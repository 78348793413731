import { Button, Form, Input, Table, TableProps, Tooltip } from 'antd';
import { useEffect, useState } from 'react';
import { validate } from 'utils/constants/validate';
import PlusIcon from 'assets/icons/white-plus.svg';
import styles from './styles.module.scss';
import clsx from 'clsx';
import iconRemove from 'assets/icons/icon_delete.svg';
import IconRemove from 'assets/icons/ic-bg-remove.png';
import { StyledModalInvoice } from '../styled';
import {
    formatNumber,
    removeSpecialCharacters,
} from 'utils/constants/function';
import { DeleteOutlined } from '@ant-design/icons';
import invoiceService from 'services/invoiceService';
import { alertError, alertSuccessPopup } from 'utils/helper/appHelper';

interface TableInvoiceProps {
    form?: any;
    dataInvoice?: any;
    setDataInvoice: (data: any) => void;
    isDisabled?: boolean;
    invoice_id?: number;
    getListInvoice?: any;
    project_id?: number;
    isOpenInvoice?: boolean;
}
export interface DataTypeDetail {
    key?: number;
    id?: number;
    contents?: string;
    quantity?: number;
    unit?: string;
    unit_price?: number;
    amount?: number;
    price_tax_included?: number;
}

const TableInvoice: React.FC<TableInvoiceProps> = ({
    form,
    setDataInvoice,
    dataInvoice,
    isDisabled,
    invoice_id,
    project_id,
}) => {

    const defaultCount = dataInvoice?.length + 1;

    const [count, setCount] = useState(1);

    const [currentRecord, setCurrentRecord] = useState<{
        key?: number;
        id?: number;
    }>({});

    const [isOpenDelete, setIsOpenDelete] = useState(false);

    const showOpenDelete = () => {
        setIsOpenDelete(true);
    };

    const convertFieldsValue = () => {
        const fieldsValue =
            dataInvoice?.map((item: any, index: number) => {
                const indexFormat = index + 1;
                return {
                    [`contents-${indexFormat}`]: item?.contents,
                    [`quantity-${indexFormat}`]: item?.quantity,
                    [`unit-${indexFormat}`]: item?.unit,
                    [`unit_price-${indexFormat}`]: item?.unit_price,
                    [`amount-${indexFormat}`]: item?.amount,
                    [`price_tax_included-${indexFormat}`]:
                        item?.price_tax_included,
                };
            }) ?? [];
        const objFieldsValue = fieldsValue.reduce((acc, item) => {
            return { ...acc, ...item };
        }, {});

        return objFieldsValue;
    };

    const fieldsValue = convertFieldsValue();

    const handleCloseDelete = () => {
        setIsOpenDelete(false);
    };
    const handleAdd = () => {
        const newData = {
            key: count,
            contents: '',
            quantity: '',
            unit: '式',
            unit_price: '',
            amount: '¥0',
            price_tax_included: '¥0',
        };
        setDataInvoice((prev: any = []) => [...prev, newData]);
        setCount(count + 1);
    };

    const handleDeleteInvoiceItem = async (
        invoice_id: number,
        bill_item_id: number,
        project_id: number,
    ) => {
        try {
            const data = await invoiceService.deleteInvoiceItem(
                invoice_id,
                bill_item_id,
                project_id,
            );
            if (data) {
                alertSuccessPopup({
                    title: '請求書が削除されました。',
                    confirmButtonText: '閉じる',
                });
                handleCloseDelete();
            }
        } catch (error) {
            const errorMessage = error?.response?.data?.error || '';
            alertError(errorMessage);
        }
    };

    const handleDelete = (key: any, bill_item_id: any) => {
        const newData = (dataInvoice || [])
            .filter((item: any) => item.key !== key)
            ?.map((element: any, index: number) => {
                return {
                    ...element,
                    key: index + 1,
                };
            });
        setDataInvoice(newData);
        setCount(count - 1);
        handleCloseDelete();
        if (bill_item_id && invoice_id && project_id) {
            handleDeleteInvoiceItem(invoice_id, bill_item_id, project_id);
        }
    };

    const updateValuesInvoice = (
        keyIndex?: number,
        keyValue?: string,
        data?: any,
        quantity?: any,
        unit_price_format?: any,
    ) => {
        const totalAmount = quantity * unit_price_format;
        const formattedTotalAmount = '¥' + formatNumber(totalAmount);
        const formattedPriceTaxIncluded =
            '¥' + formatNumber(Math.round(totalAmount + totalAmount * 0.1));
        const dataInvoiceFormat = dataInvoice?.map(
            (item: any, index: number) => {
                const isKey = item?.key === keyIndex;
                const isKeyValue =
                    keyValue?.includes('unit_price') ||
                    keyValue?.includes('quantity');
                return isKey
                    ? {
                        ...item,
                        [`${keyValue}`]: isKey ? data : item.contents,
                        amount: isKeyValue
                            ? formattedTotalAmount
                            : item?.amount,
                        price_tax_included: isKeyValue
                            ? formattedPriceTaxIncluded
                            : item?.price_tax_included,
                    }
                    : item;
            },
        );
        setDataInvoice(dataInvoiceFormat);
    };

    const defaultColumns: TableProps<DataTypeDetail>['columns'] = [
        {
            title: '番号',
            dataIndex: 'number',
            key: 'number',
            align: 'center',
            width: '7%',
            render: (text, record) => (
                <>
                    <div
                        className={clsx({
                            'text-[#d9d9d9]': isDisabled,
                        })}
                        key={record?.key}
                    >
                        {record?.key}
                    </div>
                </>
            ),
        },
        {
            title: '内容',
            dataIndex: 'contents',
            key: 'contents',
            render: (text: any, record: any) => (
                <>
                    <Form.Item
                        key={record?.key}
                        className={clsx(
                            'mb-0 line-clamp-1',
                            styles?.inputCommon,
                        )}
                        name={`contents-${record?.key}`}
                        rules={[
                            () => ({
                                required: !isDisabled,
                                validator(_: any, value: any) {
                                    if (!value && !isDisabled) {
                                        return Promise.reject(
                                            new Error(
                                                `工事名${validate?.validate_input}`,
                                            ),
                                        );
                                    } else {
                                        return Promise.resolve();
                                    }
                                },
                            }),
                        ]}
                    >
                        <Input
                            disabled={isDisabled}
                            className="rounded-lg"
                            defaultValue={record?.contents}
                            onBlur={(data) => {
                                const contentsFormat = data?.target?.value;
                                const key = record?.key;
                                updateValuesInvoice(
                                    key,
                                    'contents',
                                    contentsFormat.trim(),
                                );
                            }}
                            onChange={(data) => {
                                const contentsFormat = data?.target?.value;
                                const key = record?.key;
                                updateValuesInvoice(
                                    key,
                                    'contents',
                                    contentsFormat,
                                );
                            }}
                        />
                    </Form.Item>
                </>
            ),
            width: '25%',
        },
        {
            title: '数量',
            dataIndex: 'quantity',
            key: 'quantity',
            render: (text: any, record: any) => (
                <>
                    <Tooltip
                        title={`${form?.getFieldError([
                            `quantity-${record?.key}`,
                        ])}`}
                    >
                        <Form.Item
                            key={record?.key}
                            className={clsx(
                                'mb-0 line-clamp-1',
                                styles?.inputCommon,
                                styles.inputCommonRight,
                            )}
                            name={`quantity-${record?.key}`}
                            rules={[
                                () => ({
                                    required: !isDisabled,
                                    validator(_: any, value: any) {
                                        if (!value && !isDisabled) {
                                            return Promise.reject(
                                                new Error(
                                                    `数量${validate?.validate_input}`,
                                                ),
                                            );
                                        } else {
                                            return Promise.resolve();
                                        }
                                    },
                                }),
                            ]}
                        >
                            <Input
                                disabled={isDisabled}
                                className="rounded-lg"
                                onChange={(data: any) => {
                                    const key = record?.key;

                                    const valueFormatted =
                                        removeSpecialCharacters(
                                            data?.target?.value?.toString(),
                                        );

                                    const dataFormat = formatNumber(
                                        valueFormatted
                                            ? Number(valueFormatted)
                                            : '',
                                    );
                                    const quantity = Number(
                                        dataFormat?.replace(/,/g, ''),
                                    );

                                    const unit_price = form?.getFieldValue(
                                        `unit_price-${key}`,
                                    );

                                    const unit_price_format = unit_price
                                        ? Number(
                                            unit_price?.replace(/[^\d]/g, ''),
                                        )
                                        : 1;

                                    const totalAmount =
                                        quantity * unit_price_format;

                                    updateValuesInvoice(
                                        key,
                                        'quantity',
                                        dataFormat,
                                        quantity,
                                        unit_price_format,
                                    );
                                }}
                            />
                        </Form.Item>
                    </Tooltip>
                </>
            ),
            width: '8%',
        },
        {
            title: '単位',
            dataIndex: 'unit',
            key: 'unit',
            render: (text: any, record: any) => (
                <>
                    <Form.Item
                        key={record?.key}
                        className={clsx(
                            'mb-0 line-clamp-1',
                            styles?.inputCommon,
                            styles.inputCommonCenter,
                        )}
                        name={`unit-${record?.key}`}
                        rules={[
                            () => ({
                                required: !isDisabled,
                                validator(_: any, value: any) {
                                    if (!value && !isDisabled) {
                                        return Promise.reject(
                                            new Error(
                                                `単位${validate?.validate_input}`,
                                            ),
                                        );
                                    } else {
                                        return Promise.resolve();
                                    }
                                },
                            }),
                        ]}
                    >
                        <Input
                            disabled={isDisabled}
                            className="rounded-lg"
                            onBlur={(data) => {
                                const unitFormat = data?.target?.value;
                                const key = record?.key;
                                updateValuesInvoice(
                                    key,
                                    'unit',
                                    unitFormat.trim(),
                                );
                            }}
                            onChange={(data) => {
                                const unitFormat = data?.target?.value?.trim();
                                const key = record?.key;
                                updateValuesInvoice(key, 'unit', unitFormat);
                            }}
                        />
                    </Form.Item>
                </>
            ),
            width: '8%',
        },
        {
            title: '単価',
            dataIndex: 'unit_price',
            key: 'unit_price',
            render: (text: any, record: any) => (
                <>
                    <Form.Item
                        key={record?.key}
                        className={clsx(
                            'mb-0 line-clamp-1',
                            styles?.inputCommon,
                            styles?.inputCommonRight,
                        )}
                        name={`unit_price-${record?.key}`}
                        rules={[
                            () => ({
                                required: !isDisabled,
                                validator(_: any, value: any) {
                                    if (!value && !isDisabled) {
                                        return Promise.reject(
                                            new Error(
                                                `単価${validate?.validate_input}`,
                                            ),
                                        );
                                    } else {
                                        return Promise.resolve();
                                    }
                                },
                            }),
                        ]}
                    >
                        <Input
                            disabled={isDisabled}
                            className="rounded-lg"
                            onChange={(data: any) => {
                                const key = record?.key;

                                const valueFormatted = removeSpecialCharacters(
                                    data?.target?.value?.toString(),
                                );

                                const dataFormat = formatNumber(
                                    valueFormatted
                                        ? Number(valueFormatted)
                                        : '',
                                );
                                const unit_price = Number(
                                    dataFormat?.replace(/,/g, ''),
                                );

                                const quantity = form?.getFieldValue(
                                    `quantity-${key}`,
                                );

                                const quantity_format = quantity
                                    ? Number(quantity?.replace(/[^\d]/g, ''))
                                    : 1;

                                const totalAmount =
                                    quantity_format * unit_price;

                                form?.setFieldsValue({
                                    [`unit_price-${key}`]: dataFormat,
                                    [`amount-${key}`]:
                                        '¥' + formatNumber(totalAmount),
                                    [`price_tax_included-${key}`]:
                                        '¥' +
                                        formatNumber(
                                            Math.round(
                                                totalAmount + totalAmount * 0.1,
                                            ),
                                        ),
                                });
                                updateValuesInvoice(
                                    key,
                                    'unit_price',
                                    '¥' + dataFormat,
                                    quantity_format,
                                    unit_price,
                                );
                            }}
                        />
                    </Form.Item>
                </>
            ),
            width: '15%',
        },
        {
            title: '金額',
            dataIndex: 'amount',
            key: 'amount',
            render: (text: any, record: any) => (
                <>
                    <Form.Item
                        key={record?.key}
                        className={clsx(
                            'mb-0 line-clamp-1',
                            styles?.inputCommon,
                            styles?.inputCommonRight,
                        )}
                        rules={[
                            () => ({
                                required: !isDisabled,
                                validator(_: any, value: any) {
                                    const valueFormatted =
                                        removeSpecialCharacters(value);

                                    if (
                                        valueFormatted?.length > 11 &&
                                        !isDisabled
                                    ) {
                                        return Promise.reject(
                                            new Error(
                                                `文字数の制限を超えています。`,
                                            ),
                                        );
                                    } else {
                                        return Promise.resolve();
                                    }
                                },
                            }),
                        ]}
                        name={`amount-${record?.key}`}
                    >
                        <Input disabled className="rounded-lg" />
                    </Form.Item>
                </>
            ),
            width: '15%',
        },
        {
            title: '金額（税込）',
            dataIndex: 'price_tax_included',
            key: 'price_tax_included',
            render: (text: any, record: any) => (
                <>
                    <Form.Item
                        key={record?.key}
                        className={clsx(
                            'mb-0 line-clamp-1',
                            styles?.inputCommon,
                            styles?.inputCommonRight,
                        )}
                        name={`price_tax_included-${record?.key}`}
                        rules={[
                            () => ({
                                required: !isDisabled,
                                validator(_: any, value: any) {
                                    const valueFormatted =
                                        removeSpecialCharacters(value);

                                    if (
                                        valueFormatted?.length > 11 &&
                                        !isDisabled
                                    ) {
                                        return Promise.reject(
                                            new Error(
                                                `文字数の制限を超えています。`,
                                            ),
                                        );
                                    } else {
                                        return Promise.resolve();
                                    }
                                },
                            }),
                        ]}
                    >
                        <Input disabled className="rounded-lg" />
                    </Form.Item>
                </>
            ),
            width: '15%',
        },
        {
            title: '操作',
            key: 'action',
            render: (_, record) => (
                <button
                    key={record?.key}
                    onClick={() => {
                        if (!isDisabled) {
                            showOpenDelete();
                            setCurrentRecord(record);
                        }
                    }}
                    type="button"
                >
                    {!isDisabled ? (
                        <img src={iconRemove} />
                    ) : (
                        <DeleteOutlined
                            disabled
                            className="text-[20px] !text-[#d9d9d9]"
                        />
                    )}
                </button>
            ),
            width: '7%',
            align: 'center',
        },
    ];

    const columns = defaultColumns?.map((col: any) => {
        if (!col.editable) {
            return col;
        }
        return {
            ...col,
            onCell: (record: any) => ({
                record,
                editable: col.editable,
                dataIndex: col.dataIndex,
                title: col.title,
            }),
        };
    });

    useEffect(() => {
        fieldsValue &&
            dataInvoice?.length > 0 &&
            form?.setFieldsValue(fieldsValue);
    }, [fieldsValue, dataInvoice]);

    useEffect(() => {
        setCount(defaultCount);
    }, [dataInvoice]);

    return (
        <>
            <div className={styles.tableInvoiceCustom}>
                <Table
                    columns={columns}
                    dataSource={dataInvoice}
                    pagination={false}
                    locale={{ emptyText: 'データがありません。' }}
                />
            </div>
            {!isDisabled && (
                <div className="w-full flex justify-center mt-3">
                    <Button
                        onClick={handleAdd}
                        type="primary"
                        className="mb-4 w-fit !bg-[#215493] !border-none !rounded-lg"
                    >
                        <div className="flex gap-2">
                            <img
                                src={PlusIcon}
                                alt="plus"
                                height={16}
                                width={16}
                            />
                            <span>追加</span>
                        </div>
                    </Button>
                </div>
            )}
            <StyledModalInvoice
                className="!p-0"
                open={isOpenDelete}
                footer={null}
                closable={false}
                closeIcon={false}
                onCancel={handleCloseDelete}
                centered
                width={462}
            >
                <div
                    className="modal-create-user !w-full d-flex flex-column"
                    style={{ height: '243px' }}
                >
                    <img src={IconRemove} />
                    <div className="modal-create-user-title d-flex flex-row mt-4 font-semibold text-2xl">
                        本当に削除しますか?
                    </div>
                    <div
                        className="modal-create-user-buttons d-flex flex-row"
                        style={{ marginTop: '50px' }}
                    >
                        <button
                            className="modal-create-user-button1 d-flex flex-row !border-[#e4e4e4] !rounded-[3px] !h-9"
                            onClick={handleCloseDelete}
                            type="button"
                        >
                            いいえ
                        </button>
                        <button
                            className="modal-create-user-button2 d-flex flex-row !bg-[#d83535] !rounded-[3px] !h-9"
                            onClick={() => {
                                handleDelete(
                                    currentRecord.key,
                                    currentRecord?.id,
                                );
                            }}
                            type="button"
                        >
                            はい
                        </button>
                    </div>
                </div>
            </StyledModalInvoice>
        </>
    );
};
export default TableInvoice;
