import { useState, useEffect } from 'react';
import './detailPlan.css';
import Box from '@mui/material/Box';
import 'react-step-progress/dist/index.css';
import { useNavigate, useParams } from 'react-router-dom';
import Billings from './Billings';
import ProjectTable from './components/projectTable';
import ProjectTabs from './components/projectTabs';
import { ProjectManifestsTab, ProjectOrderItemsTab } from './projectTabs';
import ProjectDetailChats from './Chats';
import BudgetList from './budgetAction/BudgetList';
import EstimationList from './Estimations/EstimationList';
import ProgressStepCreate from '../projectCreate/progressStepCreate';
import projectApi from 'services/projectApi';
import BluePen from 'assets/icons/blue-pen.png';
import Perform from './Perform';
import clsx from 'clsx';
import Invoices from './Invoices';
import { LIST_TAB_PROJECT } from 'utils/constants';
import LoadingSpin from 'components/LoadingSpin';
import Payment from './Payment';

export const determineStatus = (project: any, idAllPaid: boolean) => {
    if (
        !project?.estimate &&
        !project?.order &&
        !project?.implement &&
        project?.bills?.length === 0
    ) {
        return 'order_placement';
    } else if (
        project?.estimate &&
        !project?.order &&
        !project?.implement &&
        project?.bills?.length === 0
    ) {
        return 'order_sheet';
    } else if (
        project?.estimate &&
        project?.order &&
        !project?.implement &&
        project?.bills?.length === 0
    ) {
        return 'order_receipt';
    } else if (
        project?.estimate &&
        project?.order &&
        project?.implement &&
        project?.bills?.length === 0
    ) {
        return 'construction';
    } else if (
        project?.estimate &&
        project?.order &&
        project?.implement &&
        project?.bills?.length > 0
    ) {
        return idAllPaid ? 'payment_confirmation' : 'invoice_creation';
    }
};

const WorkingReport = (props) => {
    const navigate = useNavigate();

    const [tab, setTab] = useState(0);

    let { id } = useParams();

    const [currentProject, setCurrentProject] = useState<any>(null);

    let storage = localStorage.getItem('user');
    const user = storage !== null ? JSON.parse(storage) : null;

    // list chats
    const [chats, setChats] = useState<any>(null);
    const [refresh, setRefresh] = useState<any>(null);

    const [images, setImages] = useState([]);

    const determineTab = (project: any) => {
        if (
            project?.estimate &&
            !project?.order &&
            !project?.implement &&
            project?.bills?.length === 0
        ) {
            return 1;
        } else if (
            (project?.estimate &&
                project?.order &&
                !project?.implement &&
                project?.bills?.length === 0) ||
            (project?.estimate &&
                project?.order &&
                project?.implement &&
                (project?.implement?.status?.includes('implementing') ||
                    project?.implement?.status?.includes('send_implement')) &&
                project?.bills?.length === 0)
        ) {
            return 2;
        } else if (
            project?.estimate &&
            project?.order &&
            project?.implement &&
            project?.implement?.status?.includes('finish_implement')
        ) {
            return 3;
        } else {
            return 0;
        }
    };

    const getDetailProject = async () => {
        if (!id) return;
        try {
            const res = await projectApi.getProjectDetail(
                user?.access_token,
                id,
            );
            const project = res?.data?.project;

            const fileArr: any = [];
            let imageSize = 0;
            if (project?.images.length > 0 || project?.images !== null) {
                for (let i = 0; i < project?.images.length; i++) {
                    await fetch(project?.images[i].image_path)
                        .then((res) => res.blob())
                        .then((myBlob) => {
                            const myFile: any = new File(
                                [myBlob],
                                'image.jpeg',
                                { type: myBlob.type },
                            );
                            fileArr.push({
                                uid: `-${i + 1}`,
                                name: myFile.name,
                                url: project?.images[i].image_path,
                                status: 'done',
                                originFileObj: myFile,
                                lastModified: myFile.lastModified,
                                lastModifiedDate: myFile.lastModifiedDate,
                                type: myFile.type,
                                size: myFile.size,
                            });
                            imageSize += myFile.size;
                        });
                }
            }
            setImages(fileArr);
            // setValue('images', fileArr);
            const isAllPaid =
                project?.bills?.length > 0
                    ? project?.bills?.every(
                          (item: any) => item.status === 'paid',
                      )
                    : false;

            const tabIndex: any = determineTab(project);
            const statusFormat = determineStatus(project, isAllPaid);
            const project_format = {
                ...res?.data?.project,
                status: statusFormat,
            };
            setTab(tabIndex);
            setCurrentProject(project_format);
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        if (id) {
            getDetailProject();
        }
    }, [id]);

    return (
        <>
            {currentProject === null ? (
                <LoadingSpin />
            ) : (
                <div className="container-project2 d-flex flex-row">
                    <div className="project-content-border2 d-flex flex-column">
                        <ProgressStepCreate status={currentProject?.status} />
                        <div className="flex flex-row mb-[20px]">
                            <div className="text-[20px] font-bold">
                                {currentProject?.property_name}
                            </div>
                            <div className="min-w-[80px]">
                                <div
                                    onClick={() =>
                                        navigate(
                                            `/projects/edit/${currentProject?.quote?.id}?project_id=${currentProject?.id}`,
                                        )
                                    }
                                    className="flex flex-row justify-end ml-[10px] gap-[5px] mb-[2px] text-[14px] cursor-pointer"
                                >
                                    <img
                                        src={BluePen}
                                        alt=""
                                        height="14px"
                                        width="17px"
                                        className="py-[3px]"
                                    />
                                    <div className="text-[14px] text-[#215493] mt-[4px] !mb-0">
                                        編集
                                    </div>
                                </div>
                            </div>
                        </div>
                        {currentProject && (
                            <ProjectTable
                                currentProject={currentProject}
                                images={images}
                            />
                        )}
                        {currentProject?.business_type == '産廃' ? (
                            <ProjectTabs
                                titles={[
                                    '委託内容',
                                    'マニフェスト',
                                    // '運行予定',
                                    '請求',
                                    'チャット',
                                ]}
                                contents={[
                                    <ProjectOrderItemsTab />,
                                    <ProjectManifestsTab />,
                                    <Box
                                        sx={{
                                            mt: '24px',
                                            th: {
                                                fontFamily:
                                                    'Zen Kaku Gothic Antique',
                                            },
                                        }}
                                    >
                                        <Billings
                                            project_id={id}
                                            project={currentProject}
                                        />
                                    </Box>,
                                    <ProjectDetailChats
                                        currentProject={currentProject}
                                        chats={chats}
                                        setRefresh={setRefresh}
                                    />,
                                ]}
                            />
                        ) : (
                            //need to define each component for content and pass by props
                            <>
                                <div className="tab-project !text-sm d-flex flex-row">
                                    {LIST_TAB_PROJECT?.map(
                                        (tabCustom: any, index: number) => {
                                            const tabValue = tabCustom?.value;

                                            const lastIndex = index === 3;

                                            return (
                                                <>
                                                    <div
                                                        onClick={() => {
                                                            setTab(tabValue);
                                                        }}
                                                        className={clsx(
                                                            '!text-sm !font-medium',
                                                            {
                                                                'tab-active':
                                                                    tab ===
                                                                    tabValue,
                                                            },
                                                        )}
                                                    >
                                                        {tabCustom?.label}
                                                    </div>
                                                </>
                                            );
                                        },
                                    )}
                                </div>
                                {tab === 0 && (
                                    <BudgetList
                                        project_id={id}
                                        getProjectDetail={getDetailProject}
                                        project={currentProject}
                                        setTab={setTab}
                                    />
                                )}
                                {tab === 1 && (
                                    <EstimationList
                                        project_id={id}
                                        getDetailProject={getDetailProject}
                                        project={currentProject}
                                        setTab={setTab}
                                    />
                                )}
                                {tab === 2 && (
                                    <Perform
                                        project_id={id}
                                        getDetailProject={getDetailProject}
                                        project={currentProject}
                                        implement_id={
                                            currentProject?.implement?.id
                                        }
                                        setTab={setTab}
                                    />
                                )}
                                {tab === 3 && (
                                    <Invoices
                                        getDetailProject={getDetailProject}
                                        project_id={id}
                                        project={currentProject}
                                    />
                                )}
                                {tab === 4 && <Payment />}
                            </>
                        )}
                    </div>
                </div>
            )}
        </>
    );
};

export default WorkingReport;
