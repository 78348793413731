import { Button, Spin, ConfigProvider, Modal } from 'antd';
import { forwardRef, useState, useRef, useEffect } from 'react';
import jaJP from 'antd/es/locale/ja_JP';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { CloseOutlined, DeleteOutlined } from '@mui/icons-material';
import { Controller, useForm } from 'react-hook-form';
import SelectSearch, { SelectSearchOption } from 'react-select-search';
import { QuoteStatusEnum } from 'utils/enums';
import styled from 'styled-components';
import { QuoteFormStyles } from 'pages/quote/form/styled';
import moment from 'moment';
import '@react-pdf-viewer/core/lib/styles/index.css';
import { usePDF, Options, Margin, Resolution } from 'react-to-pdf';
import UploadWhite from 'assets/icons/upload-white.svg';
import LogoText from 'assets/logo-text.svg';
import InvoiceTitle from 'assets/icons/invoice-title.png';
import CompanyNumber from 'assets/icons/company-number.png';
import { IInvoice, IInvoiceChildren, IInvoiceDetail } from 'models';
import { formatCurrency } from 'utils/helper/currency';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import invoiceApi from 'services/invoiceApi';
import { alertError, alertSuccessPopup } from 'utils/helper/appHelper';
import { useModal } from 'react-hooks-use-modal';
import ExclamationIcon from 'assets/icons/exclamation.svg';
import UploadGrey from 'assets/icons/upload-grey.svg';
import querystring from 'query-string';
import { InvoicePDFStyle } from '../estimatePdf/styled';
import SignInvoiceImg from 'assets/icons/sign-invoice.png';

const digit = /[0-9０-９]/;
const mask = [digit, digit, digit, '-', digit, digit, digit, digit];
const statusOptions = Object.values(QuoteStatusEnum).map((item) => ({
    value: item.value,
    name: item.title,
}));
const typeOptions: SelectSearchOption[] = [
    { value: 'interior', name: '内装' },
    { value: 'exterior', name: '外装' },
    { value: 'water_area', name: '水回り' },
    { value: 'other', name: 'その他' },
];

export interface InvoicePdf {
    view: (id: number) => void;
}

const ModalQuoteCustoms = styled(Modal)`
    .ant-modal-header {
        padding: 0px !important;
    }
    .ant-modal-content {
        min-height: 700px !important;
    }
`;
interface IProps {
    invoice: any;
    quoteDetail?: any;
    totalItemPrice?: number[];
    disableInvoice?: boolean;
    getDetail?: () => void;
}
const InvoicePdf = forwardRef<InvoicePdf, IProps>((props, ref) => {
    const { invoice, quoteDetail, totalItemPrice, getDetail, disableInvoice } =
        props;
    const {
        formState: { errors },
        clearErrors,
    } = useForm();
    const matchesSP = window?.innerWidth < 992;
    const pdfExportComponent: any = useRef(null);
    const [totalPrice, setTotalPrice] = useState(0);
    const [totalBFTaxPrice, setTotalBFTaxPrice] = useState(0);
    const [searchParams, setSearchParams] = useSearchParams();
    const [uploadLoading, setUploadLoading] = useState(false);
    const [exportLoading, setExportLoading] = useState(false);
    const location = useLocation();
    const queryObj = querystring.parse(location.search);
    const id = searchParams.get('id');
    const viewInvoicePdf = searchParams.get('viewInvoicePdf'); // determine wether that we can edit or view-only
    const navigate = useNavigate();
    let storage = localStorage.getItem('user');
    const [isLoading, setIsLoading] = useState(false);
    const user = storage !== null ? JSON.parse(storage) : null;
    const [ModalConfirm, openConfirm, closeConfirm, isOpenConfirm] = useModal(
        'root',
        {
            preventScroll: true,
            // closeOnOverlayClick: false,
        },
    );
    const { toPDF, targetRef } = usePDF({
        filename: 'invoice.pdf',
        page: {
            margin: 10,
            format: 'letter',
            orientation: 'landscape',
        },
        overrides: {
            // see https://artskydj.github.io/jsPDF/docs/jsPDF.html for more options
            pdf: {
                compress: true,
            },
            // see https://html2canvas.hertzen.com/configuration for more options
            canvas: {
                useCORS: true,
            },
        },
    });
    const handleClose = () => {
        clearErrors();
        navigate(
            `/projects/detail/${invoice?.id}/plan?${
                queryObj?.prev ? `prev=${queryObj?.prev}` : ''
            }`,
        );
    };

    const getTotalPrice = () => {
        let total = 0;
        let totalBFTax = 0;
        if (invoice?.bills) {
            for (let i = 0; i < invoice?.bills?.length; i++) {
                total += Number(invoice?.bills[i].total_price);
                totalBFTax += Number(invoice?.bills[i].sub_total);
            }
        }
        setTotalBFTaxPrice(totalBFTax);
        setTotalPrice(total);
    };

    const handleExport = async (type?: string) => {
        if (type === 'export') {
            setExportLoading(true);
        } else {
            setUploadLoading(true);
        }
        const element: any = targetRef.current;
        html2canvas(element, {
            useCORS: true,
            allowTaint: true,
            scrollY: 0,
        }).then((canvas) => {
            const image = { type: 'jpeg', quality: 0.98 };
            const margin = [0.5, 0.5];
            const filename = 'myfile.pdf';

            let imgWidth = 8.5;
            let pageHeight = 11;

            let innerPageWidth = imgWidth - margin[0] * 2;
            let innerPageHeight = pageHeight - margin[1] * 2;

            // Calculate the number of pages.
            let pxFullHeight = canvas.height;
            let pxPageHeight = Math.floor(
                canvas.width * (pageHeight / imgWidth),
            );
            let nPages = Math.ceil(pxFullHeight / pxPageHeight);

            // Define pageHeight separately so it can be trimmed on the final page.
            let pageHeight2 = innerPageHeight;

            // Create a one-page canvas to split up the full image.
            let pageCanvas = document.createElement('canvas');
            let pageCtx: any = pageCanvas.getContext('2d');
            pageCanvas.width = canvas.width;
            pageCanvas.height = pxPageHeight;

            // Initialize the PDF.
            let pdf = new jsPDF('p', 'in', [8.5, 11]);

            for (let page = 0; page < nPages; page++) {
                // Trim the final page to reduce file size.
                if (page === nPages - 1 && pxFullHeight % pxPageHeight !== 0) {
                    pageCanvas.height = pxFullHeight % pxPageHeight;
                    pageHeight2 =
                        (pageCanvas.height * innerPageWidth) / pageCanvas.width;
                }

                // Display the page.
                let w = pageCanvas.width;
                let h = pageCanvas.height;
                pageCtx.fillStyle = 'white';
                pageCtx.fillRect(0, 0, w, h);
                pageCtx.drawImage(
                    canvas,
                    0,
                    page * pxPageHeight,
                    w,
                    h,
                    0,
                    0,
                    w,
                    h,
                );

                // Add the page to the PDF.
                if (page > 0) pdf.addPage();
                let imgData = pageCanvas.toDataURL(
                    'image/' + image.type,
                    image.quality,
                );
                pdf.addImage(
                    imgData,
                    image.type,
                    margin[1],
                    margin[0],
                    innerPageWidth,
                    pageHeight2,
                );
            }

            if (type === 'export') {
                pdf.save(`${invoice?.property_name} 請求書.pdf`);
            } else {
                const pdfBlob = pdf.output('blob');
                const newFile = new File(
                    [pdfBlob],
                    `${invoice?.property_name} 請求書.pdf`,
                );
                const is_send_message = true;
                const formData = new FormData();
                formData.append('invoice_pdf', newFile);
                formData.append('is_send_message', is_send_message.toString());
                uploadFile(formData);
            }
            setExportLoading(false);
            setUploadLoading(false);
        });
    };

    const uploadFile = async (formData: FormData) => {
        // try {
        //     const res = await invoiceApi.uploadFileInvoicePdf(user?.access_token, formData, invoice?.id)
        //     if (res) {
        //         alertSuccessPopup({
        //             title: '保存してLINEで送信しました',
        //             confirmButtonText: '閉じる',
        //         });
        //         closeConfirm()
        //         handleClose()
        //         getDetail()
        //     } else {
        //         alertError("fail")
        //     }
        // } catch (error) {
        //     console.log('error', error)
        //     setExportLoading(false)
        //     setUploadLoading(false)
        // }
        // setExportLoading(false)
        // setUploadLoading(false)
    };
    useEffect(() => {
        if (invoice) {
            getTotalPrice();
        }
    }, [invoice]);
    return (
        <ModalQuoteCustoms
            width="1000px"
            title={
                <div className="flex justify-end items-center py-[20px] px-[15px]">
                    <Button shape="circle" type="text" onClick={handleClose}>
                        <CloseOutlined />
                    </Button>
                </div>
            }
            style={{
                scale: `${matchesSP ? '0.4' : '1'}`,
                minWidth: '1000px',
                zIndex: '999',
                // marginLeft: `${matchesSP ? '300px' : ''}`,
            }}
            className="max-md:!ml-[300px] max-[391px]:!ml-[250px]"
            closable={false}
            destroyOnClose
            footer={null}
            open={Boolean(viewInvoicePdf)}
            onCancel={handleClose}
            // headerStyle={{ background: '#215493' }}
        >
            <ConfigProvider locale={jaJP}>
                {isLoading && (
                    <div
                        style={{
                            width: '600px',
                            height: '100vh',
                            top: 0,
                            left: 0,
                            zIndex: 99,
                        }}
                        className="position-fixed d-flex align-items-center justify-content-center"
                    >
                        <Spin className="w-[100px] h-[100px]" />
                    </div>
                )}
                {viewInvoicePdf && (
                    <InvoicePDFStyle className="bg-[#fff] relative">
                        <div
                            className="quote-form-container h-[100%] pt-[20px] pb-[100px] px-[10px]"
                            id="element-to-print"
                            ref={targetRef}
                        >
                            <div className="relative mt-[30px]">
                                <div className="flex flex-row justify-center gap-[20px] ">
                                    <p
                                        className="text-center font-bold text-[35px] py-[7px]"
                                        style={{
                                            borderBottom: '1px solid #000',
                                        }}
                                    >
                                        御　請　求　書
                                    </p>
                                    {/* <img
                                        src={QuoteTitle}
                                        alt=""
                                        height={50}
                                        width={400}
                                    /> */}
                                </div>
                                <div className="grid grid-cols-2 w-1/2 text-[20px] mt-[15px] ml-[20px]">
                                    {/* <p>{project?.quote?.customer?.last_name}{project?.quote?.customer?.first_name}</p> */}
                                    <div>
                                        {invoice?.quote?.customer?.last_name}
                                        {invoice?.quote?.customer?.first_name}
                                    </div>
                                    <p className="font-semibold">御中</p>
                                </div>
                                <p
                                    className="absolute text-[14px] top-[95px]"
                                    style={{ right: '20px' }}
                                >
                                    見積日
                                    <span className="ml-[10px]">
                                        {moment(
                                            // quoteDetail?.desired_date,
                                            invoice?.from_date,
                                        ).format('YYYY年MM月DD日')}{' '}
                                    </span>
                                </p>
                            </div>

                            <div className="flex flex-row gap-[20px] ml-[20px]">
                                <div>
                                    <p>工事件名:</p>
                                </div>
                                <div>
                                    <div></div>
                                    <div className="mt-[25px]">
                                        下記のとおり御請求申し上げます。
                                    </div>
                                </div>
                            </div>
                            <div className="flex flex-col items-center ml-[20px]">
                                <div className="flex flex-col">
                                    <div
                                        style={{
                                            borderBottom: '1px solid #000',
                                        }}
                                        className="flex flex-row justify-center text-[35px] py-[7px] font-bold mt-[40px] gap-[20px]"
                                    >
                                        <p className="mb-0">御請求金額</p>
                                        <p className="mb-0">
                                            {formatCurrency(totalPrice)}–
                                        </p>
                                    </div>
                                    <div className="flex flex-row justify-end mt-[15px] gap-[10px] mr-[-50px]">
                                        <p className="mb-0">合計金額</p>
                                        <p className="mb-0">
                                            {formatCurrency(totalBFTaxPrice)}–
                                        </p>
                                    </div>
                                    <div className="flex flex-row justify-end mt-[10px] gap-[10px] mr-[-50px]">
                                        <p className="mb-0">消費税金額10％</p>
                                        <p className="mb-0">
                                            {formatCurrency(
                                                (totalBFTaxPrice * 10) / 100,
                                            )}
                                            –
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div className="grid grid-cols-2 mt-[80px]">
                                <div className="grid grid-cols-5">
                                    <div className="col-span-5 flex flex-row gap-[20px] ml-[20px]">
                                        <div>
                                            <p>お振込先:</p>
                                        </div>
                                        <div>
                                            <div>
                                                名古屋銀行　平田町支店　普通　3243513
                                            </div>
                                            <div>
                                                コンアフェット株式会社　代表取締役　ツジ　ヨシタカ
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-span-5 pl-[20px] text-[20px]">
                                        {/* <p>{quoteDetail?.project_title ? quoteDetail?.project_title : invoice?.project_title}</p> */}
                                    </div>

                                    {/* <div style={{ borderBottom: '3px solid #000' }}
                                        className='col-span-4 flex flex-row text-[20px] pb-[16px] mt-[100px] gap-[20px]'>
                                        <p className='w-1/2 ml-[20px] mb-0' >御請求合計金額</p>
                                        <p className='w-1/2 mb-0'>{formatCurrency(totalPrice)}–</p>

                                    </div> */}
                                </div>
                                <div>
                                    <div className="pl-[200px] mt-[-50px]">
                                        <p className="mb-[0px] font-bold">
                                            コンアフェット　株式会社
                                        </p>
                                        <p className="mb-[0px]">〒486-0817</p>
                                        <p className="mb-[0px]">
                                            愛知県春日井市東野町5-24-1
                                        </p>
                                        <p className="mb-[0px]">
                                            TEL0568-81-1234
                                        </p>
                                        <p className="mb-[0px]">
                                            FAX0568-84-8712
                                        </p>
                                    </div>
                                    <div className="flex flex-row justify-between mt-[10px]">
                                        <p className="mb-[0px] mt-[30px]">
                                            登録番号　T6　1800　0106　9144
                                        </p>
                                        <div className="flex flex-col mr-[20px]">
                                            <img
                                                src={SignInvoiceImg}
                                                height={90}
                                                width={70}
                                            />
                                            {/* <div style={{ border: '1px solid #000' }}
                                                className='w-[70px] h-[30px] text-center' >
                                                印
                                            </div>
                                            <div style={{ border: '1px solid #000' }}
                                                className='w-[70px] h-[70px] text-center' >

                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="text-center mt-[20px] flex flex-row justify-center">
                                <div
                                    className="text-center pb-[6px] w-fit font-bold"
                                    style={{ borderBottom: '1px solid #000' }}
                                >
                                    内 訳 明 細 書
                                </div>
                            </div>
                            <table className="mt-[7px] border  mx-[20px]">
                                <tr className="border ">
                                    <th className="w-[30%] py-[6px]">
                                        工事内容・商品名
                                    </th>
                                    <th className="w-[5%] py-[6px]">単位</th>
                                    <th className="w-[10%] py-[6px] gap-[16px]">
                                        <div className="flex flex-row justify-between px-[15%]">
                                            <span>数</span>
                                            <span>量</span>
                                        </div>
                                    </th>
                                    <th className="w-[10%] py-[6px] gap-[16px]">
                                        <div className="flex flex-row justify-between px-[15%]">
                                            <span>単</span>
                                            <span>価</span>
                                        </div>
                                    </th>
                                    <th className="w-[10%] py-[6px] gap-[16px]">
                                        <div className="flex flex-row justify-between px-[15%]">
                                            <span>金</span>
                                            <span>額</span>
                                        </div>
                                    </th>
                                    <th className="w-[10%] py-[6px] gap-[16px]">
                                        <div className="flex flex-row justify-between px-[15%]">
                                            <span>価</span>
                                            <span>考</span>
                                        </div>
                                    </th>
                                </tr>
                                {invoice?.bills &&
                                    invoice?.bills.map((item: any, index) => {
                                        let count = 1;
                                        return (
                                            <>
                                                <tr className="!w-[100%]">
                                                    <td className="w-[30%] pl-[6px]">
                                                        {item?.name}
                                                    </td>
                                                    <td className="w-[5%]"></td>
                                                    <td className="w-[10%] text-right px-[10px]"></td>
                                                    <td className="w-[10%] text-right px-[10px]"></td>
                                                    <td className="w-[10%] text-right px-[10px]"></td>
                                                    <td className="w-[10%] text-right px-[10px]"></td>
                                                </tr>
                                                {item?.bill_items &&
                                                    item?.bill_items?.map(
                                                        (
                                                            item: any,
                                                            indChild,
                                                        ) => (
                                                            <tr
                                                                className="!w-[100%]"
                                                                key={indChild}
                                                            >
                                                                <td className="w-[30%] pl-[6px]">
                                                                    {item?.name}
                                                                </td>
                                                                <td className="w-[5%] text-center">
                                                                    {item?.unit}
                                                                </td>
                                                                <td className="w-[10%] text-right px-[10px]">
                                                                    {item?.quantity?.toLocaleString(
                                                                        'en-US',
                                                                    )}
                                                                </td>
                                                                <td className="w-[10%] text-right px-[10px]">
                                                                    {Number(
                                                                        item?.unit_price,
                                                                    )?.toLocaleString(
                                                                        'en-US',
                                                                    )}
                                                                </td>
                                                                <td className="w-[10%] text-right px-[10px]">
                                                                    {Number(
                                                                        item?.amount,
                                                                    )?.toLocaleString(
                                                                        'en-US',
                                                                    )}
                                                                </td>
                                                                <td className="w-[10%] text-right px-[10px]"></td>
                                                            </tr>
                                                        ),
                                                    )}
                                                <tr className="!w-[100%]">
                                                    <td className="w-[30%]"></td>
                                                    <td className="w-[5%]"></td>
                                                    <td className="w-[10%] text-right px-[10px]"></td>
                                                    <td className="w-[10%] text-right px-[10px]"></td>
                                                    <td className="w-[10%] text-right px-[10px]">
                                                        {Number(
                                                            item?.sub_total,
                                                        )?.toLocaleString(
                                                            'en-US',
                                                        )}
                                                    </td>
                                                    <td className="w-[10%] text-right px-[10px]"></td>
                                                </tr>
                                            </>
                                        );
                                    })}

                                <tr>
                                    <td className="w-[30%] pl-[6px]">
                                        {'<税抜合計金額>'}
                                    </td>
                                    <td className="w-[5%]"></td>
                                    <td className="w-[10%] text-right px-[10px]"></td>
                                    <td className="w-[10%] text-right px-[10px]"></td>
                                    <td className="w-[10%] text-right pr-[10px]">
                                        {totalBFTaxPrice?.toLocaleString(
                                            'en-US',
                                        )}
                                    </td>
                                    <td className="w-[10%] text-right px-[10px]"></td>
                                </tr>
                                <tr>
                                    <td className="w-[30%] pl-[6px]">
                                        {'<消費税>'}
                                    </td>
                                    <td className="w-[5%]"></td>
                                    <td className="w-[10%] text-right px-[10px]"></td>
                                    <td className="w-[10%] text-right px-[10px]"></td>
                                    <td className="w-[11%] text-right pr-[10px]">
                                        {(
                                            (totalBFTaxPrice * 10) /
                                            100
                                        )?.toLocaleString('en-US')}
                                    </td>
                                    <td className="w-[11%] text-right px-[10px]"></td>
                                </tr>
                            </table>
                            <table
                                className="mx-[20px]"
                                style={{
                                    borderBottom: '1px solid',
                                    borderLeft: '1px solid',
                                    borderRight: '1px solid',
                                }}
                            >
                                <tr>
                                    <td className="w-[57.3%] !border-t-0 pl-[6px]">
                                        口座：知多信用金庫　東浦支店　普通　０３３７２１５{' '}
                                        <br />
                                        口座名義：河建株式会社（カワケンカブシキガイシャ）
                                        <br />
                                        恐れ入りますが、振込手数料は負担くださいますようにお願い致します。
                                    </td>
                                    <td className="w-[12.7%] !border-t-0 text-center">
                                        合計
                                    </td>
                                    <td className="w-[14%] !border-t-0 pr-[10px] text-right">
                                        {totalPrice?.toLocaleString('en-US')}
                                    </td>
                                    <td className="w-[14%] !border-t-0 text-right"></td>
                                </tr>
                            </table>
                        </div>
                        <div className="flex flex-row absolute bottom-0 left-[calc(50%-230px)] gap-[20px]">
                            <button
                                className="modal-create-user-button2 !rounded-[2xp] !text-[#000000D9] right-auto d-flex flex-row w-[140px]"
                                style={{
                                    width: '140px',
                                    borderRadius: '0px',
                                    background: '#fff',
                                    border: '1px solid #D9D9D9',
                                }}
                                type="button"
                                disabled={uploadLoading || exportLoading}
                                onClick={handleClose}
                            >
                                キャンセル
                            </button>
                            <button
                                className="modal-create-user-button2 !rounded-[2xp] !whitespace-nowrap right-auto d-flex flex-row w-[140px]"
                                style={{
                                    width: '140px',
                                    borderRadius: '0px',
                                    background: '#215493',
                                    border: '1px solid #D9D9D9',
                                }}
                                type="button"
                                disabled={uploadLoading || exportLoading}
                                onClick={() => handleExport('export')}
                            >
                                {exportLoading && <Spin />}
                                <img src={UploadWhite} alt="" />
                                ダウンロード
                            </button>

                            <button
                                className="modal-create-user-button2 !rounded-[2xp] right-auto d-flex flex-row w-[140px]"
                                style={{
                                    width: '140px',
                                    borderRadius: '0px',
                                    background: `${
                                        disableInvoice ||
                                        invoice?.quote?.created_by === '管理者'
                                            ? '#F5F5F5'
                                            : '#02A509'
                                    }`,
                                    color: `${
                                        disableInvoice ||
                                        invoice?.quote?.created_by === '管理者'
                                            ? '#8C8C8C'
                                            : '#fff'
                                    }`,
                                    // background: '#02A509',
                                    border: '1px solid #CCCCCC',
                                }}
                                type="button"
                                disabled={
                                    uploadLoading ||
                                    exportLoading ||
                                    disableInvoice ||
                                    invoice?.quote?.created_by === '管理者'
                                }
                                onClick={openConfirm}
                            >
                                {uploadLoading && <Spin />} LINE送信
                            </button>
                        </div>

                        <ModalConfirm>
                            <div
                                className="d-flex flex-column justify-center !bg-[#ffffff] rounded-[10px] px-[20px]"
                                style={{
                                    width: '449px',
                                    height: '285px',
                                    zIndex: '9999999999999999',
                                }}
                            >
                                <div className="flex justify-end mt-[10px] cursor-pointer">
                                    <CloseOutlined onClick={closeConfirm} />
                                </div>
                                <div className="flex flex-col justify-center items-center pb-[10px]">
                                    <img
                                        src={ExclamationIcon}
                                        className="w-[80px] h-[80px]"
                                        alt="exclamation icon"
                                    />
                                    <div className="text-[#3A3B3C] font-normal text-center text-[13px] pt-[15px]">
                                        データをもう一度確認してから送信してください。
                                        <br />
                                        このデータを送信してもよろしいですか？
                                    </div>
                                    <div
                                        className="flex gap-[10px]"
                                        style={{ marginTop: '40px' }}
                                    >
                                        <button
                                            className="modal-create-user-button1 d-flex flex-row !w-[147px] !h-[36px] !rounded-[2xp]"
                                            onClick={closeConfirm}
                                        >
                                            キャンセル
                                        </button>
                                        <button
                                            onClick={() =>
                                                handleExport('upload')
                                            }
                                            disabled={
                                                uploadLoading || exportLoading
                                            }
                                            className="modal-create-user-button2 d-flex flex-row !w-[147px] !h-[36px] !rounded-[2xp]"
                                        >
                                            {uploadLoading && <Spin />}
                                            送信する
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </ModalConfirm>
                    </InvoicePDFStyle>
                )}
            </ConfigProvider>
        </ModalQuoteCustoms>
    );
});
export default InvoicePdf;
