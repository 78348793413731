import React, { useState, useEffect, FocusEventHandler } from 'react';
import { useParams, Link, useLocation } from 'react-router-dom';

import 'react-step-progress/dist/index.css';
import { useModal } from 'react-hooks-use-modal';
import { useNavigate } from 'react-router-dom';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import estimationService from '../../../../../services/estimationService';
import plus from '../../../../../assets/icons/Plus.png';
import { useLogicBudget } from '../hooks';
import ListBox from './ListBox';
import { Main } from './style';
import CustomPagination from 'components/CustomPagination';
import { IPageQueries } from 'models';
import budgetActionService from 'services/budgetActionService';
import { useForm } from 'react-hook-form';
import { validateFullWhitespace } from 'utils/validation/validatorHelper';
import { Controller } from 'react-hook-form';
import { styled } from '@mui/material/styles';
import { Button, Input, message, Upload, UploadProps } from 'antd';
import { fullWidthNumConvert } from 'pages/invoice';
import {
    NumberFormatBase,
    NumericFormat,
    useNumericFormat,
} from 'react-number-format';
import PlusIcon from 'assets/icons/white-plus.svg';
import { UploadChangeParam, RcFile, UploadFile } from 'antd/lib/upload';
import cloudUploadIcon from 'assets/icons/cloudUpload.svg';
import { DeleteOutlined } from '@mui/icons-material';
import pdfFileIcon from 'assets/icons/pdf-file.svg';
import { alertError, alertSuccessPopup } from 'utils/helper/appHelper';
import invoiceApi from 'services/invoiceApi';
import projectApi from 'services/projectApi';
import ExclamationIcon from 'assets/icons/exclamation.svg';
import pdfImg from 'assets/icons/img_pdf.jpg';
import clsx from 'clsx';
import EstimatePdf from '../estimatePdf';
import UploadWhite from 'assets/icons/upload-white.svg';
import UploadGrey from 'assets/icons/upload-grey.svg';
import UpArrow from 'assets/icons/up-arrow.svg';
import querystring from 'query-string';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: '#377ACD',
        color: '#fff',
        fontWeight: 600,
        padding: '6px 8px',
    },
    [`&.${tableCellClasses.body}`]: {
        fontFamily: 'Zen Kaku Gothic Antique',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '16px',
        lineHeight: '24px',
        color: '#1C1E21',
        padding: '0px 8px',
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const StyledDefaultInput = styled(Input)({
    padding: '8px 12px 8px 12px',
    // border: 'none',
    height: '31px',
    border: '1px solid #D9D9D9',
    width: '100%',
    borderRadius: '8px',
});
const { Dragger } = Upload;

const BudgetList = (props) => {
    const { project_id, getDetailProject, project, setTab } = props;
    const navigate = useNavigate();

    const { statusOptions, id } = useLogicBudget();
    const [budgets, setBudgets] = useState<any[]>([]);
    const [deleteRow, setDeleteRow] = useState<any>({});
    const [searchObject, setSearchObject] = useState<any>({
        plan_id: '',
        worker_id: '',
        start_at: null,
        end_at: null,
        free_word: '',
        project_id: id,
    });
    const [totalCount, setTotalCount] = useState<number>(0);
    const [pageQueries, setPageQueries] = useState<IPageQueries>({
        page: 1,
        per_page: 20,
    });
    const param = useParams();

    const {
        control,
        handleSubmit,
        watch,
        register,
        getValues,
        setError,
        setValue,
        formState: { errors },
        resetField,
        clearErrors,
    } = useForm();
    const [arrItem, setArrItem] = useState([1]);
    let storage = localStorage.getItem('user');
    const user = storage !== null ? JSON.parse(storage) : null;
    const [totalBeforeTax, setTotalBeforeTax] = useState(0);
    const [totalAfterTax, setTotalAfterTax] = useState(0);
    const [totalTax, setTotalTax] = useState(0);
    const [listType, setListType]: any = useState([]);
    const [filesListPdf, setFilesListPdf]: any = useState([]);
    const [filePdfList, setFilePdfList]: any = useState([]);
    const [totalFile, setTotalFile]: any = useState([]);
    const [isCheckSend, setIsCheckSend] = useState(false);
    const [curInd, setCurInd]: any = useState();
    const [estimate, setEstimate]: any = useState();
    const [ModalDelete, openDelete, closeDelete, isOpenDelete] = useModal(
        'root',
        {
            preventScroll: true,
        },
    );
    const location = useLocation();
    const queryObj = querystring.parse(location.search);

    const getBudgetForProject = async ({ page = 1, per_page = 0 }) => {
        try {
            const responseBudget =
                await budgetActionService.getListBudgetProject(Number(id), {
                    page,
                    per_page,
                });
            if (responseBudget !== null && responseBudget !== undefined) {
                setTotalCount(responseBudget.meta.total_count);
                setBudgets(responseBudget.budgets);
            }
        } catch (err) { }
    };

    const handleValidateWhitespace: FocusEventHandler<
        HTMLInputElement | HTMLTextAreaElement
    > = (e) => {
        const onlyContainWhitespaces = validateFullWhitespace(e.target.value);
        if (onlyContainWhitespaces) {
            setValue(e.target.name, '');
        }
    };
    const calTotal = () => {
        let totalBFTax = 0;
        let totalTax = 0;
        let totalAfterTax = 0;
        if (getValues('price').length > 0) {
            for (let i = 0; i < getValues('price').length; i++) {
                let price = getValues(`price.${i}`)
                    ? getValues(`price.${i}`).replace(/,/g, '')
                    : '';
                let quantity = getValues(`quantity.${i}`)
                    ? getValues(`quantity.${i}`).replace(/,/g, '')
                    : '';
                if (
                    Number(price) > -1 &&
                    Number(getValues(`tax.${i}`)) > -1 &&
                    Number(quantity) > -1
                ) {
                    totalBFTax += Number(price) * Number(quantity);
                    totalTax +=
                        (Number(getValues(`tax.${i}`)) *
                            Number(quantity) *
                            Number(price)) /
                        100;
                    totalAfterTax +=
                        Number(price) * Number(quantity) +
                        (Number(getValues(`tax.${i}`)) *
                            Number(quantity) *
                            Number(price)) /
                        100;
                }
            }
        }
        setTotalBeforeTax(totalBFTax);
        setTotalAfterTax(totalAfterTax);
        setTotalTax(totalTax);
    };

    const handleDeleteBudget = async () => {
        if (getValues(`id.${curInd}`)) {
            try {
                const res = await projectApi.deleteProjectEstimated(
                    user?.access_token,
                    estimate?.id,
                    getValues(`id.${curInd}`),
                    props.project_id,
                );
                if (res) {
                    onDelete(curInd);
                }
            } catch (error) {
                console.log('error', error);
            }
        } else {
            onDelete(curInd);
        }
        closeDelete();
    };

    const dragProps: UploadProps = {
        name: 'file',
        beforeUpload(file: RcFile) {
            // const isPDF = file.type === 'application/pdf';
            // if (!isPDF) {
            //     message.error('PDF形式でアップロードしてください。');
            // }
            if (file.type === 'application/pdf') {
                listType.push('pdf');
            }
            const isLE10MB = file.size / 1024 / 1024 <= 10;
            if (!isLE10MB) {
                message.error('ファイルは10MBを超えてはいけません。');
            }

            if (isLE10MB) {
                setFilePdfList((filePdfList) => {
                    return [...filePdfList, file];
                });
            }
            return isLE10MB || Upload.LIST_IGNORE;
        },
        onChange(info) {
            const filteredPdfAfterRemove = filePdfList.filter((bItem) =>
                info?.fileList?.some((aItem) => aItem.uid === bItem.uid),
            );
            setFilePdfList(filteredPdfAfterRemove);
            setValue('file', info.file.originFileObj);
        },
        onDrop(e) {
            //
        },
        customRequest({ file, onSuccess }) {
            setTimeout(() => {
                if (onSuccess) {
                    onSuccess('ok');
                }
            }, 0);
        },
    };

    const parseNamePdf = (file_path: any) => {
        const arrName: any = [];
        const string: any = decodeURIComponent(file_path);
        if (string !== null && string !== undefined) {
            for (let i = string?.length - 1; i > 0; i--) {
                if (string[i] !== '/') {
                    arrName.push(string[i]);
                } else {
                    break;
                }
            }
        }
        return arrName
            .slice()
            .reverse()
            .toString()
            .replaceAll(',', '')
            .split('.')[0];
    };

    const getDetail = async (arrEdit?, estimate_id?) => {
        try {
            const res = await projectApi.getDetailProjectEstimated(
                user?.access_token,
                estimate_id || estimate?.id || props?.project?.estimate?.id,
                props.project_id,
            );
            if (res) {
                const data = res.data?.estimate;
                setEstimate(data);
                let arrTmp: any = [];
                if (arrEdit) {
                    arrTmp = [...arrEdit];
                } else {
                    arrTmp = [...arrItem];
                }
                // setTotalBeforeTax(data.total_before_tax);
                // setTotalAfterTax(data.total_after_tax);
                // setTotalTax(data.total_tax);
                const pdfArr: any = [];
                if (data?.files) {
                    const arrName: any = [];
                    for (let i = 0; i < data?.files.length; i++) {
                        arrName.push(data?.files[i]?.file_path);
                        await fetch(data?.files)
                            .then((res) => res.blob())
                            .then((myBlob) => {
                                const myFile: any = new File(
                                    [myBlob],
                                    data?.files[i]?.file_path,
                                    {
                                        type: 'application/pdf',
                                    },
                                );
                                listType.push('pdf');
                                const myFileWithId = {
                                    file: myFile,
                                    id: data?.files[i]?.id,
                                    size: data?.files[i]?.size,
                                    signed_id: data?.files[i]?.signed_id
                                };
                                pdfArr.push(myFileWithId);
                            });
                    }
                }
                setValue('file', pdfArr[0]);
                setFilesListPdf(pdfArr);
                if (data.estimate_items.length > 0) {
                    const resCount = data.estimate_items.length > 1 ? 1 : 0;
                    for (let i = 0; i < data.estimate_items.length; i++) {
                        const countTmp = arrTmp[arrTmp.length - 1] || 0;
                        arrTmp.push(countTmp + 1);
                        setValue(
                            `id.${i}`,
                            data.estimate_items[i]
                                ? data.estimate_items[i].id
                                : '',
                        );
                        setValue(
                            `content.${i}`,
                            data.estimate_items[i]
                                ? data.estimate_items[i].name
                                : '',
                        );
                        setValue(
                            `price.${i}`,
                            data.estimate_items[i]
                                ? String(
                                    Number(
                                        data.estimate_items[i].unit_price,
                                    ).toLocaleString('en-US'),
                                )
                                : '',
                        );
                        setValue(
                            `quantity.${i}`,
                            data.estimate_items[i]
                                ? String(
                                    data.estimate_items[
                                        i
                                    ].quantity.toLocaleString('en-US'),
                                )
                                : '',
                        );
                        setValue(`tax.${i}`, 10);
                        setValue(
                            `unit.${i}`,
                            data.estimate_items[i]
                                ? data.estimate_items[i].unit
                                : '',
                        );
                    }
                }
                setArrItem(arrTmp);
                calTotal();
            }
        } catch (error) {
            console.log('error', error);
        }
    };

    const onDelete = (index) => {
        const filterArrItem = arrItem.filter((item, ind) => ind !== index);
        const contentFilter = getValues('content').filter(
            (item, ind) => ind !== index,
        );
        const unitFilter = getValues('unit').filter(
            (item, ind) => ind !== index,
        );
        const priceFilter = getValues('price').filter(
            (item, ind) => ind !== index,
        );
        const quantityFilter = getValues('quantity').filter(
            (item, ind) => ind !== index,
        );
        const taxFilter = getValues('tax').filter((item, ind) => ind !== index);
        const arrTmp: any = [];
        for (let i = 0; i < filterArrItem.length; i++) {
            const countTmp = arrTmp[arrTmp.length - 1] || 0;
            arrTmp.push(countTmp + 1);
        }
        setArrItem([...arrTmp]);
        setValue('content', [...contentFilter]);
        setValue('unit', [...unitFilter]);
        setValue('quantity', [...quantityFilter]);
        setValue('price', [...priceFilter]);
        setValue('tax', [...taxFilter]);
        calTotal();
    };

    const onAddArr = () => {
        const arrTmp = [...arrItem];
        if (arrItem.length > 0) {
            arrTmp.push(arrItem[arrItem.length - 1] + 1);
            setValue(`tax.${arrTmp.length - 1}`, 10);
            setValue(`unit.${arrTmp.length - 1}`, '式');
            setValue(`content.${arrTmp.length - 1}`, undefined);
        } else {
            arrTmp.push(1);
            setValue(`unit.0`, '式');
            setValue(`content.0`, undefined);
            setValue(`tax.0`, 10);
        }
        setArrItem(arrTmp);
    };

    const submitData = async (value) => {
        const arrTmp: any = [];
        if (
            value.content.length > 0 ||
            value.unit.length > 0 ||
            value.price.length > 0 ||
            value.quantity.length > 0
        ) {
            for (let i = 0; i < value.content.length; i++) {
                if (
                    value?.content[i] &&
                    value?.content[i]?.length > 0 &&
                    value?.unit[i] &&
                    value?.unit[i]?.length > 0 &&
                    value.quantity[i] &&
                    value.price[i]
                ) {
                    const price = Number(value.price[i].replace(/,/g, ''))
                    const quantity = Number(value.quantity[i].replace(/,/g, ''))
                    if (quantity >= 0 && price >= 0) {
                        const objTmp: any = {
                            name: value.content[i],
                            unit: value.unit[i] || '式',
                            quantity: quantity,
                            unit_price: price,
                            amount: quantity * price || 0,
                            total_price:
                                (quantity * price * 10) /
                                100 +
                                quantity * price || 0,
                        };
                        if (value?.id && value?.id[i]) {
                            objTmp.id = value?.id[i];
                        }
                        arrTmp.push(objTmp);
                    } else {
                        alertError(
                            '合計注文金額は¥0から¥99,999,999までの間である必要があります。',
                        );
                        return;
                    }
                } else {
                    alertError(
                        '内容、数量、単位、単価を入力する必要があります。',
                    );
                    return;
                }
            }
        }
        if (Number(totalAfterTax) >= 0 && Number(totalAfterTax) <= 99999999) {
            const body: any = {
                project_id: param.id,
                document: '',
                estimate_items_attributes: arrTmp,
            };
            const formData = new FormData();
            arrTmp.forEach((obj, index) => {
                for (let key in obj) {
                    formData.append(
                        `estimate_items_attributes[][${key}]`,
                        obj[key],
                    );
                }
            });
            formData.append('project_id', String(param.id));
            const filePdfListFormat = filePdfList?.filter(
                (item: any) => !item?.id,
            );
            filePdfListFormat?.forEach((file: any, index: number) => {
                formData.append('files[]', file);
            });
            if (isCheckSend) {
                body.is_send_message = true;
                formData.append('is_send_message', 'true');
            }
            if (estimate?.id) {
                try {
                    const res = await projectApi.editProjectEstimated(
                        user?.access_token,
                        formData,
                        estimate?.id,
                    );
                    if (res) {
                        const data = res.data?.estimate;
                        setEstimate(data);
                        alertSuccessPopup({
                            title: '請求書の作成を成功しました',
                            confirmButtonText: '閉じる',
                        });
                        setTotalFile([]);
                        setFilePdfList([]);
                        setFilesListPdf([]);
                        // getDetail([]);
                        setTab(1);
                        getDetailProject();
                    }
                } catch (error) {
                    console.log('error', error);
                }
                setIsCheckSend(false);
            } else {
                try {
                    const res = await projectApi.createProjectEstimated(
                        user?.access_token,
                        formData,
                    );
                    if (res) {
                        const data = res.data?.estimate;
                        setEstimate(data);
                        props.getProjectDetail();
                        alertSuccessPopup({
                            title: '請求書の作成を成功しました',
                            confirmButtonText: '閉じる',
                        });
                        setTotalFile([]);
                        setFilePdfList([]);
                        setFilesListPdf([]);
                        // getDetail([]);
                        // getDetail([], res.data?.estimate?.id);
                        setTab(1);
                        getDetailProject();
                    }
                } catch (error) {
                    console.log('error', error);
                }
                setIsCheckSend(false);
            }
        } else {
            alertError(
                '合計注文金額は¥0から¥99,999,999までの間である必要があります。',
            );
        }
        setIsCheckSend(false);
    };

    const handleBlur = () => {
        setArrItem([...arrItem]);
        calTotal();
    };

    const deleteFile = async (item, ind) => {
        if (item?.id) {
            try {
                const res = await projectApi.deleteFileEstimated(user?.access_token, estimate?.id, item?.signed_id)
                if (res) {
                    const arrTypeTmp = listType;
                    arrTypeTmp.filter((item, index,) => index !== ind,);
                    setListType((oldVal,) => {
                        return oldVal.filter((item, index,) => index !== ind,);
                    });
                    setFilesListPdf((oldVal,) => {
                        return oldVal.filter(
                            (item, index,) => index !== ind,)
                    },);
                    setFilePdfList((oldVal) => {
                        return oldVal.filter((itemFile, index,) =>
                            itemFile.uid !== item.uid,);
                    },
                    );
                    const filterFileRemove = totalFile?.filter((element,) =>
                        item?.uid ? element?.uid !== item?.uid : element?.lastModified !== item?.lastModified,
                    );
                    setTotalFile(filterFileRemove,);
                }
            } catch (error) {
                console.log('error', error)
            }
        } else {
            const arrTypeTmp = listType;
            arrTypeTmp.filter((item, index,) => index !== ind,);
            setListType((oldVal,) => {
                return oldVal.filter((item, index,) => index !== ind,);
            });
            setFilesListPdf((oldVal,) => {
                return oldVal.filter(
                    (item, index,) => index !== ind,)
            },);
            setFilePdfList((oldVal) => {
                return oldVal.filter((itemFile, index,) =>
                    itemFile.uid !== item.uid,);
            },
            );
            const filterFileRemove = totalFile?.filter((element,) =>
                item?.uid ? element?.uid !== item?.uid : element?.lastModified !== item?.lastModified,
            );
            setTotalFile(filterFileRemove,);
        }
    }

    useEffect(() => {
        setValue(`unit.0`, '式');
        setValue(`tax.0`, 10);
        setValue(`price.0`, '');
        setValue(`quantity.0`, '');
    }, []);
    useEffect(() => {
        if (props?.project?.estimate?.id) {
            getDetail([]);
        }
    }, [props?.project?.estimate?.id]);

    useEffect(() => {
        if (filesListPdf === null) {
            setTotalFile(filePdfList);
        } else {
            // if (filePdfList.length > 0) {
            //   filesListPdf.pop()
            // }
            setTotalFile(filesListPdf?.concat(filePdfList));
        }
    }, [filePdfList, filesListPdf]);

    const isDisabledField =
        project?.estimate !== null && project?.order !== null;

    return (
        <Main>
            <div className="d-flex flex-column">
                <div className="d-flex flex-column"></div>
                <form onSubmit={handleSubmit(submitData)}>
                    <div className="table-workers d-flex flex-column mt-[45px]">
                        <div className="table-content d-flex flex-column">
                            <TableContainer table-layout={'auto'}>
                                <Table
                                    sx={{ minWidth: 900 }}
                                    aria-label="customized table"
                                >
                                    <TableHead
                                        style={{
                                            background: '#377ACD',
                                            color: '#fff',
                                        }}
                                    >
                                        <TableRow>
                                            <StyledTableCell
                                                className="text-overflow1"
                                                style={{
                                                    width: '5%',
                                                    whiteSpace: 'nowrap',
                                                }}
                                            >
                                                <div>番号</div>
                                            </StyledTableCell>

                                            <StyledTableCell
                                                className="text-overflow1"
                                                style={{ width: '25%' }}
                                            >
                                                <div>内容</div>
                                            </StyledTableCell>

                                            <StyledTableCell
                                                className="text-overflow1"
                                                style={{ width: '10%' }}
                                            >
                                                <div>数量</div>
                                            </StyledTableCell>

                                            <StyledTableCell
                                                className="text-overflow1"
                                                style={{ width: '10%' }}
                                            >
                                                <div>単位</div>
                                            </StyledTableCell>

                                            <StyledTableCell
                                                className="text-overflow1"
                                                style={{ width: '12%' }}
                                            >
                                                <div>単価</div>
                                            </StyledTableCell>

                                            <StyledTableCell
                                                className="text-overflow1"
                                                style={{ width: '12%' }}
                                            >
                                                <div>金額</div>
                                            </StyledTableCell>
                                            <StyledTableCell
                                                className="text-overflow1"
                                                style={{
                                                    width: '12%',
                                                    textAlign: 'center',
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        whiteSpace: 'nowrap',
                                                        textAlign: 'center',
                                                    }}
                                                >
                                                    金額（税込）
                                                </div>
                                            </StyledTableCell>
                                            <StyledTableCell
                                                className="text-overflow1"
                                                style={{
                                                    width: '5%',
                                                    padding: '0px',
                                                }}
                                            >
                                                <div>操作</div>
                                            </StyledTableCell>
                                        </TableRow>
                                    </TableHead>

                                    <TableBody>
                                        {arrItem.map((row, ind) => {
                                            if (ind < arrItem.length) {
                                                let price =
                                                    getValues(`price.${ind}`) ||
                                                    '';
                                                let quantity =
                                                    getValues(
                                                        `quantity.${ind}`,
                                                    ) || '';
                                                if (
                                                    String(price).includes(',')
                                                ) {
                                                    price = price.replace(
                                                        /,/g,
                                                        '',
                                                    );
                                                }
                                                if (
                                                    String(quantity).includes(
                                                        ',',
                                                    )
                                                ) {
                                                    quantity = quantity.replace(
                                                        /,/g,
                                                        '',
                                                    );
                                                }
                                                return (
                                                    <StyledTableRow key={row}>
                                                        <StyledTableCell
                                                            className="!text-left"
                                                            component="th"
                                                            scope="row"
                                                            style={{
                                                                width: '5%',
                                                            }}
                                                        >
                                                            <div
                                                                style={{
                                                                    display:
                                                                        'flex',
                                                                    alignItems:
                                                                        'center',
                                                                }}
                                                            >
                                                                <span>
                                                                    {' '}
                                                                    {row}
                                                                </span>
                                                            </div>
                                                        </StyledTableCell>

                                                        <StyledTableCell
                                                            className="!text-left"
                                                            style={{
                                                                width: '25%',
                                                            }}
                                                        >
                                                            <Controller
                                                                name={`content.${ind}`}
                                                                control={
                                                                    control
                                                                }
                                                                render={({
                                                                    field,
                                                                }) => (
                                                                    <StyledDefaultInput
                                                                        {...register(
                                                                            `content.${ind}`,
                                                                        )}
                                                                        {...field}
                                                                        disabled={
                                                                            isDisabledField
                                                                        }
                                                                        onBlur={(evt) => {
                                                                            const value = evt.target.value;
                                                                            setValue(`content.${ind}`, value.trim());
                                                                        }}
                                                                    />
                                                                )}
                                                            />
                                                        </StyledTableCell>

                                                        <StyledTableCell
                                                            className="!text-left"
                                                            style={{
                                                                width: '10%',
                                                            }}
                                                        >
                                                            <div>
                                                                <Controller
                                                                    name={`quantity.${ind}`}
                                                                    control={
                                                                        control
                                                                    }
                                                                    render={({
                                                                        field,
                                                                    }) => (
                                                                        <StyledDefaultInput
                                                                            {...register(
                                                                                `quantity.${ind}`,
                                                                            )}
                                                                            onBlur={(evt,) => {
                                                                                let stringWithoutCommas = evt.target.value.replace(/,/g, '')
                                                                                setValue(`quantity.${ind}`, Number(fullWidthNumConvert(stringWithoutCommas)).toLocaleString('en-US'));
                                                                                handleBlur();
                                                                            }}
                                                                            value={
                                                                                field.value
                                                                            }
                                                                            onChange={(
                                                                                evt,
                                                                            ) => {
                                                                                let stringWithoutCommas =
                                                                                    evt.target.value.replaceAll(
                                                                                        /,/g,
                                                                                        '',
                                                                                    );
                                                                                if (
                                                                                    stringWithoutCommas.match(
                                                                                        '^[0-9０-９]+$',
                                                                                    ) ||
                                                                                    stringWithoutCommas ===
                                                                                    ''
                                                                                ) {
                                                                                    const newVal =
                                                                                        fullWidthNumConvert(
                                                                                            stringWithoutCommas,
                                                                                        );
                                                                                    field.onChange(
                                                                                        stringWithoutCommas,
                                                                                    );
                                                                                    setValue(
                                                                                        `quantity.${ind}`,
                                                                                        stringWithoutCommas,
                                                                                    );
                                                                                }
                                                                            }}
                                                                            maxLength={
                                                                                9
                                                                            }
                                                                            disabled={
                                                                                isDisabledField
                                                                            }
                                                                        />
                                                                    )}
                                                                />
                                                            </div>
                                                        </StyledTableCell>

                                                        <StyledTableCell
                                                            className="!text-left"
                                                            style={{
                                                                width: '10%',
                                                            }}
                                                        >
                                                            <Controller
                                                                name={`unit.${ind}`}
                                                                control={
                                                                    control
                                                                }
                                                                render={({
                                                                    field,
                                                                }) => (
                                                                    <StyledDefaultInput
                                                                        maxLength={
                                                                            3
                                                                        }
                                                                        style={{
                                                                            textAlign:
                                                                                'center',
                                                                        }}
                                                                        {...register(
                                                                            `unit.${ind}`,
                                                                        )}
                                                                        {...field}
                                                                        disabled={
                                                                            isDisabledField
                                                                        }
                                                                        onBlur={(evt) => {
                                                                            const value = evt.target.value;
                                                                            setValue(`unit.${ind}`, value.trim());
                                                                        }}
                                                                    />
                                                                )}
                                                            />
                                                        </StyledTableCell>

                                                        <StyledTableCell
                                                            align="center"
                                                            style={{
                                                                width: '12%',
                                                            }}
                                                        >
                                                            <Controller
                                                                name={`price.${ind}`}
                                                                control={
                                                                    control
                                                                }
                                                                render={({
                                                                    field,
                                                                }) => (
                                                                    <StyledDefaultInput
                                                                        {...register(
                                                                            `price.${ind}`,
                                                                        )}
                                                                        style={{
                                                                            textAlign:
                                                                                'right',
                                                                        }}
                                                                        onBlur={(evt) => {
                                                                            let stringWithoutCommas = evt.target.value.replace(/,/g, '')
                                                                            setValue(`price.${ind}`, Number(fullWidthNumConvert(stringWithoutCommas)).toLocaleString('en-US'),
                                                                            );
                                                                            handleBlur();
                                                                        }}
                                                                        value={
                                                                            field.value
                                                                        }
                                                                        onChange={(
                                                                            evt,
                                                                        ) => {
                                                                            let stringWithoutCommas =
                                                                                evt.target.value.replaceAll(/,/g, '');
                                                                            if (
                                                                                stringWithoutCommas.match(
                                                                                    '^[0-9０-９]+$',
                                                                                ) ||
                                                                                stringWithoutCommas ===
                                                                                ''
                                                                            ) {
                                                                                const newVal =
                                                                                    fullWidthNumConvert(
                                                                                        stringWithoutCommas,
                                                                                    );
                                                                                field.onChange(
                                                                                    stringWithoutCommas,
                                                                                );
                                                                                setValue(
                                                                                    `price.${ind}`,
                                                                                    stringWithoutCommas,
                                                                                );
                                                                            }
                                                                        }}
                                                                        maxLength={
                                                                            9
                                                                        }
                                                                        disabled={
                                                                            isDisabledField
                                                                        }
                                                                    />
                                                                )}
                                                            />
                                                        </StyledTableCell>

                                                        <StyledTableCell
                                                            align="center"
                                                            style={{
                                                                width: '12%',
                                                            }}
                                                        >
                                                            <NumericFormat
                                                                customInput={
                                                                    StyledDefaultInput
                                                                }
                                                                thousandSeparator=","
                                                                decimalSeparator="."
                                                                prefix={'￥'}
                                                                name="amount_money"
                                                                style={{
                                                                    textAlign:
                                                                        'right',
                                                                    background:
                                                                        '#fff',
                                                                    color: 'rgba(0, 0, 0, 0.85)',
                                                                }}
                                                                value={
                                                                    Number(
                                                                        price,
                                                                    ) *
                                                                    Number(
                                                                        quantity,
                                                                    ) || '0'
                                                                }
                                                                disabled
                                                            />
                                                        </StyledTableCell>
                                                        <StyledTableCell
                                                            align="center"
                                                            style={{
                                                                width: '12%',
                                                            }}
                                                        >
                                                            <NumericFormat
                                                                customInput={
                                                                    StyledDefaultInput
                                                                }
                                                                thousandSeparator=","
                                                                decimalSeparator="."
                                                                prefix={'￥'}
                                                                disabled
                                                                name="amount_money_tax"
                                                                style={{
                                                                    textAlign:
                                                                        'right',
                                                                    background:
                                                                        '#fff',
                                                                    color: 'rgba(0, 0, 0, 0.85)',
                                                                }}
                                                                value={
                                                                    (Number(price) *
                                                                        Number(quantity) +
                                                                        (Number(price) * Number(quantity) *
                                                                            Number(getValues(`tax.${ind}`))) / 100).toFixed(0) ||
                                                                    '0'
                                                                }
                                                            />
                                                        </StyledTableCell>
                                                        <StyledTableCell
                                                            align="center"
                                                            style={{
                                                                width: '5%',
                                                                paddingLeft:
                                                                    '0px',
                                                            }}
                                                        >
                                                            <div
                                                                style={{
                                                                    borderLeft:
                                                                        '1px solid #D9D9D9',
                                                                    height: '40px',
                                                                }}
                                                                className="flex justify-center items-center"
                                                            >
                                                                <button
                                                                    onClick={() => {
                                                                        setCurInd(
                                                                            ind,
                                                                        );
                                                                        openDelete();
                                                                    }}
                                                                    disabled={
                                                                        isDisabledField
                                                                    }
                                                                    type="button"
                                                                    className={clsx(
                                                                        'button-tb btn-delete !bg-[#ff5045]',
                                                                        {
                                                                            'cursor-no-drop':
                                                                                isDisabledField,
                                                                        },
                                                                    )}
                                                                >
                                                                    削除
                                                                </button>
                                                            </div>
                                                        </StyledTableCell>
                                                    </StyledTableRow>
                                                );
                                            }
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <EstimatePdf
                                invoice={estimate}
                                project={project}
                                totalItemPrice={totalBeforeTax}
                                totalAfterTax={totalAfterTax}
                            />
                        </div>
                        <div className="flex flex-row justify-center mt-[20px] w-full">
                            <Button
                                className="w-[114px] !h-[38px] text-white bg-[#215493] flex flex-row"
                                style={{
                                    borderRadius: '8px',
                                    background: '#215493',
                                    gap: '8px',
                                    marginTop: '10px',
                                }}
                                onClick={onAddArr}
                                disabled={isDisabledField}
                            >
                                <div className="flex flex-row gap-3">
                                    <img
                                        src={PlusIcon}
                                        alt="plus"
                                        height={16}
                                        width={16}
                                    />{' '}
                                    <span>追加</span>
                                </div>
                            </Button>
                        </div>

                        <div className="flex flex-row justify-between">
                            <div className="flex flex-col w-[45%] max-w-[500px] mt-3 gap-[15px]">
                                <Dragger
                                    {...dragProps}
                                    multiple={false}
                                    // fileList={filesListPdf}
                                    accept=".pdf"
                                    showUploadList={false}
                                    // maxCount={1}
                                    style={{ maxHeight: '91px' }}
                                // disabled={isDisabledField}
                                >
                                    <div className="flex p-3 items-center justify-between max-h-[91px]">
                                        <div className="flex items-center">
                                            <div className="mr-2">
                                                <img
                                                    src={cloudUploadIcon}
                                                    alt="cloud upload icon"
                                                />
                                            </div>
                                            <div className="text-left">
                                                <p className="ant-upload-text !text-[14px]">
                                                    ファイルを選択してください
                                                </p>
                                                <p className="ant-upload-hint !text-[12px] !whitespace-nowrap max-w-[244px] pr-[12px]">
                                                    10MB以下の、PDF
                                                    ファイルを対応します。
                                                </p>
                                            </div>
                                        </div>
                                        <div className="text-[#215493] !text-[14px] border-solid border-[1px] border-[#215493] rounded flex items-center px-3 py-2">
                                            選択
                                        </div>
                                    </div>
                                </Dragger>
                                <div className="flex flex-row flex-wrap gap-2">
                                    {totalFile?.length > 0 &&
                                        totalFile?.map(
                                            (item: any, ind: any) => (
                                                <>
                                                    <a
                                                        className="text-[#000000] !no-underline"
                                                        download
                                                    >
                                                        <div className="flex bg-[#F8F8F8] rounded-xl p-2">
                                                            <div className="flex justify-between items-center">
                                                                <a
                                                                    href={
                                                                        item
                                                                            ?.file
                                                                            ?.name
                                                                    }
                                                                    className="text-[#000000] !no-underline flex items-center"
                                                                    target="_blank"
                                                                >
                                                                    <img
                                                                        src={
                                                                            listType[
                                                                                ind
                                                                            ] ===
                                                                                'pdf'
                                                                                ? pdfImg
                                                                                : ''
                                                                        }
                                                                        alt="pdf file icon"
                                                                    />
                                                                    <div className="ml-3">
                                                                        <div className="text-ellipsis whitespace-nowrap overflow-hidden	max-w-[450px] ">
                                                                            {!item?.uid
                                                                                ? parseNamePdf(
                                                                                    item
                                                                                        ?.file
                                                                                        ?.name,
                                                                                )
                                                                                : item?.name?.substring(
                                                                                    0,
                                                                                    item
                                                                                        ?.name
                                                                                        ?.length -
                                                                                    4,
                                                                                )}
                                                                        </div>
                                                                        <div className="text-[#0000008C]">
                                                                            {item?.id
                                                                                ? item?.size
                                                                                : Number(
                                                                                    item?.size /
                                                                                    1024,
                                                                                )?.toFixed(
                                                                                    1,
                                                                                ) +
                                                                                'KB'}
                                                                        </div>
                                                                    </div>
                                                                </a>
                                                                <div>
                                                                    <button
                                                                        onClick={() => {
                                                                            // const arrTypeTmp = listType;
                                                                            // arrTypeTmp.filter((item, index,) => index !== ind,);
                                                                            // setListType((oldVal,) => {
                                                                            //     return
                                                                            //     oldVal.filter((item, index,) => index !== ind,);
                                                                            // });
                                                                            // setFilesListPdf((oldVal,) => {
                                                                            //     return oldVal.filter(
                                                                            //         (item, index,) => index !== ind,)
                                                                            // },);
                                                                            // setFilePdfList((oldVal) => {
                                                                            //     return oldVal.filter((itemFile, index,) =>
                                                                            //         itemFile.uid !== item.uid,);
                                                                            // },
                                                                            // );
                                                                            // const filterFileRemove = totalFile?.filter((element,) =>
                                                                            //     item?.uid ? element?.uid !== item?.uid : element?.lastModified !== item?.lastModified,
                                                                            // );
                                                                            // setTotalFile(filterFileRemove,);
                                                                            deleteFile(item, ind)
                                                                        }}
                                                                        type="button"
                                                                        className={clsx(
                                                                            'button-tb btn-delete !bg-[#ff5045]',
                                                                            { 'cursor-no-drop': item?.id && isDisabledField, },
                                                                        )}
                                                                        style={{
                                                                            transform:
                                                                                'scale(0.7)',
                                                                        }}
                                                                        disabled={
                                                                            item?.id &&
                                                                            isDisabledField
                                                                        }
                                                                    >
                                                                        削除
                                                                    </button>
                                                                    {/* <DeleteOutlined /> */}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </a>
                                                </>
                                            ),
                                        )}
                                </div>
                            </div>
                            <div className="flex flex-col items-end text-[14px] p-[5px]">
                                <div
                                    className="flex flex-col bg-[#E8F2FF] w-[300px] p-[5px]"
                                    style={{ borderRadius: '8px' }}
                                >
                                    <div className="flex flex-row justify-between h-[32px]">
                                        <p style={{ color: '#0000008C' }}>
                                            小計
                                        </p>
                                        <p>
                                            ¥
                                            {totalBeforeTax.toFixed().toLocaleString()}
                                        </p>
                                    </div>
                                    <div className="flex flex-row justify-between h-[32px]">
                                        <p style={{ color: '#0000008C' }}>
                                            割引
                                        </p>
                                        <p>¥0</p>
                                    </div>
                                    <div className="flex flex-row justify-between h-[32px]">
                                        <p style={{ color: '#0000008C' }}>
                                            消費税（10％）
                                        </p>
                                        <p>
                                            ¥{totalTax.toFixed().toLocaleString()}
                                        </p>
                                    </div>
                                    <div className="flex flex-row justify-between h-[32px]">
                                        <p style={{ color: '#0000008C' }}>
                                            合計
                                        </p>
                                        <p>
                                            ¥
                                            {totalAfterTax.toFixed().toLocaleString()}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div
                        className="bg-[#fff] flex flex-row justify-between"
                        style={{ padding: '16px 24px 16px 24px' }}
                    >
                        <div className="flex flex-row">
                            <button
                                className="modal-create-user-button2 !rounded-[2px] d-flex flex-row mx-[15px] w-[140px]"
                                style={{
                                    width: '140px',
                                    borderRadius: '2px',
                                    background: `${!estimate?.status
                                        ? '#F5F5F5'
                                        : '#215493'
                                        }`,
                                    border: '1px solid #D9D9D9',
                                    //  background: '#215493',
                                    // color: '#fff'
                                    color: `${!estimate?.status ? '#8C8C8C' : '#fff'
                                        }`,
                                }}
                                disabled={!estimate?.status}
                                type="button"
                                onClick={() =>
                                    navigate(
                                        `/projects/detail/${id}/plan${queryObj?.quote_id
                                            ? `?quote_id=${queryObj?.quote_id}&`
                                            : '?'
                                        }${queryObj?.invoice_id
                                            ? `invoice_id=${queryObj?.invoice_id}&`
                                            : ''
                                        }${queryObj?.prev
                                            ? `prev=${queryObj?.prev}&`
                                            : ''
                                        }viewPdf=true`,
                                    )
                                }
                            >
                                <img
                                    src={
                                        !estimate?.status
                                            ? UploadGrey
                                            : UploadWhite
                                    }
                                    alt=""
                                />
                                PDF見積書
                            </button>
                        </div>
                        <div
                            className="d-flex flex-row text-lg"
                            style={{
                                marginTop: '14px',
                            }}
                        >
                            <button
                                className={clsx(
                                    'modal-create-user-button1 d-flex flex-row w-[140px]',
                                )}
                                style={{
                                    width: '140px',
                                    borderRadius: '0px',
                                    border: '1px solid #D9D9D9',
                                    color: '#000000D9',
                                }}
                                type="button"
                                onClick={() => navigate(-1)}
                            >
                                キャンセル
                            </button>

                            <button
                                className={clsx(
                                    'modal-create-user-button2 d-flex flex-row mx-[15px] w-[140px]',
                                )}
                                // disabled={invoice?.status === 'paid'}
                                style={{
                                    width: '140px',
                                    borderRadius: '0px',
                                    // background: `${invoice?.status === 'paid' && '#CCCCCC'
                                    //   }`,`
                                }}
                                type="submit"
                            >
                                保存
                            </button>

                            <button
                                className={clsx(
                                    'modal-create-user-button2 !bg-[#02A509] w-[140px] d-flex flex-row',
                                )}
                                type="submit"
                                style={{ width: '140px', borderRadius: '0px' }}
                                onClick={() => {
                                    setIsCheckSend(true);
                                }}
                            >
                                LINE送信
                            </button>
                        </div>
                    </div>
                </form>
                {/* {budgets && budgets.length > 0 && (
          <CustomPagination
            totalCount={totalCount}
            pageQueries={pageQueries}
            setPageQueries={setPageQueries}
            fetchList={(page) =>
              getBudgetForProject({ page, per_page: pageQueries.per_page })
            }
          />
        )} */}
                <ModalDelete>
                    <div
                        className="modal-create-user d-flex flex-column"
                        style={{ height: '250px' }}
                    >
                        <div className="flex flex-col justify-center items-center pt-[15px] pb-[10px]">
                            <img
                                src={ExclamationIcon}
                                className="w-[80px] h-[80px]"
                                alt="exclamation icon"
                            />
                        </div>
                        <div className="modal-create-user-title d-flex flex-row">
                            本当に削除しますか？
                        </div>
                        <div
                            className="modal-create-user-buttons d-flex flex-row"
                            style={{ marginTop: '50px' }}
                        >
                            <button
                                className="modal-create-user-button1 d-flex flex-row"
                                onClick={closeDelete}
                            >
                                キャンセル
                            </button>
                            <button
                                className="modal-create-user-button2 d-flex flex-row"
                                onClick={handleDeleteBudget}
                            >
                                はい
                            </button>
                        </div>
                    </div>
                </ModalDelete>
            </div>
        </Main>
    );
};

export default BudgetList;
