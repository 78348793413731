import { useState, useEffect, FocusEventHandler } from 'react';
import './companyCreate.css';
import { useNavigate } from 'react-router-dom';
import 'react-dropdown/style.css';
import Autocomplete from '@mui/material/Autocomplete';
import { TextField } from '@mui/material';
import DatePicker, { registerLocale } from 'react-datepicker';
import calendarIcon from '../../../assets/icons/calendar.png';
import { Checkbox } from '@fluentui/react/lib/Checkbox';
import CooperativeCompanyService from '../../../services/cooperativeCompaniesService';
import ja from 'date-fns/locale/ja';
registerLocale('ja', ja);
import createClientCompanyValidation from 'utils/validation/registers/clientCompanies/createClientCompany';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import CompanyService from '../../../services/clientCompanyService';
import {
    convertClientCompaniesToDropdownOptions,
    removeUndefinedCompany,
} from 'utils/helper/clientCompanyHelper';
import { Input } from 'antd';
import { fullWidthNumConvert } from 'pages/invoice';
import { RequireSign } from 'components/common';
import { formatPostalCode } from 'utils/helper/postalCodeHelper';
import { MAX_LENGTH } from 'pages/project/projectPlan/projectTabs/constant';
import { validateFullWhitespace } from 'utils/validation/validatorHelper';
import {
    formatNumber,
    removeSpecialCharacters,
} from 'utils/constants/function';
import { alertError } from 'utils/helper/appHelper';

export const convertToHalf = (number) => {
    //e.value = e.value.normalize('NFKC');
    number = number.replace(/[！-～]/g, (halfwidthChar) =>
        String.fromCharCode(halfwidthChar.charCodeAt(0) - 0xfee0),
    );
};

const industryOptions = [
    { label: '', value: '' },
    { label: '', value: '' },
    { label: '', value: '' },
    { label: '', value: '' },
];

const CooperativeCompanyCreate = (props) => {
    const navigate = useNavigate();
    const [startDate, setStartDate] = useState(null);
    const { t } = useTranslation();
    const {
        register,
        control,
        handleSubmit,
        watch,
        reset,
        setError,
        getValues,
        formState: { errors },
        setValue,
    } = useForm();

    const [companyOption, setCompanyOption] = useState<any>([]);

    const [checkClient, setCheckClient] = useState(false);
    const [checkCooperative, setCheckCooperative] = useState(true);

    const handleStartAtChange = (date: any) => {
        setStartDate(date);
    };

    const getListClientCompany = async () => {
        try {
            const response = await CompanyService.getListCompanies();
            if (response !== null && response !== undefined) {
                let tempListClient = convertClientCompaniesToDropdownOptions(
                    response.client_companies,
                );
                const finalClients = removeUndefinedCompany(tempListClient);
                setCompanyOption(finalClients);
            }
        } catch (err) {
            console.log('err', err);
        }
    };

    const getListCooperativeCompanies = async () => {
        try {
            const response =
                await CooperativeCompanyService.getListCooperativeCompanies();
            if (response !== null && response !== undefined) {
                let tempListClient = convertClientCompaniesToDropdownOptions(
                    response.cooperative_companies,
                );
                const finalClients = removeUndefinedCompany(tempListClient);
                setCompanyOption(finalClients);
            }
        } catch (err) {
            console.log('err', err);
        }
    };

    const getListCompany = async () => {
        try {
            let listClient: any = [];
            let listCooperative: any = [];
            const response = await CompanyService.getListCompanies();
            if (response !== null && response !== undefined) {
                listClient = convertClientCompaniesToDropdownOptions(
                    response.client_companies,
                );
            }
            const response2 =
                await CooperativeCompanyService.getListCooperativeCompanies();
            if (response2 !== null && response2 !== undefined) {
                listCooperative = convertClientCompaniesToDropdownOptions(
                    response2.cooperative_companies,
                );
            }
            const finalClients = removeUndefinedCompany(
                listClient.concat(listCooperative),
            );
            setCompanyOption(finalClients);
        } catch (err) {
            console.log('err', err);
        }
    };

    useEffect(() => {
        checkClient && !checkCooperative && getListClientCompany();
        !checkClient && checkCooperative && getListCooperativeCompanies();
        checkClient && checkCooperative && getListCompany();
    }, [checkClient, checkCooperative]);

    useEffect(() => {
        if (checkClient) {
            setValue('is_client_company', true);
        } else {
            setValue('is_client_company', false);
        }
    }, [checkClient]);

    useEffect(() => {
        if (checkCooperative) {
            setValue('is_cooperative_company', true);
        } else {
            setValue('is_cooperative_company', false);
        }
    }, [checkCooperative]);

    const onCreate = (data) => {
        const dataFormat = {
            ...data,
            ...data,
            name: data?.name?.trim() ?? '',
            email: data?.email?.trim() ?? '',
            phone: data?.phone ?? '',
            fax: data?.fax ?? '',
            zipcode: data?.zipcode?.trim() ?? '',
            province: data?.province?.trim() ?? '',
            company_address: data?.company_address?.trim() ?? '',
            capital: data?.capital?.trim() ?? '',
            number_of_employees: data?.number_of_employees ?? '',
            business_content: data?.business_content?.trim() ?? '',
            business_type: data?.business_type?.trim() ?? '',
            note: data?.note?.trim() ?? '',
            person_in_charge_phone_number:
                data?.person_in_charge_phone_number ?? '',
            person_in_charge: data?.person_in_charge?.trim() ?? '',
        };

        return new Promise(async (resolve, reject) => {
            // await CooperativeCompanyService.createCooperativeCompany(dataFormat)
            //     .then(() => {
            //         navigate('/cooperative-companies');
            //     })
            //     .catch((err) => {
            //         const response = err?.response;
            //         if (!response || typeof response === 'undefined') {
            //             // window.location.href = '/login';
            //             reject(err);
            //         }
            //         reject(err);
            //     });
            // resolve(true);
            try {
                await CooperativeCompanyService.createCooperativeCompany(
                    dataFormat,
                );
                navigate('/cooperative-companies');
                resolve(true);
            } catch (err) {
                const response = err?.response?.data?.error;
                alertError(response);
                if (!response || typeof response === 'undefined') {
                    // window.location.href = '/login';
                }
            }
        });
    };

    const handleValidateWhitespace: FocusEventHandler<
        HTMLInputElement | HTMLTextAreaElement
    > = (e) => {
        const onlyContainWhitespaces = validateFullWhitespace(e.target.value);
        if (onlyContainWhitespaces) {
            setValue(e.target.name, '');
        } else {
            setValue(e.target.name, e.target.value.trim());
        }
    };

    return (
        <form
            onSubmit={handleSubmit(onCreate)}
            className="container-company-create-detail d-flex flex-row"
        >
            <div className="company-create-content d-flex flex-column !pl-2">
                <div className="pl-3 z-50">
                    <div
                        className="worker-create-title d-flex flex-column"
                        style={{ alignItems: 'flex-start' }}
                    >
                        協力会社 新規作成
                    </div>
                    <div className="input-add-user margin-input d-flex flex-column">
                        <p>
                            <RequireSign /> 顧客
                        </p>
                        <input
                            autoComplete="off"
                            type="text"
                            // required
                            className="create-company-input"
                            {...register(
                                'name',
                                createClientCompanyValidation(t).name(),
                            )}
                            onBlur={handleValidateWhitespace}
                            maxLength={MAX_LENGTH}
                        ></input>
                        {errors.name && (
                            <span className="error">
                                {errors.name.message?.toString()}
                            </span>
                        )}
                    </div>
                    <div className="input-add-user margin-input d-flex flex-column">
                        <p style={{ marginTop: 0 }}>親会社</p>
                        <Controller
                            name="parent_company_id"
                            control={control}
                            render={({ field: { value } }) => (
                                <Autocomplete
                                    disablePortal
                                    value={{
                                        label: value?.label || '',
                                        value: value?.value || '',
                                    }}
                                    options={companyOption.map((item) => ({
                                        label: item.label,
                                        value: String(item.value),
                                    }))}
                                    getOptionLabel={(option: any) =>
                                        option.label || option.value
                                    }
                                    onChange={(event, newValue) => {
                                        setValue('parent_company_id', newValue);
                                    }}
                                    classes={{
                                        root: 'cooperative-company-create-search-select-style',
                                        input: 'cooperative-company-create-search-select-style',
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label=""
                                            className="autocomplete-custom-textfield"
                                        />
                                    )}
                                    noOptionsText="該当なし"
                                />
                            )}
                        />
                        {errors.parent_company && (
                            <span className="error">
                                {errors.parent_company.message?.toString()}
                            </span>
                        )}
                    </div>
                    <div className="input-add-user margin-input d-flex flex-column">
                        <p style={{ marginTop: 0 }}>業種</p>
                        <input
                            autoComplete="off"
                            type="text"
                            // required
                            className="create-company-input"
                            {...register('business_type')}
                            onBlur={handleValidateWhitespace}
                            maxLength={MAX_LENGTH}
                        ></input>
                    </div>
                    <div className="input-add-user margin-input d-flex flex-column">
                        <p>会社属性</p>
                        <div className="checkbox checkbox-create-company d-flex flex-row">
                            <Checkbox
                                styles={
                                    checkClient
                                        ? checkBoxStyles
                                        : checkBoxStyles2
                                }
                                {...register('is_client_company')}
                                checked={checkClient}
                                onChange={() => setCheckClient(!checkClient)}
                            />
                            <div className="checkbox-text ">顧客</div>
                        </div>

                        <div
                            className="checkbox checkbox-create-company d-flex flex-row"
                            style={{ marginTop: '8px' }}
                        >
                            <Checkbox
                                styles={
                                    checkCooperative
                                        ? checkBoxStyles
                                        : checkBoxStyles2
                                }
                                {...register('is_cooperative_company')}
                                checked={checkCooperative}
                                onChange={() =>
                                    setCheckCooperative(!checkCooperative)
                                }
                            />
                            <div className="checkbox-text">協力会社</div>
                        </div>
                    </div>
                    <div className="input-add-user margin-input d-flex flex-column">
                        <p>Email</p>
                        <input
                            className="create-company-input"
                            autoComplete="off"
                            type="text"
                            // required
                            {...register(
                                'email',
                                createClientCompanyValidation(t).email(),
                            )}
                            onBlur={handleValidateWhitespace}
                            maxLength={MAX_LENGTH}
                        ></input>
                        {errors.email && (
                            <span className="error">
                                {errors.email.message?.toString()}
                            </span>
                        )}
                    </div>
                    <div className="input-add-user margin-input d-flex flex-column">
                        <p>電話番号</p>
                        {/* <input
                        className="create-company-input"
                        autoComplete="off"
                        type="text"
                        // required
                        {...register(
                            'phone',
                            createClientCompanyValidation(t).phone(),
                        )}
                    ></input> */}
                        <Controller
                            name="phone"
                            // rules={{ required: true }}
                            control={control}
                            render={({ field }) => {
                                // const { name, onChange, onBlur, ref } = register('phone', createClientCompanyValidation(t).phone());
                                return (
                                    <div className="d-flex flex-column">
                                        <Input
                                            className="create-company-input"
                                            minLength={10}
                                            maxLength={12}
                                            {...register('phone')}
                                            onBlur={(evt) => {
                                                if (
                                                    evt.target.value.length > 0
                                                ) {
                                                    setValue(
                                                        'phone',
                                                        fullWidthNumConvert(
                                                            evt.target.value,
                                                        ),
                                                    );
                                                }

                                                // onBlur()
                                            }}
                                            value={field.value}
                                            onChange={(evt) => {
                                                let stringWithoutCommas =
                                                    evt.target.value.replace(
                                                        /,/g,
                                                        '',
                                                    );
                                                stringWithoutCommas =
                                                    evt.target.value.replace(
                                                        /[a-zA-Z]/g,
                                                        '',
                                                    );
                                                if (
                                                    stringWithoutCommas.match(
                                                        '^[0-9０-９]+$',
                                                    ) ||
                                                    stringWithoutCommas === ''
                                                ) {
                                                    if (
                                                        stringWithoutCommas.length <=
                                                        12
                                                    ) {
                                                        const newVal =
                                                            fullWidthNumConvert(
                                                                stringWithoutCommas,
                                                            );
                                                        field.onChange(
                                                            stringWithoutCommas,
                                                        );
                                                        setValue(
                                                            'phone',
                                                            stringWithoutCommas,
                                                        );
                                                    }
                                                }
                                            }}
                                        />
                                        {errors.phone && (
                                            <span className="error text-xs tracking-tight mt-1">
                                                {errors.phone.message?.toString()}
                                            </span>
                                        )}
                                    </div>
                                );
                            }}
                        />
                    </div>
                    <div className="input-add-user margin-input d-flex flex-column">
                        <p>FAX</p>
                        <input
                            className="create-company-input"
                            autoComplete="off"
                            type="text"
                            // required
                            {...register(
                                'fax',
                                createClientCompanyValidation(t).fax(),
                            )}
                            onBlur={(evt) => {
                                setValue(
                                    'fax',
                                    fullWidthNumConvert(evt.target.value),
                                );
                            }}
                            maxLength={10}
                        ></input>
                        {errors.fax && (
                            <span className="error">
                                {errors.fax.message?.toString()}
                            </span>
                        )}
                    </div>
                </div>

                <div className="input-add-user margin-input d-flex flex-column pt-1">
                    <div className="container-address">
                        <span className="title">住所</span>
                        <div className="input-add-user margin-input d-flex flex-column pt-2">
                            <p className="z-1">
                                <RequireSign />
                                郵便番号
                            </p>
                            <input
                                className="create-company-input"
                                autoComplete="off"
                                type="text"
                                // required
                                {...register(
                                    'zipcode',
                                    createClientCompanyValidation(t).zipcode(),
                                )}
                                onChange={(evt) => {
                                    const dataFormat = formatPostalCode(evt);
                                    setValue('zipcode', dataFormat);
                                }}
                                maxLength={255}
                                onBlur={handleValidateWhitespace}
                            />
                            {errors.zipcode && (
                                <span className="error">
                                    {errors.zipcode.message?.toString()}
                                </span>
                            )}
                        </div>
                        <div className="input-add-user margin-input d-flex flex-column">
                            <p>
                                <RequireSign />
                                都道府県
                            </p>
                            <input
                                className="create-company-input"
                                autoComplete="off"
                                type="text"
                                // required
                                {...register(
                                    'province',
                                    createClientCompanyValidation(t).province(),
                                )}
                                onBlur={handleValidateWhitespace}
                                maxLength={MAX_LENGTH}
                            ></input>
                            {errors.province && (
                                <span className="error">
                                    {errors.province.message?.toString()}
                                </span>
                            )}
                        </div>
                        <div className="input-add-user margin-input d-flex flex-column !mb-0">
                            <p>
                                <RequireSign />
                                都市名、住所、番地、アパート名、部屋番号
                            </p>
                            <input
                                className="create-company-input"
                                autoComplete="off"
                                type="text"
                                // required
                                {...register(
                                    'company_address',
                                    createClientCompanyValidation(
                                        t,
                                    ).company_address(),
                                )}
                                onBlur={handleValidateWhitespace}
                                maxLength={MAX_LENGTH}
                            ></input>
                            {errors.company_address && (
                                <span className="error">
                                    {errors.company_address.message?.toString()}
                                </span>
                            )}
                        </div>
                    </div>
                </div>

                <div className="pl-3">
                    <div
                        className="input-add-user margin-input d-flex flex-column"
                        style={{ width: '343px' }}
                    >
                        <p>設立日</p>
                        <div
                            className="create-company-input"
                            style={{ position: 'relative', width: '343px' }}
                        >
                            <Controller
                                name="date_of_establishment"
                                control={control}
                                render={({ field }) => (
                                    <DatePicker
                                        id="date_of_establishment"
                                        selected={startDate}
                                        {...register(
                                            'date_of_establishment',
                                            // createClientCompanyValidation(
                                            //     t,
                                            // ).date_of_establishment(),
                                        )}
                                        {...field}
                                        onChange={(date) => {
                                            field?.onChange(date);
                                            handleStartAtChange(date);
                                        }}
                                        dateFormat="yyyy/MM/dd"
                                        autoComplete="off"
                                        locale="ja"
                                    />
                                )}
                            />
                            <label htmlFor="date_of_establishment">
                                <img
                                    className="input-date-img"
                                    // style={{ position: 'absolute', right: '0px' }}
                                    width={'16px'}
                                    height={'16px'}
                                    src={calendarIcon}
                                ></img>
                            </label>
                        </div>
                        {errors.date_of_establishment && (
                            <span className="error">
                                {errors.date_of_establishment.message?.toString()}
                            </span>
                        )}
                    </div>
                    <div className="input-add-user margin-input d-flex flex-column">
                        <p>資本金</p>
                        <input
                            className="create-company-input"
                            autoComplete="off"
                            type="text"
                            // required
                            {...register(
                                'capital',
                                // createClientCompanyValidation(t).capital(),
                            )}
                            onBlur={handleValidateWhitespace}
                            maxLength={MAX_LENGTH}
                        ></input>
                        {errors.capital && (
                            <span className="error">
                                {errors.capital.message?.toString()}
                            </span>
                        )}
                    </div>

                    <div className="input-add-user margin-input d-flex flex-column">
                        <p>従業員数</p>
                        <input
                            className="create-company-input"
                            autoComplete="off"
                            type="number"
                            // required
                            {...register(
                                'number_of_employees',
                                // createClientCompanyValidation(
                                //     t,
                                // ).number_of_employees(),
                            )}
                            maxLength={9}
                        ></input>
                        {errors.number_of_employees && (
                            <span className="error">
                                {errors.number_of_employees.message?.toString()}
                            </span>
                        )}
                    </div>

                    <div className="input-add-user margin-input d-flex flex-column">
                        <p>担当者名</p>
                        <input
                            className="create-company-input"
                            autoComplete="off"
                            {...register('person_in_charge')}
                        />
                    </div>

                    <div className="input-add-user margin-input d-flex flex-column">
                        <p>担当者電話番号</p>
                        <input
                            className="create-company-input"
                            autoComplete="off"
                            {...register('person_in_charge_phone_number')}
                            onChange={(evt) => {
                                const valueFormatted = removeSpecialCharacters(
                                    evt?.target?.value?.toString(),
                                );
                                setValue(
                                    'person_in_charge_phone_number',
                                    valueFormatted,
                                );
                            }}
                        />
                    </div>

                    <div className="input-add-user d-flex flex-column">
                        <p style={{ marginTop: 0 }}>事業内容</p>
                        <textarea
                            autoComplete="off"
                            // required
                            className="dropdown-create-user2 create-company-input"
                            {...register(
                                'business_content',
                                // createClientCompanyValidation(t).business_content(),
                            )}
                            maxLength={255}
                            onBlur={handleValidateWhitespace}
                        ></textarea>
                        {errors.business_content && (
                            <span className="error">
                                {errors.business_content.message?.toString()}
                            </span>
                        )}
                    </div>
                    <div
                        className="input-add-user d-flex flex-column"
                        style={{ marginTop: '8px' }}
                    >
                        <p style={{ marginTop: 0 }}>備考</p>
                        <textarea
                            autoComplete="off"
                            // required
                            className="dropdown-create-user2 create-company-input"
                            {...register('note')}
                            maxLength={255}
                            onBlur={handleValidateWhitespace}
                        ></textarea>
                    </div>
                    <div
                        className="modal-create-user-buttons create-button-company"
                        style={{ marginTop: '14px' }}
                    >
                        <button
                            className="modal-create-user-button1"
                            onClick={() => navigate('/cooperative-companies')}
                        >
                            戻る
                        </button>
                        <button
                            className="modal-create-user-button2"
                            style={{ marginLeft: '5px' }}
                            // disabled={(newUser.email === "" || newUser.password === "" || newUser.password_confirmation === "" || newUser.worker_id === -1) ? true : false}
                            // onClick={handleCreateUser}
                            type="submit"
                        >
                            登録
                        </button>
                    </div>
                </div>
            </div>
        </form>
    );
};
const checkBoxStyles = {
    checkmark: {
        background: '#3DDD74',
        color: 'white',
    },
    checkbox: {
        background: '#3DDD74',
        borderColor: '#3DDD74',
        width: '15px',
        margin: '5px 15px 0 0',
        height: '15px',
    },
    // text: {
    //   fontWeight: "bold",
    //   color:'brown'
    // }
};

const checkBoxStyles2 = {
    checkmark: {
        background: '#3DDD74',
        color: 'white',
    },
    checkbox: {
        background: '#FFF',
        borderColor: '#CDD1D5',
        width: '15px',
        margin: '5px 15px 0 0',
        height: '15px',
    },
};
export default CooperativeCompanyCreate;
