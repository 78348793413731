/* eslint-disable no-unused-vars */
/* eslint-disable no-restricted-syntax */
import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import moment from 'moment';
import { Chart as ChartJS, registerables } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { size } from 'lodash';

import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import { MONTHS_IN_YEAR, WEEKS_IN_MONTH } from 'utils/constants';
import ChartBoxStyle from './ChartBoxStyle';
import { useLogicChart } from './hook';

ChartJS.register(...registerables);

export const handleRenderChartHeight = () => {
    const width = window.innerWidth || 1450;
    const minWidth = 1450;
    const size = 10;
    // if (width <= 1350) size = 12;

    return size * (minWidth / width);
};

export const createadYearList = (startYear, endYear) => {
    const draftYear = [
        {
            id: '',
            name: 'すべて',
        },
    ];
    // eslint-disable-next-line no-plusplus
    for (let i = startYear; i >= endYear; i--) {
        draftYear.push({
            id: i,
            name: `${i}年`,
        });
    }
    return draftYear;
};

const ChartBox = ({ formik, workingTimeData }) => {
    const { time_range, week_number } = formik.values;
    const heightChart = handleRenderChartHeight();
    const { options, getColorForchart, getDataChartForMonthOrWeek }: any =
        useLogicChart();
    const [dataChart, setDataChart] = useState([]);
    const [labelsChart, setLabelsChart] = useState([]);
    useEffect(() => {
        if (workingTimeData) {
            const newData: any = Object.entries(workingTimeData);
            const data: any = [];
            const lables: any = [];
            for (const [key, value] of newData) {
                lables.push(key);
                data.push(value);
            }
            setDataChart(data);
            setLabelsChart(lables);
        } else {
            setDataChart([]);
            setLabelsChart([]);
        }
    }, [workingTimeData]);
    console.log('workingTimeData size', size(workingTimeData))
    console.log('workingTimeData', workingTimeData)
    console.log('heightChart', heightChart)
    return (
        <>
            <ChartBoxStyle>
                <form onSubmit={formik.handleSubmit} className="filterBox">
                    {/* <div className="viewTypeBox">
        <button
          type="button"
          onClick={() => {
            formik.setFieldValue('data_type', 'salary');
            formik.submitForm();
          }}
          className={classNames(data_type === 'salary' ? 'active' : '')}
        >
          勤怠月
        </button>
        <button
          type="button"
          onClick={() => {
            formik.setFieldValue('data_type', 'attendance');
            formik.submitForm();
          }}
          className={classNames(data_type === 'attendance' ? 'active' : '')}
        >
          給与月
        </button>
      </div> */}

                    <div className="selectionBox">
                        <div className="boxLeft">
                            <div className="wrapSelectField !w-[100px]">
                                <FormControl fullWidth>
                                    <Select
                                        // hiddenLabel
                                        size="small"
                                        placeholder="すべて"
                                        name="year"
                                        onChange={(e) => {
                                            formik.setFieldValue(
                                                'year',
                                                e.target.value,
                                            );
                                            formik.submitForm();
                                        }}
                                        value={formik.values.year}
                                    >
                                        {createadYearList(
                                            moment(new Date()).format('YYYY'),
                                            2000,
                                        ).map((item) => (
                                            <MenuItem
                                                key={item.id}
                                                value={item.id}
                                            >
                                                {item.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </div>

                            <div className="wrapSelectField">
                                <FormControl fullWidth>
                                    <Select
                                        // hiddenLabel
                                        size="small"
                                        placeholder="すべて"
                                        name="month"
                                        onChange={(e) => {
                                            formik.setFieldValue(
                                                'month',
                                                e.target.value,
                                            );
                                            formik.submitForm();
                                        }}
                                        value={formik.values.month}
                                    >
                                        {MONTHS_IN_YEAR.map((item) => (
                                            <MenuItem
                                                key={item.id}
                                                value={item.id}
                                            >
                                                {item.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </div>
                        </div>

                        <div className="boxRight">
                            <div className="wrapSelectField">
                                <FormControl fullWidth>
                                    <Select
                                        // hiddenLabel
                                        size="small"
                                        placeholder="すべて"
                                        name="time_range"
                                        onChange={(e) => {
                                            if (e.target.value === 'monthly') {
                                                const currentFormValues = {
                                                    ...formik.values,
                                                };
                                                delete currentFormValues.week_number;

                                                formik.setValues(
                                                    currentFormValues,
                                                );
                                            } else {
                                                formik.setFieldValue(
                                                    'week_number',
                                                    1,
                                                );
                                            }

                                            setTimeout(() => {
                                                formik.setFieldValue(
                                                    'time_range',
                                                    e.target.value,
                                                );
                                                formik.submitForm();
                                            }, 300);
                                        }}
                                        value={formik.values.time_range}
                                    >
                                        {[
                                            {
                                                id: 'weekly',
                                                name: '週稼働 ',
                                            },
                                            {
                                                id: 'monthly',
                                                name: '月稼働',
                                            },
                                        ].map((item) => (
                                            <MenuItem
                                                key={item.id}
                                                value={item.id}
                                            >
                                                {item.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </div>
                        </div>
                    </div>
                </form>

                {time_range === 'weekly' && (
                    <div className="chartTab">
                        {WEEKS_IN_MONTH.map((item) => (
                            <button
                                key={item.id}
                                type="button"
                                onClick={() => {
                                    formik.setFieldValue(
                                        'week_number',
                                        item.id,
                                    );
                                    formik.submitForm();
                                }}
                                className={classNames(
                                    week_number === item.id ? 'active' : '',
                                )}
                            >
                                {item.name}
                            </button>
                        ))}
                    </div>
                )}
                {workingTimeData && size(workingTimeData) > 0 && (
                    <div className="chartBox">
                        <Bar
                            height={
                                size(workingTimeData) > 10
                                    ? size(workingTimeData) * heightChart
                                    : 200
                            }
                            options={options}
                            data={{
                                labels: labelsChart,
                                datasets: [
                                    {
                                        data: dataChart,
                                        base: 0,
                                        barThickness: 10,
                                        backgroundColor: () =>
                                            getColorForchart(
                                                time_range,
                                                dataChart.map(
                                                    (workerTime: any) =>
                                                        getDataChartForMonthOrWeek(
                                                            time_range,
                                                            workerTime,
                                                            formik.values.month,
                                                        ),
                                                ),
                                                formik.values.month,
                                            ),
                                    },
                                ],
                            }}
                        />
                    </div>
                )}
            </ChartBoxStyle>
        </>
    );
};

export default ChartBox;
