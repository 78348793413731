import { Table, TableProps } from 'antd';
import { STATUS_INVOICE } from 'utils/constants';
import styles from './styles.module.scss';
import { formatMoney } from 'utils/helper/helpers';
import clsx from 'clsx';
import { LeftOutlinedCustom } from 'pages/invoices';
import { useEffect, useState } from 'react';
import { PlusOutlined, RightOutlined } from '@ant-design/icons';
import ModalCUDInvoice from './ModalCUDInvoice';
import { ACTION } from 'utils/enums/action';
import { StyledModalInvoice } from './ModalCUDInvoice/styled';
import IconRemove from 'assets/icons/ic-bg-remove.png';
import invoiceService from 'services/invoiceService';
import { alertError, alertSuccessPopup } from 'utils/helper/appHelper';
import dayjs from 'dayjs';
import { FORMAT } from 'utils/constants/format-date-time';
import InvoicePdf from '../budgetAction/invoicePdf';
import UploadWhite from 'assets/icons/upload-white.svg';
import UploadGrey from 'assets/icons/upload-grey.svg';
import { useNavigate } from 'react-router-dom';

interface InvoicesProps {
    project_id: number | any;
    project: any;
    getDetailProject?: any;
}

interface IParams {
    page?: number;
    per_page?: number;
    total_page?: number;
    project_id?: number;
}

interface DataType {
    id?: number | undefined;
    number?: number;
    invoice_name?: string;
    created_date?: string;
    total_amount?: string;
    status?: number;
}

const Invoices: React.FC<InvoicesProps> = ({
    project_id,
    getDetailProject,
    project,
}) => {
    const [dataListInvoice, setDataListInvoice] = useState<DataType[]>();

    const [isLoading, setIsLoading] = useState(false);

    const [currentRecord, setCurrentRecord] = useState({ id: undefined });
    const [params, setParams] = useState<IParams>({
        page: 1,
        total_page: 1,
        per_page: 10,
        project_id: project_id,
    });
    const navigate = useNavigate();
    const [statusPrev, setStatusPrev] = useState(false);
    const [statusNext, setStatusNext] = useState(false);

    const [typeAction, setTypeAction] = useState<string>('');
    const [isOpenInvoice, setIsOpenInvoice] = useState(false);

    const [isOpenDeleteInvoice, setIsOpenDeleteInvoice] = useState(false);

    const handleCloseDeleteInvoice = () => {
        setIsOpenDeleteInvoice(false);
    };

    const handleDeleteInvoice = async (invoice_id?: number) => {
        try {
            const data = await invoiceService.deleteInvoice(invoice_id);
            if (data) {
                alertSuccessPopup({
                    title: '請求書が削除されました。',
                    confirmButtonText: '閉じる',
                });
                handleCloseDeleteInvoice();
                getListInvoice();
            }
        } catch (error) {
            const errorMessage = error?.response?.data?.error || '';
            alertError(errorMessage);
        }
    };

    const showOpenDeleteInvoice = () => {
        setIsOpenDeleteInvoice(true);
    };

    const setPage = (newPage?: any) => {
        setParams((prev: IParams) => ({
            ...prev,
            page: newPage,
        }));
        setStatusPrev(newPage < (params?.page ?? 1));
        setStatusNext(newPage > (params?.page ?? 1));
    };

    const setPagePrev = () => {
        if ((params?.page ?? 1) > 1) {
            setPage((params?.page ?? 1) - 1);
        }
    };

    const setPageNext = () => {
        if ((params?.page ?? 1) < (params?.total_page ?? 1)) {
            setPage((params?.page ?? 1) + 1);
        }
    };

    const showOpenInvoice = () => {
        setIsOpenInvoice(true);
    };

    const getListInvoice = async () => {
        const paramsFormat = params;
        delete paramsFormat?.total_page;
        try {
            const response = await invoiceService.getListInvoice(paramsFormat);
            if (response !== null && response !== undefined) {
                const response_data = response?.data;
                const dataInvoice =
                    response_data?.bills?.map((item: any, index: number) => {
                        const invoice_name =
                            item?.bill_items?.length > 0
                                ? item?.bill_items
                                      ?.map((obj: any) => obj.name)
                                      .join(',')
                                : '';

                        const status = STATUS_INVOICE?.find(
                            (element) => element?.value === item?.status,
                        );

                        return {
                            ...item,
                            number: index + 1,
                            invoice_name: invoice_name,
                            created_date: item?.created_at
                                ? dayjs(item?.created_at).format(
                                      FORMAT.FORMAT_DATE_UPPER,
                                  )
                                : '',
                            total_amount: item?.total_price
                                ? Math.round(item?.total_price)
                                : '',
                        };
                    }) ?? [];
                const total_count = response?.data?.meta?.total_count;
                const total_integer = Math.floor(total_count / 10);
                const total_surplus = total_count % 10;
                const total_pageCustom =
                    total_surplus >= 1 ? total_integer + 1 : total_integer;
                setParams((prev: IParams) => {
                    return {
                        ...prev,
                        total_page: total_pageCustom,
                    };
                });
                setDataListInvoice(dataInvoice);
            }
        } catch (err) {
            alertError(err);
        }
    };

    useEffect(() => {
        !isOpenInvoice && getListInvoice();
    }, [isOpenInvoice, params?.page]);

    const columns: TableProps<DataType>['columns'] = [
        {
            title: '番号',
            dataIndex: 'number',
            key: 'number',
            align: 'center',
            width: '5%',
            render: (text, record) => (
                <>
                    <div className='w-[20px]'>{record?.number}</div>
                </>
            ),
        },
        {
            title: '請求書名',
            dataIndex: 'invoice_name',
            key: 'invoice_name',
            align: 'center',
            render: (text: any, record: any) => (
                <>
                    <div className="max-w-[400px]">
                        {record?.invoice_name ?? ''}
                    </div>
                </>
            ),
            width: '30%',
        },
        {
            title: '作成日',
            dataIndex: 'created_date',
            key: 'created_date',
            align: 'center',
            render: (text: any, record?: any) => (
                <>
                    <div>{record?.created_date ?? ''}</div>
                </>
            ),
            width: '15%',
        },
        {
            title: '合計金額',
            dataIndex: 'total_amount',
            key: 'total_amount',
            align: 'center',
            render: (text: any, record?: any) => (
                <>
                    <div>
                        {record?.total_amount
                            ? '¥' +
                              formatMoney(String(record?.total_amount || 0))
                            : ''}
                    </div>
                </>
            ),
            width: '15%',
        },
        {
            title: 'ステータス',
            key: 'status',
            dataIndex: 'status',
            align: 'center',
            render: (text: any, record: any) => {
                const status = STATUS_INVOICE?.find(
                    (item) => item.value === record?.status,
                );
                return (
                    <>
                        {status ? (
                            <div className="w-full flex justify-center">
                                <div
                                    className="w-[70%] flex items-center justify-center rounded-2xl h-7"
                                    style={{
                                        color: status?.color,
                                        backgroundColor: status?.bg,
                                    }}
                                >
                                    {status?.label}
                                </div>
                            </div>
                        ) : (
                            ''
                        )}
                    </>
                );
            },
            width: '25%',
        },
        {
            title: '操作',
            key: 'action',
            render: (_, record: any) => (
                <div className="flex gap-2">
                    <button
                        className={clsx(
                            'button-tb !p-0 !rounded-[3px] !text-sm',
                        )}
                        style={{ width: '48px' }}
                        onClick={() => {
                            setTypeAction(ACTION.DETAIL);
                            showOpenInvoice();
                            setCurrentRecord(record);
                            setIsLoading(true);
                        }}
                    >
                        詳細
                    </button>
                    <button
                        className={clsx(
                            'button-tb !p-0 !rounded-[3px] !text-sm',
                            {
                                'cursor-no-drop': isDisabledField,
                            },
                        )}
                        style={{ width: '48px' }}
                        onClick={() => {
                            setTypeAction(ACTION.EDIT);
                            showOpenInvoice();
                            setCurrentRecord(record);
                            setIsLoading(true);
                        }}
                    >
                        編集
                    </button>
                    <button
                        className={clsx(
                            'button-tb !p-0 !rounded-[3px] !text-sm',
                            {
                                'cursor-no-drop': isDisabledField,
                            },
                        )}
                        style={{
                            width: '48px',
                            background: '#FF5045',
                        }}
                        onClick={() => {
                            setCurrentRecord(record);
                            showOpenDeleteInvoice();
                        }}
                        disabled={isDisabledField}
                    >
                        削除
                    </button>
                </div>
            ),
            width: '10%',
            align: 'center',
        },
    ];

    const isDisabledField =
        project?.bills?.length > 0
            ? project?.bills?.every((item: any) => item.status === 'paid')
            : false || project?.implement === null;

    const isDisabledCreate =
        project?.implement?.status?.includes('finish_implement');

    return (
        <>
            <div className={styles.tableCustom}>
                <div className="flex justify-end mb-3">
                    <button
                        className={clsx('create-user-button d-flex flex-row', {
                            'cursor-no-drop':
                                !isDisabledCreate || isDisabledField,
                        })}
                        onClick={() => {
                            setTypeAction(ACTION.CREATE);
                            showOpenInvoice();
                        }}
                        disabled={!isDisabledCreate || isDisabledField}
                    >
                        <PlusOutlined />
                        新規作成
                    </button>
                </div>
                <Table
                    columns={columns}
                    dataSource={dataListInvoice}
                    pagination={false}
                    locale={{ emptyText: 'データがありません。' }}
                    loading={isLoading}
                />
                <InvoicePdf invoice={project} />
                {dataListInvoice && dataListInvoice.length > 0 && (
                    <>
                        <div className="flex items-baseline gap-[50px] justify-end mt-[20px] pr-[18px] !shadow-none">
                            <p className="font-normal text-[14px] text-[#666666]">
                                ページ {params?.page} / {params?.total_page}
                            </p>
                            <div className="flex gap-[25px]">
                                <LeftOutlinedCustom
                                    style={{
                                        color: '#666666',
                                        fontSize: 12,
                                    }}
                                    className={clsx(
                                        'cursor-pointer !font-semibold',
                                        {
                                            '!text-[black]':
                                                statusPrev &&
                                                params?.page !== 1,
                                            '!text-[#D3D3D3]':
                                                params?.page === 1,
                                        },
                                    )}
                                    onClick={setPagePrev}
                                    disabled
                                />

                                <RightOutlined
                                    style={{
                                        color: '#666666',
                                        fontSize: 12,
                                    }}
                                    className={clsx(
                                        'cursor-pointer !font-semibold',
                                        {
                                            '!text-[black] inline-flex':
                                                statusNext &&
                                                params?.page !==
                                                    params?.total_page,
                                            '!text-[#D3D3D3]':
                                                params?.page ===
                                                params?.total_page,
                                        },
                                    )}
                                    onClick={setPageNext}
                                />
                            </div>
                        </div>
                    </>
                )}
            </div>

            <div className="flex flex-row">
                <button
                    className="modal-create-user-button2 !rounded-[2px] d-flex flex-row mx-[15px] w-[140px]"
                    style={{
                        width: '140px',
                        borderRadius: '2px',
                        background: `${
                            !project?.status ? '#F5F5F5' : '#215493'
                        }`,
                        border: '1px solid #D9D9D9',

                        color: `${!project?.status ? '#8C8C8C' : '#fff'}`,
                    }}
                    disabled={!project?.status}
                    type="button"
                    onClick={() =>
                        navigate(
                            `/projects/detail/${project?.id}/plan?viewInvoicePdf=true`,
                        )
                    }
                >
                    <img
                        src={!project?.status ? UploadGrey : UploadWhite}
                        alt=""
                    />
                    PDF見積書
                </button>
            </div>
            <ModalCUDInvoice
                isDisabledField={isDisabledField}
                isOpenInvoice={isOpenInvoice}
                setIsOpenInvoice={setIsOpenInvoice}
                setTypeAction={setTypeAction}
                typeAction={typeAction}
                project_id={project_id}
                getListInvoice={getListInvoice}
                invoice_id={currentRecord?.id}
                setIsLoading={setIsLoading}
                isLoading={isLoading}
                setCurrentRecord={setCurrentRecord}
                getDetailProject={getDetailProject}
            />
            <StyledModalInvoice
                className="!p-0"
                open={isOpenDeleteInvoice}
                footer={null}
                closable={false}
                closeIcon={false}
                onCancel={handleCloseDeleteInvoice}
                centered
                width={462}
            >
                <div
                    className="modal-create-user !w-full d-flex flex-column"
                    style={{ height: '243px' }}
                >
                    <img src={IconRemove} />
                    <div className="modal-create-user-title d-flex flex-row mt-4 font-semibold text-2xl">
                        本当に削除しますか?
                    </div>
                    <div
                        className="modal-create-user-buttons d-flex flex-row"
                        style={{ marginTop: '50px' }}
                    >
                        <button
                            className="modal-create-user-button1 d-flex flex-row !border-[#e4e4e4] !rounded-[3px] !h-9"
                            onClick={handleCloseDeleteInvoice}
                            type="button"
                        >
                            いいえ
                        </button>
                        <button
                            className="modal-create-user-button2 d-flex flex-row !bg-[#d83535] !rounded-[3px] !h-9"
                            type="button"
                            onClick={() => {
                                handleDeleteInvoice(currentRecord?.id);
                            }}
                        >
                            はい
                        </button>
                    </div>
                </div>
            </StyledModalInvoice>
        </>
    );
};
export default Invoices;
