import { Box, InputBase, MenuItem, Paper } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Controller, useForm } from 'react-hook-form';
import { typeSelect } from '..';
import { message, Select, Upload, UploadProps } from 'antd';
import cloudUploadIcon from 'assets/icons/cloudUpload.svg';
import { RcFile } from 'antd/lib/upload';
import { useState } from 'react';
import pdfImg from '../../../assets/icons/img_pdf.jpg';
import { StyledModalUpload } from './styled';
import documentsService from 'services/documentService';
import { alertError, alertSuccessPopup } from 'utils/helper/appHelper';

const { Dragger } = Upload;

const ModalUploadDocument = (props) => {
    const { isOpenUpload, setIsOpenUpload } = props;

    const [listType, setListType]: any = useState([]);

    const [isValidate, setIsValidate] = useState(false);

    const [filePdfList, setFilePdfList]: any = useState([]);

    const handleClose = () => {
        setIsOpenUpload(false);
        reset();
        setFilePdfList([]);
        setIsValidate(false);
    };

    const {
        handleSubmit,
        setValue,
        control,
        clearErrors,
        reset,
        formState: { errors },
    } = useForm({
        defaultValues: {
            company_name: '',
            type: '',
            file: undefined,
        },
    });

    const dragProps: UploadProps = {
        name: 'file',
        beforeUpload(file: RcFile) {
            if (file.type === 'application/pdf') {
                listType.push('pdf');
            }
            const isLE10MB = file.size / 1024 / 1024 <= 10;
            if (!isLE10MB) {
                message.error('ファイルは10MBを超えてはいけません。');
            }

            if (isLE10MB) {
                setFilePdfList((filePdfList) => {
                    return [...filePdfList, file];
                });
            }
            return isLE10MB || Upload.LIST_IGNORE;
        },
        onChange(info: any) {
            const filteredPdfAfterRemove = filePdfList.filter((bItem) =>
                info?.fileList?.some((aItem) => aItem.uid === bItem.uid),
            );
            setFilePdfList(filteredPdfAfterRemove);
            setValue('file', info.file.originFileObj);
        },
        onDrop(e) {
            //
        },
        customRequest({ file, onSuccess }) {
            setTimeout(() => {
                if (onSuccess) {
                    onSuccess('ok');
                }
            }, 0);
        },
    };

    const handleRemoveFile = (file: { uid?: string }) => {
        setFilePdfList((filePdfList: { uid?: string }[]) => {
            return filePdfList.filter((item) => item.uid !== file.uid);
        });
    };

    const onSubmit = async (data) => {
        const dataFormat = {
            name: data?.company_name?.trim() ?? '',
            data_type: data?.type ?? '',
            file: filePdfList,
        };
        let formData = new FormData();
        formData.append('name', dataFormat.name);
        formData.append('data_type', dataFormat.data_type);
        for (let i = 0; i < dataFormat?.file?.length; i++) {
            formData.append('files[]', dataFormat?.file[i]);
        }
        if (filePdfList?.length > 0) {
            try {
                const data = await documentsService.createDocument(formData);
                if (data) {
                    alertSuccessPopup({
                        title: '資料登録が完了しました。',
                        confirmButtonText: '閉じる',
                    });
                    handleClose();
                }
            } catch (err) {
                const response = err?.response;
                alertError(response.data?.error);
            }
        }
    };

    return (
        <>
            <StyledModalUpload
                className="!p-0"
                open={isOpenUpload}
                footer={null}
                closable={false}
                closeIcon={false}
                onCancel={handleClose}
                centered
                width={562}
            >
                <Box
                    sx={{
                        background: '#215493',
                        margin: 0,
                        color: 'white',
                        borderTopLeftRadius: '12px',
                        borderTopRightRadius: '12px',
                        p: 2,
                        fontSize: '16px',
                    }}
                    id="modal-modal-title"
                    component="h2"
                >
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                        }}
                    >
                        <span>アップロード</span>
                        <CloseIcon
                            sx={{ cursor: 'pointer' }}
                            onClick={handleClose}
                        />
                    </div>
                </Box>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Box
                        id="modal-modal-description"
                        sx={{
                            my: 2,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            p: '15px',
                            width: '100%',
                        }}
                    >
                        <div className="w-full">
                            <Paper
                                sx={{
                                    mb: 1,
                                    fontSize: '14px',
                                    fontWeight: 400,
                                }}
                            >
                                <span style={{ color: 'red' }}>*</span>
                                <span>&nbsp;</span>
                                顧客
                            </Paper>
                            <Paper
                                component="form"
                                sx={{
                                    p: '2px 4px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    border: '1px solid rgba(0, 0, 0, 0.15)',
                                    borderRadius: '2px',
                                }}
                            >
                                <Controller
                                    control={control}
                                    rules={{
                                        required: `顧客は必須です。`,
                                    }}
                                    render={({
                                        field: { onChange, onBlur, value },
                                    }) => (
                                        <InputBase
                                            sx={{
                                                ml: 1,
                                                flex: 1,
                                            }}
                                            onBlur={onBlur}
                                            onChange={onChange}
                                            value={value}
                                        />
                                    )}
                                    name="company_name"
                                />
                            </Paper>
                            {errors.company_name && (
                                <span
                                    style={{
                                        textAlign: 'left',
                                        width: '100%',
                                        color: 'red',
                                        paddingTop: '3px',
                                    }}
                                >
                                    {errors?.company_name?.message}
                                </span>
                            )}
                        </div>
                        <div className="w-full mt-4">
                            <Paper
                                sx={{
                                    mb: 1,
                                    fontSize: '14px',
                                    fontWeight: 400,
                                }}
                            >
                                タイプ
                            </Paper>
                            <Paper component="form">
                                <Controller
                                    control={control}
                                    render={({
                                        field: { onChange, onBlur, value },
                                    }) => (
                                        <Select
                                            className="w-full"
                                            onBlur={onBlur}
                                            onChange={onChange}
                                            value={value}
                                        >
                                            {typeSelect?.map(
                                                (element, index) => (
                                                    <MenuItem
                                                        key={index}
                                                        value={element.value}
                                                    >
                                                        {element.label}
                                                    </MenuItem>
                                                ),
                                            )}
                                        </Select>
                                    )}
                                    name="type"
                                />
                            </Paper>
                        </div>
                        <div className="w-full mt-4">
                            <Paper
                                sx={{
                                    mb: 1,
                                    fontSize: '14px',
                                    fontWeight: 400,
                                }}
                            >
                                <span style={{ color: 'red' }}>*</span>
                                <span>&nbsp;</span>
                                ファイル選択
                            </Paper>

                            <Dragger
                                {...dragProps}
                                multiple={true}
                                // fileList={filesListPdf}
                                accept=".pdf"
                                showUploadList={false}
                                className="w-full"
                                name="file_list"
                            >
                                <div className="flex p-2 items-center justify-between">
                                    <div className="flex items-center">
                                        <div className="mr-2">
                                            <img
                                                src={cloudUploadIcon}
                                                alt="cloud upload icon"
                                            />
                                        </div>
                                        <div className="text-left">
                                            <p className="ant-upload-text text-sm">
                                                ファイルを選択してください
                                            </p>
                                            <p className="ant-upload-hint text-xs">
                                                10MB以下のPDF、を対応します。
                                            </p>
                                        </div>
                                    </div>
                                    <div className="text-[#215493] !text-sm border-solid border-[1px] border-[#215493] rounded flex justify-center items-center w-[60px] h-[46px]">
                                        選択
                                    </div>
                                </div>
                            </Dragger>

                            {filePdfList?.length === 0 && isValidate && (
                                <p className="text-[red]">
                                    ファイルは必須です。
                                </p>
                            )}
                        </div>
                        {filePdfList?.length > 0 && (
                            <div className="w-full mt-4">
                                <div className="flex gap-2 flex-wrap">
                                    {filePdfList.map(
                                        (file: any, index: number) => {
                                            const size = file.size
                                                ? Math.round(file.size / 1024)
                                                : 0;
                                            return (
                                                <div
                                                    key={index}
                                                    className="flex items-center gap-[6px] py-2 px-3 bg-[#f8f8f8] w-fit rounded-lg"
                                                >
                                                    <div>
                                                        <img src={pdfImg} />
                                                    </div>
                                                    <div>
                                                        <div className="text-[10px] font-normal line-clamp-1 max-w-[70px]">
                                                            {file?.name?.replace(
                                                                '.pdf',
                                                                '',
                                                            )}
                                                            . PDF
                                                        </div>
                                                        <div className="text-[8px] text-[#0000008C] line-clamp-1 max-w-[70px] font-normal">
                                                            {size}KB
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <button
                                                            className="text-[10px] text-white w-9 rounded-[3px] h-[19px] bg-[#ff5045]"
                                                            onClick={() => {
                                                                handleRemoveFile(
                                                                    file,
                                                                );
                                                            }}
                                                            type="button"
                                                        >
                                                            削除
                                                        </button>
                                                    </div>
                                                </div>
                                            );
                                        },
                                    )}
                                </div>
                            </div>
                        )}
                        <div className="buttonGroup">
                            <button
                                className="cancelBtn"
                                type="button"
                                onClick={handleClose}
                            >
                                戻る
                            </button>
                            <button
                                className="registerBtn"
                                onClick={() => {
                                    setIsValidate(true);
                                }}
                                type="submit"
                            >
                                登録
                            </button>
                        </div>
                    </Box>
                </form>
            </StyledModalUpload>
        </>
    );
};
export default ModalUploadDocument;
