import { StylesWorkingTimes } from './styled';
import useWorkingTimesChart, { Props } from './hook';
import { Button } from '@mui/material';
import FilterBox from './FilterBox';
import ChartBox from './ChartBox';
import Loading from 'components/Loading';
const WorkingTimesChartLayout = ({
    formik,
    workingTimeData,
    positionOptions,
    workerOptions,
    departmentOptions,
    loading,
    isOpenDepartment,
    setIsOpenDepartment,
    isOpenPosition,
    setIsOpenPosition,
    isOpenWorker,
    setIsOpenWorker,
}: Props) => {    
    return (
        <StylesWorkingTimes>
            <Loading loading={loading} />
            <div className="headerBox">
                <h1 className="working_times--title mb-0">勤務時間</h1>
                <span className="importBtn">
                    <input
                        type="file"
                        accept="application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    // onChange={(e) => {
                    //     handleImportFile(e.target.files[0]);
                    // }}
                    />
                    インポート
                </span>
            </div>
            <div >
                <FilterBox
                    formik={formik}
                    positionOptions={positionOptions}
                    workerOptions={workerOptions}
                    departmentOptions={departmentOptions}
                    isOpenDepartment={isOpenDepartment}
                    setIsOpenDepartment={setIsOpenDepartment}
                    isOpenWorker={isOpenWorker}
                    setIsOpenWorker={setIsOpenWorker}
                    isOpenPosition={isOpenPosition}
                    setIsOpenPosition={setIsOpenPosition}

                />
                {!loading && (
                    <ChartBox
                        formik={formik}
                        workingTimeData={workingTimeData}
                    // workerData={workerData}
                    />
                )}
            </div>
        </StylesWorkingTimes>
    );
};

const WorkingTimeChart = (props: any) => {
    return <WorkingTimesChartLayout {...useWorkingTimesChart(props)} />;
};

export default WorkingTimeChart;
