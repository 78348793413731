import { styled } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import invoiceApi from 'services/invoiceApi';
import defaultAvatar from 'assets/avatar-default.png';
import { Box, TextField } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { clsx } from 'clsx';
import { useModal } from 'react-hooks-use-modal';
import { CloseOutlined } from '@mui/icons-material';
import ExclamationIcon from 'assets/icons/exclamation.svg';
import { DatePicker } from 'antd';
import { alertError, alertSuccess } from 'utils/helper/appHelper';
import InvoiceController from './InvoiceCotroller';
import TableInvoices, { billItem, DataTypeProject } from './TableInvoices';
import billOfProject from 'services/billOfProjectService';
import React from 'react';

const { RangePicker } = DatePicker;

export const LeftOutlinedCustom = styled(LeftOutlined)``;

interface IInvoiceData {
    id: number;
    company_address: string;
    company_bank_account: string;
    company_name: string;
    created_at: string;
    customer_address: string;
    customer_name: string;
    invoice_date: string;
    invoice_details: unknown[];
    invoice_number: string;
    line_user_avatar?: string | null;
    line_user_name: string;
    payment_date: string;
    payment_type?: string | null;
    quote_id: number;
    status: string;
    total_after_tax?: number;
    total_before_tax?: number;
    total_tax?: number;
    type_of_quote: string;
    updated_at?: string;
    invoice_deposits?;
    total_deposit?;
}

interface ISearchValues {
    filter_value: string;
    invoice_start_date: string;
    invoice_end_date: string;
    payment_start_date: string;
    payment_end_date: string;
}

const initialSearchValues: ISearchValues = {
    filter_value: '',
    invoice_start_date: '',
    invoice_end_date: '',
    payment_start_date: '',
    payment_end_date: '',
};

const Invoices: React.FC = () => {
    const [billOfProjects, setBillOfProjects] = useState<DataTypeProject[]>([]);
    let storage = localStorage.getItem('user');
    const user = storage !== null ? JSON.parse(storage) : null;
    const [page, setPage] = useState(1);
    const [totalPage, setTotalPage] = useState(0);
    const [statusPrev, setStatusPrev] = useState(false);
    const [statusNext, setStatusNext] = useState(false);
    const [selectedInvoice, setSelectedInvoice] = useState<IInvoiceData | null>(
        null,
    );
    const location = useLocation();
    const PER_PAGE = 10;
    const [ModalDelete, openDelete, closeDelete, isOpenDelete] = useModal(
        'root',
        {
            preventScroll: true,
            //closeOnOverlayClick: false,
        },
    );
    const [searchValues, setSearchValues] =
        useState<ISearchValues>(initialSearchValues);
    const [submitValues, setSubmitValues] =
        useState<ISearchValues>(initialSearchValues);

    const setPagePrev = () => {
        if (page > 1) {
            setPage(page - 1);
            setStatusNext(false);
            setStatusPrev(true);
        }
    };
    const setPageNext = () => {
        if (page < totalPage) {
            setPage(page + 1);
            setStatusNext(true);
            setStatusPrev(false);
        }
    };

    const fetchBillOfProjects = async () => {
        try {
            const response = await billOfProject.getBillOfProject(
                user?.access_token,
                {
                    page: page,
                    per_page: 10,
                    free_text: submitValues.filter_value,
                    bill_start_date: submitValues.invoice_start_date,
                    bill_end_date: submitValues.invoice_end_date,
                    paid_start_date: submitValues.payment_start_date,
                    paid_end_date: submitValues.payment_end_date,
                },
            );
            const { data } = response;
            if (data) {
                const totalCount = data?.meta?.total_count;
                const totalInteger = Math.floor(totalCount / PER_PAGE);
                const totalSurplus = totalCount % PER_PAGE;
                const totalPageCustom =
                    totalSurplus >= 1 ? totalInteger + 1 : totalInteger;
                const dataProjects =
                    data?.projects?.length > 0
                        ? data.projects?.map(
                              (item: DataTypeProject, index: number) => {
                                  const billsCustomize = item?.bills?.map(
                                      (bill: billItem, index: number) => {
                                          return {
                                              ...bill,
                                              project_id: item?.id,
                                              invoice_id: bill?.id,
                                              number: index + 1,
                                              invoice_name: bill?.name ?? '',
                                              total_amount:
                                                  bill?.total_price ?? '',
                                              status_of_invoice:
                                                  bill?.status ?? '',
                                          };
                                      },
                                  );
                                  return {
                                      ...item,
                                      status_of_project: item?.status,
                                      project_id: item?.id,
                                      key: item?.id,
                                      project_name: item?.property_name,
                                      bills: billsCustomize,
                                  };
                              },
                          )
                        : [];
                setTotalPage(totalPageCustom);
                setBillOfProjects(dataProjects);
            }
        } catch (error) {}
    };

    useEffect(() => {
        if (!location.search) {
            fetchBillOfProjects();
        }
    }, [page, submitValues, location.search]);

    const handleSearchInvoices = () => {
        setPage(1);
        setSubmitValues((prev) => ({
            ...prev,
            filter_value: searchValues.filter_value?.trim(),
            invoice_start_date: searchValues.invoice_start_date,
            invoice_end_date: searchValues.invoice_end_date,
            payment_start_date: searchValues.payment_start_date,
            payment_end_date: searchValues.payment_end_date,
        }));
    };

    const handleDeleteInvoice = async () => {
        if (!selectedInvoice) {
            return;
        }
        try {
            const response = await invoiceApi.deleteInvoice(
                user,
                selectedInvoice.id,
            );
            const { data } = response;
            if (data && data.status === 'success') {
                fetchBillOfProjects();
                alertSuccess(
                    `${selectedInvoice.invoice_number}を削除しました。`,
                );
            }
        } catch (error) {
            alertError('');
        } finally {
            closeDelete();
            setSelectedInvoice(null);
        }
    };

    return (
        <>
            <div className="container-worker d-flex flex-row">
                <div className="worker-content d-flex flex-column">
                    <div className="filterSection flex items-end mb-3">
                        <Box
                            sx={{
                                width: '100%',
                                maxWidth: '250px',
                                display: 'flex',
                                '.input-search': {
                                    border: '1px solid #cdd1d5',
                                    borderTopLeftRadius: '2px',
                                    borderBottomLeftRadius: '2px',
                                    marginBottom: '14.5px',
                                    paddingLeft: '14px',
                                },
                                '.MuiTextField-root': {
                                    border: '1px solid #cdd1d5',
                                    width: '100%',
                                    borderTopLeftRadius: '2px',
                                    borderBottomLeftRadius: '2px',
                                    paddingLeft: '14px',
                                    // marginRight: '12.5px',
                                },
                                '.MuiInputBase-input': {
                                    height: '30px',
                                    padding: '0px !important',
                                },
                                '.MuiButtonBase-root': {
                                    minWidth: '0px',
                                },
                            }}
                        >
                            <TextField
                                autoComplete="off"
                                placeholder="検索"
                                type="text"
                                value={searchValues.filter_value}
                                onChange={(evt) =>
                                    setSearchValues((prev) => ({
                                        ...prev,
                                        filter_value: evt.target.value,
                                    }))
                                }
                            />
                        </Box>

                        <Box sx={{ marginLeft: '10px' }}>
                            <div className="mb-1">日付</div>
                            <RangePicker
                                placeholder={['開始日', '終了日']}
                                onChange={(dates, dateStrings) => {
                                    const [start, end] = dateStrings;
                                    setSearchValues((prev) => ({
                                        ...prev,
                                        invoice_start_date: start,
                                        invoice_end_date: end,
                                    }));
                                }}
                            />
                        </Box>

                        <Box sx={{ marginLeft: '10px' }}>
                            <div className="mb-1">振込期限</div>
                            <RangePicker
                                placeholder={['開始日', '終了日']}
                                onChange={(dates, dateStrings) => {
                                    const [start, end] = dateStrings;
                                    setSearchValues((prev) => ({
                                        ...prev,
                                        payment_start_date: start,
                                        payment_end_date: end,
                                    }));
                                }}
                            />
                        </Box>

                        <button
                            onClick={handleSearchInvoices}
                            className="modal-create-user-button2 !w-[70px] !h-[32px] !rounded-[3px] ml-3"
                        >
                            検索
                        </button>
                    </div>

                    <TableInvoices
                        fetchBillOfProjects={fetchBillOfProjects}
                        billOfProjects={billOfProjects}
                    />
                    {billOfProjects?.length > 0 && (
                        <div className="flex items-baseline gap-[50px] justify-end mt-[20px] pr-[18px]">
                            <p className="font-normal text-[14px] text-[#666666]">
                                ページ {page} / {totalPage}
                            </p>
                            <div className="flex gap-[25px]">
                                <LeftOutlinedCustom
                                    style={{ color: '#666666', fontSize: 12 }}
                                    className={clsx(
                                        'cursor-pointer !font-semibold',
                                        {
                                            '!text-[black]':
                                                statusPrev && page !== 1,
                                            '!text-[#D3D3D3]': page === 1,
                                        },
                                    )}
                                    onClick={setPagePrev}
                                    disabled
                                />

                                <RightOutlined
                                    style={{ color: '#666666', fontSize: 12 }}
                                    className={clsx(
                                        'cursor-pointer !font-semibold',
                                        {
                                            '!text-[black] inline-flex':
                                                statusNext &&
                                                page !== totalPage,
                                            '!text-[#D3D3D3]':
                                                page === totalPage,
                                        },
                                    )}
                                    onClick={setPageNext}
                                />
                            </div>
                        </div>
                    )}
                </div>
                <InvoiceController />
            </div>
            <ModalDelete>
                <div
                    className="d-flex flex-column justify-center !bg-[#ffffff] rounded-[10px] px-[20px]"
                    style={{ width: '449px', height: '285px' }}
                >
                    <div className="flex justify-end">
                        <CloseOutlined
                            onClick={() => {
                                setSelectedInvoice(null);
                                closeDelete();
                            }}
                        />
                    </div>
                    <div className="flex flex-col justify-center items-center pt-[15px] pb-[10px]">
                        <img
                            src={ExclamationIcon}
                            className="w-[80px] h-[80px]"
                            alt="exclamation icon"
                        />
                        <div className="text-[#215493] font-medium text-[16px] pt-[5px]">
                            警告
                        </div>
                        <div className="text-[#3A3B3C] font-normal text-[13px] pt-[5px]">
                            本当に削除しますか?
                        </div>
                        <div
                            className="flex gap-[10px]"
                            style={{ marginTop: '40px' }}
                        >
                            <button
                                className="modal-create-user-button1 d-flex flex-row !w-[147px] !h-[36px] !rounded-[3px]"
                                onClick={() => {
                                    setSelectedInvoice(null);
                                    closeDelete();
                                }}
                            >
                                キャンセル
                            </button>
                            <button
                                onClick={handleDeleteInvoice}
                                className="modal-create-user-button2 d-flex flex-row !w-[147px] !h-[36px] !rounded-[3px]"
                            >
                                削除
                            </button>
                        </div>
                    </div>
                </div>
            </ModalDelete>
        </>
    );
};

export default Invoices;
