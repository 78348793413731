import { useEffect, useState } from 'react';
import './progressStep.css';
import Dialog from '@mui/material/Dialog';

import workersService from 'services/wokersService';
import sugorokuService from 'services/sugorokuService';
import { SugorokuIndexEnum, WasteSugorokuIndexEnum } from 'utils/enums';
import { convertSugorokuArrayToObject } from 'utils/helper/sugorokuHelper';
import { ISugorokuList } from 'models';
import CompanyService from 'services/clientCompanyService';
import {
    convertClientCompaniesToDropdownOptions,
    removeUndefinedCompany,
} from 'utils/helper/clientCompanyHelper';
import CooperativeCompanyService from 'services/cooperativeCompaniesService';
import { convertObjectToDropdownOptions } from 'utils/helper/dropdownHelper';
import ProjectSteps from './components/ProjectSteps';
interface IProps {
    project_id?: number;
    business_type?: boolean;
    readOnly?: boolean;
    status?: String;
}

const ProgressStepCreate = (props: IProps) => {
    const { status } = props;

    const [sugorokuList, setSugorokuList] = useState<ISugorokuList | null>(
        null,
    );

    return (
        <>
            <div>
                <ProjectSteps
                    sugorokuList={sugorokuList}
                    readOnly={!!props.readOnly}
                    status={status}
                />
            </div>
        </>
    );
};

export default ProgressStepCreate;
