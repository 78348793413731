import { Table, TableProps } from 'antd';
import clsx from 'clsx';
import { useState } from 'react';
import { STATUS_PAYMENT } from 'utils/constants';
import { formatMoney } from 'utils/helper/helpers';
import styles from './styles.module.scss';
import dayjs from 'dayjs';
import { FORMAT } from 'utils/constants/format-date-time';
import { PlusOutlined, RightOutlined } from '@ant-design/icons';
import { IParams } from 'pages/document';
import { LeftOutlinedCustom } from 'pages/invoices';

interface PaymentProps {}

interface DataType {
    id?: number | undefined;
    number?: number;
    invoice_name?: string;
    deposit_date?: string;
    total_amount?: string;
    remarks?: string;
    status?: string;
}

const mockData: DataType[] = [
    {
        id: 1,
        number: 1,
        invoice_name: 'Invoice 1',
        deposit_date: '2023-01-01',
        total_amount: '1000',
        remarks: 'Remark 1',
        status: 'not_deposited',
    },
    {
        id: 2,
        number: 2,
        invoice_name: 'Invoice 2',
        deposit_date: '2023-02-01',
        total_amount: '2000',
        remarks: 'Remark 2',
        status: 'partially_deposited',
    },
    {
        id: 3,
        number: 3,
        invoice_name: 'Invoice 3',
        deposit_date: '2023-03-01',
        total_amount: '3000',
        remarks: 'Remark 3',
        status: 'deposited',
    },
];

const Payment: React.FC<PaymentProps> = () => {
    const [statusPrev, setStatusPrev] = useState(false);

    const [statusNext, setStatusNext] = useState(false);

    const [isLoading, setIsLoading] = useState(false);

    const [params, setParams] = useState<IParams>({
        page: 1,
        total_page: 1,
        per_page: 10,
    });

    const [dataListPayment, setDataListPayment] =
        useState<DataType[]>(mockData);

    const setPage = (newPage?: any) => {
        setParams((prev: IParams) => ({
            ...prev,
            page: newPage,
        }));
        setStatusPrev(newPage < (params?.page ?? 1));
        setStatusNext(newPage > (params?.page ?? 1));
    };

    const setPagePrev = () => {
        if ((params?.page ?? 1) > 1) {
            setPage((params?.page ?? 1) - 1);
        }
    };

    const setPageNext = () => {
        if ((params?.page ?? 1) < (params?.total_page ?? 1)) {
            setPage((params?.page ?? 1) + 1);
        }
    };

    const columns: TableProps<DataType>['columns'] = [
        {
            title: '番号',
            dataIndex: 'number',
            key: 'number',
            align: 'center',
            width: '5%',
            render: (text, record) => (
                <>
                    <div className="w-[20px]">{record?.number}</div>
                </>
            ),
        },
        {
            title: '請求書名',
            dataIndex: 'invoice_name',
            key: 'invoice_name',
            align: 'center',
            render: (text: any, record: any) => (
                <>
                    <div className="max-w-[400px]">
                        {record?.invoice_name ?? ''}
                    </div>
                </>
            ),
            width: '30%',
        },
        {
            title: '入金日',
            dataIndex: 'deposit_date',
            key: 'deposit_date',
            align: 'center',
            render: (text: any, record?: any) => (
                <>
                    <div>
                        {record?.deposit_date
                            ? dayjs(record?.deposit_date).format(
                                  FORMAT.DATE_FORMAT,
                              )
                            : ''}
                    </div>
                </>
            ),
            width: '15%',
        },
        {
            title: '合計金額',
            dataIndex: 'total_amount',
            key: 'total_amount',
            align: 'center',
            render: (text: any, record?: any) => (
                <>
                    <div>
                        {record?.total_amount
                            ? '¥' +
                              formatMoney(String(record?.total_amount || 0))
                            : ''}
                    </div>
                </>
            ),
            width: '15%',
        },
        {
            title: 'ステータス',
            key: 'status',
            dataIndex: 'status',
            align: 'center',
            render: (text: any, record: any) => {
                const status = STATUS_PAYMENT?.find(
                    (item) => item.value === record?.status,
                );
                return (
                    <>
                        {status ? (
                            <div className="w-full flex justify-center">
                                <div
                                    className="w-[70%] flex items-center justify-center rounded-2xl h-7"
                                    style={{
                                        color: status?.color,
                                        backgroundColor: status?.bg,
                                    }}
                                >
                                    {status?.label}
                                </div>
                            </div>
                        ) : (
                            ''
                        )}
                    </>
                );
            },
            width: '15%',
        },
        {
            title: '備考',
            dataIndex: 'remarks',
            key: 'remarks',
            align: 'center',
            render: (text: any, record?: any) => (
                <>
                    <div>{record?.remarks ? record?.remarks : ''}</div>
                </>
            ),
            width: '15%',
        },
        {
            title: '操作',
            key: 'action',
            render: (_, record: any) => (
                <div className="flex gap-2">
                    <button
                        className={clsx(
                            'button-tb !p-0 !rounded-[3px] !text-sm',
                        )}
                        style={{ width: '48px' }}
                        onClick={() => {
                            // setTypeAction(ACTION.DETAIL);
                            // showOpenInvoice();
                            // setCurrentRecord(record);
                            // setIsLoading(true);
                        }}
                    >
                        詳細
                    </button>
                    <button
                        className={clsx(
                            'button-tb !p-0 !rounded-[3px] !text-sm',
                            {
                                // 'cursor-no-drop': isDisabledField,
                            },
                        )}
                        style={{ width: '48px' }}
                        onClick={() => {
                            // setTypeAction(ACTION.EDIT);
                            // showOpenInvoice();
                            // setCurrentRecord(record);
                            // setIsLoading(true);
                        }}
                    >
                        編集
                    </button>
                    <button
                        className={clsx(
                            'button-tb !p-0 !rounded-[3px] !text-sm',
                            {
                                // 'cursor-no-drop': isDisabledField,
                            },
                        )}
                        style={{
                            width: '48px',
                            background: '#FF5045',
                        }}
                        onClick={() => {
                            // setCurrentRecord(record);
                            // showOpenDeleteInvoice();
                        }}
                        // disabled={isDisabledField}
                    >
                        削除
                    </button>
                </div>
            ),
            width: '10%',
            align: 'center',
        },
    ];
    return (
        <>
            <div className={styles.tableCustom}>
                <div className="flex justify-end mb-3">
                    <button
                        className={clsx('create-user-button d-flex flex-row')}
                        disabled
                    >
                        <PlusOutlined />
                        新規作成
                    </button>
                </div>
                <Table
                    columns={columns}
                    dataSource={dataListPayment}
                    pagination={false}
                    locale={{ emptyText: 'データがありません。' }}
                    loading={isLoading}
                />
                {dataListPayment && dataListPayment.length > 0 && (
                    <>
                        <div className="flex items-baseline gap-[50px] justify-end mt-[20px] pr-[18px] !shadow-none">
                            <p className="font-normal text-[14px] text-[#666666]">
                                ページ {params?.page} / {params?.total_page}
                            </p>
                            <div className="flex gap-[25px]">
                                <LeftOutlinedCustom
                                    style={{
                                        color: '#666666',
                                        fontSize: 12,
                                    }}
                                    className={clsx(
                                        'cursor-pointer !font-semibold',
                                        {
                                            '!text-[black]':
                                                statusPrev &&
                                                params?.page !== 1,
                                            '!text-[#D3D3D3]':
                                                params?.page === 1,
                                        },
                                    )}
                                    onClick={setPagePrev}
                                    disabled
                                />

                                <RightOutlined
                                    style={{
                                        color: '#666666',
                                        fontSize: 12,
                                    }}
                                    className={clsx(
                                        'cursor-pointer !font-semibold',
                                        {
                                            '!text-[black] inline-flex':
                                                statusNext &&
                                                params?.page !==
                                                    params?.total_page,
                                            '!text-[#D3D3D3]':
                                                params?.page ===
                                                params?.total_page,
                                        },
                                    )}
                                    onClick={setPageNext}
                                />
                            </div>
                        </div>
                    </>
                )}
            </div>
        </>
    );
};

export default Payment;
