import React, { useState, useEffect, FocusEventHandler } from 'react';
import { useParams, Link } from 'react-router-dom';
import 'react-step-progress/dist/index.css';
import { useModal } from 'react-hooks-use-modal';
import { useNavigate } from 'react-router-dom';

import estimationService from '../../../../../services/estimationService';
import plus from '../../../../../assets/icons/Plus.png';
import { useLogicEstimate } from '../hooks';
import ListBox from './ListBox';
import { Main } from './style';
import CustomPagination from 'components/CustomPagination';
import { IPageQueries } from 'models';
import { Controller, useForm } from 'react-hook-form';
import Dropdown from 'react-dropdown';
import budgetService from 'services/budgetService';
import 'react-step-progress/dist/index.css';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import budgetActionService from 'services/budgetActionService';
import { validateFullWhitespace } from 'utils/validation/validatorHelper';
import { styled } from '@mui/material/styles';
import { Button, Input, message } from 'antd';
import { fullWidthNumConvert } from 'pages/invoice';
import {
    NumberFormatBase,
    NumericFormat,
    useNumericFormat,
} from 'react-number-format';
import PlusIcon from 'assets/icons/white-plus.svg';
import { DeleteOutlined } from '@mui/icons-material';
import { alertError, alertSuccessPopup } from 'utils/helper/appHelper';
import invoiceApi from 'services/invoiceApi';
import projectApi from 'services/projectApi';
import ExclamationIcon from 'assets/icons/exclamation.svg';
import clsx from 'clsx';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: '#377ACD',
        color: '#fff',
        fontWeight: 600,
        padding: '6px 8px',
    },
    [`&.${tableCellClasses.body}`]: {
        fontFamily: 'Zen Kaku Gothic Antique',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '16px',
        lineHeight: '24px',
        color: '#1C1E21',
        padding: '0px 8px',
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const StyledDefaultInput = styled(Input)({
    padding: '8px 12px 8px 12px',
    // border: 'none',
    height: '31px',
    border: '1px solid #D9D9D9',
    width: '100%',
    borderRadius: '8px',
});

const EstimationList = (props) => {
    const { project, setTab, getDetailProject } = props;
    const navigate = useNavigate();

    const { statusOptions, id } = useLogicEstimate();
    const [estimations, setEstimation] = useState<any[]>([]);
    const [deleteRow, setDeleteRow] = useState<any>({});
    const [searchObject, setSearchObject] = useState<any>({
        plan_id: '',
        worker_id: '',
        start_at: null,
        end_at: null,
        free_word: '',
        project_id: id,
    });
    const [totalCount, setTotalCount] = useState<number>(0);
    const [pageQueries, setPageQueries] = useState<IPageQueries>({
        page: 1,
        per_page: 20,
    });

    const {
        control,
        handleSubmit,
        watch,
        register,
        getValues,
        setError,
        setValue,
        formState: { errors },
        resetField,
        clearErrors,
    } = useForm();
    const [arrItem, setArrItem] = useState([1]);
    let storage = localStorage.getItem('user');
    const user = storage !== null ? JSON.parse(storage) : null;
    const [totalBeforeTax, setTotalBeforeTax] = useState(0);
    const [totalAfterTax, setTotalAfterTax] = useState(0);
    const [totalTax, setTotalTax] = useState(0);
    const param = useParams();
    const [orders, setOrders]: any = useState();
    const [curInd, setCurInd]: any = useState();

    const [ModalDelete, openDelete, closeDelete, isOpenDelete] = useModal(
        'root',
        {
            preventScroll: true,
            // //closeOnOverlayClick: false,
        },
    );

    const getEstimations = async ({ page = 1, per_page = 0 }) => {
        try {
            const responseEstimation =
                await estimationService.getListEstimation(Number(id), {
                    page,
                    per_page,
                });
            if (
                responseEstimation !== null &&
                responseEstimation !== undefined
            ) {
                setTotalCount(responseEstimation.meta.total_count);
                setEstimation(responseEstimation.estimates);
            }
        } catch (err) { }
    };

    const getDetail = async (arrEdit?, order_id?) => {
        try {
            const res = await projectApi.getDetailProjectOrders(
                user?.access_token,
                order_id || props?.project?.order?.id || orders?.id,
                props.project_id,
            );
            if (res) {
                const data = res.data?.order;
                setOrders(data);
                let arrTmp: any = [];
                if (arrEdit) {
                    arrTmp = [...arrEdit];
                } else {
                    arrTmp = [...arrItem];
                }
                if (data.order_items.length > 0) {
                    const resCount = data.order_items.length > 1 ? 1 : 0;
                    for (let i = 0; i < data.order_items.length; i++) {
                        const countTmp = arrTmp[arrTmp.length - 1] || 0;
                        arrTmp.push(countTmp + 1);
                        setValue(
                            `id.${i}`,
                            data.order_items[i] ? data.order_items[i].id : '',
                        );
                        setValue(
                            `content.${i}`,
                            data.order_items[i] ? data.order_items[i].name : '',
                        );
                        setValue(
                            `price.${i}`,
                            data.order_items[i]
                                ? String(
                                    Number(
                                        data.order_items[i].unit_price,
                                    ).toLocaleString('en-US'),
                                )
                                : '',
                        );
                        setValue(
                            `quantity.${i}`,
                            data.order_items[i]
                                ? String(
                                    data.order_items[
                                        i
                                    ].quantity.toLocaleString('en-US'),
                                )
                                : '',
                        );
                        setValue(`tax.${i}`, 10);
                        setValue(
                            `unit.${i}`,
                            data.order_items[i] ? data.order_items[i].unit : '',
                        );
                    }
                }
                setArrItem(arrTmp);
                calTotal();
            }
        } catch (error) {
            console.log('error', error);
        }
    };

    // useEffect(() => {
    //     getEstimations({
    //         page: pageQueries.page,
    //         per_page: pageQueries.per_page,
    //     });
    // }, []);

    const handleDeleteEstimate = async () => {
        if (getValues(`id.${curInd}`)) {
            try {
                const res = await projectApi.deleteProjectEstimated(
                    user?.access_token,
                    orders?.id,
                    getValues(`id.${curInd}`),
                    props.project_id,
                );
                if (res) {
                    onDelete(curInd);
                }
            } catch (error) {
                console.log('error', error);
            }
        } else {
            onDelete(curInd);
        }
        closeDelete();
    };

    const [
        ModalComparison,
        openModalCompare,
        closeModalCompare,
        isOpenComparison,
    ] = useModal('root', {
        preventScroll: true,
    });

    const [budgetsListOptions, setBudgetListOptions] = useState<any>([]);
    const [estimatesCompare, setEstimatesCompare] = useState<any>(null);

    // useEffect(() => {
    //     fetchListBudget();
    // }, []);

    const fetchListBudget = async () => {
        try {
            const response = await budgetService.getListBudget({
                page: 1,
                per_page: 0,
                project_id: id,
            });
            setBudgetListOptions(
                response.budgets?.map((item) => ({
                    label: item.name,
                    value: item.id,
                })),
            );
        } catch (error) { }
    };

    const [budgetSelectItem, setBudgetSelectItem] = useState<any>({});
    const handleValidateWhitespace: FocusEventHandler<
        HTMLInputElement | HTMLTextAreaElement
    > = (e) => {
        const onlyContainWhitespaces = validateFullWhitespace(e.target.value);
        if (onlyContainWhitespaces) {
            setValue(e.target.name, '');
        }
    };

    const calTotal = () => {
        let totalBFTax = 0;
        let totalTax = 0;
        let totalAfterTax = 0;
        if (getValues('price').length > 0) {
            for (let i = 0; i < getValues('price').length; i++) {
                let price = getValues(`price.${i}`)
                    ? getValues(`price.${i}`).replace(/,/g, '')
                    : '';
                let quantity = getValues(`quantity.${i}`)
                    ? getValues(`quantity.${i}`).replace(/,/g, '')
                    : '';
                // console.log('quantity replace', quantity.replace(/,/g, ''))
                if (
                    Number(price) > -1 &&
                    Number(getValues(`tax.${i}`)) > -1 &&
                    Number(quantity) > -1
                ) {
                    totalBFTax += Number(price) * Number(quantity);
                    totalTax +=
                        (Number(getValues(`tax.${i}`)) *
                            Number(quantity) *
                            Number(price)) /
                        100;
                    totalAfterTax +=
                        Number(price) * Number(quantity) +
                        (Number(getValues(`tax.${i}`)) *
                            Number(quantity) *
                            Number(price)) /
                        100;
                }
            }
            // console.log('totalBFTax', totalBFTax)
        }
        setTotalBeforeTax(totalBFTax);
        setTotalAfterTax(totalAfterTax);
        setTotalTax(totalTax);
    };

    const onDelete = (index) => {
        const filterArrItem = arrItem.filter((item, ind) => ind !== index);
        const contentFilter = getValues('content').filter(
            (item, ind) => ind !== index,
        );
        const unitFilter = getValues('unit').filter(
            (item, ind) => ind !== index,
        );
        const priceFilter = getValues('price').filter(
            (item, ind) => ind !== index,
        );
        const quantityFilter = getValues('quantity').filter(
            (item, ind) => ind !== index,
        );
        const taxFilter = getValues('tax').filter((item, ind) => ind !== index);
        const arrTmp: any = [];
        for (let i = 0; i < filterArrItem.length; i++) {
            const countTmp = arrTmp[arrTmp.length - 1] || 0;
            arrTmp.push(countTmp + 1);
        }
        setArrItem([...arrTmp]);
        setValue('content', [...contentFilter]);
        setValue('unit', [...unitFilter]);
        setValue('quantity', [...quantityFilter]);
        setValue('price', [...priceFilter]);
        setValue('tax', [...taxFilter]);
        calTotal();
    };

    const onAddArr = () => {
        const arrTmp = [...arrItem];
        if (arrItem.length > 0) {
            arrTmp.push(arrItem[arrItem.length - 1] + 1);
            setValue(`tax.${arrTmp.length - 1}`, 10);
            setValue(`unit.${arrTmp.length - 1}`, '式');
            setValue(`content.${arrTmp.length - 1}`, undefined);
        } else {
            arrTmp.push(1);
            setValue(`unit.0`, '式');
            setValue(`content.0`, undefined);
            setValue(`tax.0`, 10);
        }
        setArrItem(arrTmp);
    };

    const submitData = async (value) => {
        const arrTmp: any = [];
        if (
            value.content.length > 0 ||
            value.unit.length > 0 ||
            value.price.length > 0 ||
            value.quantity.length > 0
        ) {
            for (let i = 0; i < value.content.length; i++) {
                if (
                    value?.content[i] &&
                    value?.content[i]?.length > 0 &&
                    value?.unit[i] &&
                    value?.unit[i]?.length > 0 &&
                    value.quantity[i] &&
                    value.price[i]
                ) {
                    const quantity = parseFloat(value.quantity[i].replace(/,/g, ''));
                    const price = parseFloat(value.price[i].replace(/,/g, ''));
                    if (quantity >= 0 && price >= 0) {
                        const objTmp: any = {
                            name: value.content[i],
                            unit: value.unit[i] || '式',
                            quantity: quantity,
                            unit_price: price,
                            amount: quantity * price || 0,
                            total_price:
                                (quantity * price * 10) / 100 +
                                quantity * price || 0,
                        };
                        if (value?.id && value?.id[i]) {
                            objTmp.id = value.id[i];
                        }
                        arrTmp.push(objTmp);
                    } else {
                        alertError(
                            '合計注文金額は¥0から¥99,999,999までの間である必要があります。',
                        );
                        return
                    }
                } else {
                    alertError(
                        '内容、数量、単位、単価を入力する必要があります。',
                    );
                    return;
                }
            }
        }

        if (Number(totalAfterTax) >= 0 && Number(totalAfterTax) <= 99999999) {
            const body: any = {
                project_id: param.id,
                order_items_attributes: arrTmp,
            };
            setArrItem([1]);
            if (orders?.id) {
                try {
                    const res = await projectApi.editProjectOrders(
                        user?.access_token,
                        body,
                        orders?.id,
                    );
                    if (res) {
                        const data = res.data.order;
                        setOrders(data);
                        alertSuccessPopup({
                            title: '請求書の作成を成功しました',
                            confirmButtonText: '閉じる',
                        });
                        getDetail([], data?.id);
                        setTab(2);
                        // getDetail([]);
                        getDetailProject();
                    }
                } catch (error) {
                    console.log('error', error);
                }
            } else {
                try {
                    const res = await projectApi.createProjectOrders(
                        user?.access_token,
                        body,
                    );
                    if (res) {
                        const data = res.data.order;
                        setOrders(data);
                        alertSuccessPopup({
                            title: '請求書の作成を成功しました',
                            confirmButtonText: '閉じる',
                        });
                        getDetail([], data?.id);
                        setTab(2);
                        getDetailProject();
                    }
                } catch (error) {
                    console.log('error', error);
                }
            }
        } else {
            alertError(
                '合計注文金額は¥0から¥99,999,999までの間である必要があります。',
            );
        }
    };

    const handleBlur = () => {
        setArrItem([...arrItem]);
        calTotal();
    };

    useEffect(() => {
        setValue(`unit.0`, '式');
        setValue(`tax.0`, 10);
        setValue(`price.0`, '');
        setValue(`quantity.0`, '');
    }, []);

    useEffect(() => {
        project?.order?.id && getDetail([]);
    }, [project]);

    const isDisabledField =
        project?.estimate !== null && project?.implement !== null || project?.estimate === null;

    return (
        <Main>
            <div className="d-flex flex-column">
                <div className="d-flex flex-column">
                    <form onSubmit={handleSubmit(submitData)}>
                        <div className="table-workers d-flex flex-column mt-[45px]">
                            <div className="table-content d-flex flex-column">
                                <TableContainer table-layout={'auto'}>
                                    <Table
                                        sx={{ minWidth: 900 }}
                                        aria-label="customized table"
                                    >
                                        <TableHead
                                            style={{
                                                background: '#377ACD',
                                                color: '#fff',
                                            }}
                                        >
                                            <TableRow>
                                                <StyledTableCell
                                                    className="text-overflow1"
                                                    style={{
                                                        width: '5%',
                                                        whiteSpace: 'nowrap',
                                                    }}
                                                >
                                                    <div>番号</div>
                                                </StyledTableCell>

                                                <StyledTableCell
                                                    className="text-overflow1"
                                                    style={{ width: '25%' }}
                                                >
                                                    <div>内容</div>
                                                </StyledTableCell>

                                                <StyledTableCell
                                                    className="text-overflow1"
                                                    style={{ width: '10%' }}
                                                >
                                                    <div>数量</div>
                                                </StyledTableCell>

                                                <StyledTableCell
                                                    className="text-overflow1"
                                                    style={{ width: '10%' }}
                                                >
                                                    <div>単位</div>
                                                </StyledTableCell>

                                                <StyledTableCell
                                                    className="text-overflow1"
                                                    style={{ width: '12%' }}
                                                >
                                                    <div>単価</div>
                                                </StyledTableCell>

                                                <StyledTableCell
                                                    className="text-overflow1"
                                                    style={{ width: '12%' }}
                                                >
                                                    <div>金額</div>
                                                </StyledTableCell>
                                                <StyledTableCell
                                                    className="text-overflow1"
                                                    style={{
                                                        width: '12%',
                                                        textAlign: 'center',
                                                    }}
                                                >
                                                    <div
                                                        style={{
                                                            whiteSpace:
                                                                'nowrap',
                                                            textAlign: 'center',
                                                        }}
                                                    >
                                                        金額（税込）
                                                    </div>
                                                </StyledTableCell>
                                                <StyledTableCell
                                                    className="text-overflow1"
                                                    style={{
                                                        width: '5%',
                                                        padding: '0px',
                                                    }}
                                                >
                                                    <div>操作</div>
                                                </StyledTableCell>
                                            </TableRow>
                                        </TableHead>

                                        <TableBody>
                                            {arrItem.map((row, ind) => {
                                                if (ind < arrItem.length) {
                                                    let price =
                                                        getValues(
                                                            `price.${ind}`,
                                                        ) || '';
                                                    let quantity =
                                                        getValues(
                                                            `quantity.${ind}`,
                                                        ) || '';
                                                    if (
                                                        String(price).includes(
                                                            ',',
                                                        )
                                                    ) {
                                                        price = price.replace(
                                                            /,/g,
                                                            '',
                                                        );
                                                    }
                                                    if (
                                                        String(
                                                            quantity,
                                                        ).includes(',')
                                                    ) {
                                                        quantity =
                                                            quantity.replace(
                                                                /,/g,
                                                                '',
                                                            );
                                                    }
                                                    return (
                                                        <StyledTableRow
                                                            key={row}
                                                        >
                                                            <StyledTableCell
                                                                className="!text-left"
                                                                component="th"
                                                                scope="row"
                                                                style={{
                                                                    width: '5%',
                                                                }}
                                                            >
                                                                <div
                                                                    style={{
                                                                        display:
                                                                            'flex',
                                                                        alignItems:
                                                                            'center',
                                                                    }}
                                                                >
                                                                    <span>
                                                                        {' '}
                                                                        {row}
                                                                    </span>
                                                                </div>
                                                            </StyledTableCell>

                                                            <StyledTableCell
                                                                className="!text-left"
                                                                style={{
                                                                    width: '25%',
                                                                }}
                                                            >
                                                                <Controller
                                                                    name={`content.${ind}`}
                                                                    control={
                                                                        control
                                                                    }
                                                                    render={({
                                                                        field,
                                                                    }) => (
                                                                        <StyledDefaultInput
                                                                            {...register(
                                                                                `content.${ind}`,
                                                                            )}
                                                                            {...field}
                                                                            onBlur={(evt) => {
                                                                                const value = evt.target.value;
                                                                                setValue(`content.${ind}`, value.trim());
                                                                            }}
                                                                        />
                                                                    )}
                                                                    disabled={
                                                                        isDisabledField
                                                                    }
                                                                />
                                                            </StyledTableCell>

                                                            <StyledTableCell
                                                                className="!text-left"
                                                                style={{
                                                                    width: '10%',
                                                                }}
                                                            >
                                                                <div>
                                                                    <Controller
                                                                        name={`quantity.${ind}`}
                                                                        control={
                                                                            control
                                                                        }
                                                                        render={({
                                                                            field,
                                                                        }) => (
                                                                            <StyledDefaultInput
                                                                                {...register(
                                                                                    `quantity.${ind}`,
                                                                                )}
                                                                                onBlur={(evt,) => {
                                                                                    let stringWithoutCommas = evt.target.value.replace(/,/g, '')
                                                                                    setValue(`quantity.${ind}`, Number(fullWidthNumConvert(stringWithoutCommas)).toLocaleString('en-US'));
                                                                                    handleBlur();
                                                                                }}
                                                                                value={
                                                                                    field.value
                                                                                }
                                                                                maxLength={
                                                                                    9
                                                                                }
                                                                                onChange={(
                                                                                    evt,
                                                                                ) => {
                                                                                    let stringWithoutCommas =
                                                                                        evt.target.value.replace(
                                                                                            /,/g,
                                                                                            '',
                                                                                        );
                                                                                    if (
                                                                                        stringWithoutCommas.match(
                                                                                            '^[0-9０-９]+$',
                                                                                        ) ||
                                                                                        stringWithoutCommas ===
                                                                                        ''
                                                                                    ) {
                                                                                        const newVal =
                                                                                            fullWidthNumConvert(
                                                                                                stringWithoutCommas,
                                                                                            );
                                                                                        field.onChange(
                                                                                            stringWithoutCommas,
                                                                                        );
                                                                                        setValue(
                                                                                            `quantity.${ind}`,
                                                                                            stringWithoutCommas,
                                                                                        );
                                                                                    }
                                                                                }}
                                                                                disabled={
                                                                                    isDisabledField
                                                                                }
                                                                            />
                                                                        )}
                                                                    />
                                                                </div>
                                                            </StyledTableCell>

                                                            <StyledTableCell
                                                                className="!text-left"
                                                                style={{
                                                                    width: '10%',
                                                                }}
                                                            >
                                                                <Controller
                                                                    name={`unit.${ind}`}
                                                                    control={
                                                                        control
                                                                    }
                                                                    render={({
                                                                        field,
                                                                    }) => (
                                                                        <StyledDefaultInput
                                                                            maxLength={
                                                                                3
                                                                            }
                                                                            style={{
                                                                                textAlign:
                                                                                    'center',
                                                                            }}
                                                                            {...register(
                                                                                `unit.${ind}`,
                                                                            )}
                                                                            {...field}
                                                                            disabled={
                                                                                isDisabledField
                                                                            }
                                                                            onBlur={(evt) => {
                                                                                const value = evt.target.value;
                                                                                setValue(`unit.${ind}`, value.trim());
                                                                            }}
                                                                        />
                                                                    )}
                                                                />
                                                            </StyledTableCell>

                                                            <StyledTableCell
                                                                align="center"
                                                                style={{
                                                                    width: '12%',
                                                                }}
                                                            >
                                                                <Controller
                                                                    name={`price.${ind}`}
                                                                    control={
                                                                        control
                                                                    }
                                                                    render={({
                                                                        field,
                                                                    }) => (
                                                                        <StyledDefaultInput
                                                                            {...register(
                                                                                `price.${ind}`,
                                                                            )}
                                                                            onBlur={(evt) => {
                                                                                let stringWithoutCommas = evt.target.value.replace(/,/g, '')
                                                                                setValue(`price.${ind}`, Number(fullWidthNumConvert(stringWithoutCommas)).toLocaleString('en-US'),
                                                                                );
                                                                                handleBlur();
                                                                            }}
                                                                            value={
                                                                                field.value
                                                                            }
                                                                            style={{
                                                                                textAlign:
                                                                                    'right',
                                                                            }}
                                                                            maxLength={
                                                                                9
                                                                            }
                                                                            onChange={(
                                                                                evt,
                                                                            ) => {
                                                                                let stringWithoutCommas =
                                                                                    evt.target.value.replace(
                                                                                        /,/g,
                                                                                        '',
                                                                                    );
                                                                                if (
                                                                                    stringWithoutCommas.match(
                                                                                        '^[0-9０-９]+$',
                                                                                    ) ||
                                                                                    stringWithoutCommas ===
                                                                                    ''
                                                                                ) {
                                                                                    const newVal =
                                                                                        fullWidthNumConvert(
                                                                                            stringWithoutCommas,
                                                                                        );
                                                                                    field.onChange(
                                                                                        stringWithoutCommas,
                                                                                    );
                                                                                    setValue(
                                                                                        `price.${ind}`,
                                                                                        stringWithoutCommas,
                                                                                    );
                                                                                }
                                                                            }}
                                                                            disabled={
                                                                                isDisabledField
                                                                            }
                                                                        />
                                                                    )}
                                                                />
                                                            </StyledTableCell>

                                                            <StyledTableCell
                                                                align="center"
                                                                style={{
                                                                    width: '12%',
                                                                }}
                                                            >
                                                                <NumericFormat
                                                                    customInput={
                                                                        StyledDefaultInput
                                                                    }
                                                                    thousandSeparator=","
                                                                    decimalSeparator="."
                                                                    prefix={
                                                                        '￥'
                                                                    }
                                                                    name="amount_money"
                                                                    style={{
                                                                        textAlign:
                                                                            'right',
                                                                        background:
                                                                            '#fff',
                                                                        color: 'rgba(0, 0, 0, 0.85)',
                                                                    }}
                                                                    value={
                                                                        Number(
                                                                            price,
                                                                        ) *
                                                                        Number(
                                                                            quantity,
                                                                        ) ||
                                                                        '0'
                                                                    }
                                                                    disabled
                                                                />
                                                            </StyledTableCell>
                                                            <StyledTableCell
                                                                align="center"
                                                                style={{
                                                                    width: '12%',
                                                                }}
                                                            >
                                                                <NumericFormat
                                                                    customInput={
                                                                        StyledDefaultInput
                                                                    }
                                                                    thousandSeparator=","
                                                                    decimalSeparator="."
                                                                    prefix={
                                                                        '￥'
                                                                    }
                                                                    disabled
                                                                    name="amount_money_tax"
                                                                    style={{
                                                                        textAlign:
                                                                            'right',
                                                                        background:
                                                                            '#fff',
                                                                        color: 'rgba(0, 0, 0, 0.85)',
                                                                    }}
                                                                    value={
                                                                        (Number(price) *
                                                                            Number(quantity) +
                                                                            (Number(price) * Number(quantity) *
                                                                                Number(getValues(`tax.${ind}`))) / 100).toFixed(0) ||
                                                                        '0'
                                                                    }
                                                                />
                                                            </StyledTableCell>
                                                            <StyledTableCell
                                                                align="center"
                                                                style={{
                                                                    width: '5%',
                                                                    paddingLeft:
                                                                        '0px',
                                                                }}
                                                            >
                                                                <div
                                                                    style={{
                                                                        borderLeft:
                                                                            '1px solid #D9D9D9',
                                                                        height: '40px',
                                                                    }}
                                                                    className="flex justify-center items-center"
                                                                >
                                                                    <button
                                                                        onClick={() => {
                                                                            setCurInd(
                                                                                ind,
                                                                            );
                                                                            openDelete();
                                                                        }}
                                                                        disabled={
                                                                            isDisabledField
                                                                        }
                                                                        className={clsx(
                                                                            'button-tb btn-delete !bg-[#ff5045]',
                                                                            {
                                                                                'cursor-no-drop':
                                                                                    isDisabledField,
                                                                            },
                                                                        )}
                                                                    >
                                                                        削除
                                                                    </button>
                                                                </div>
                                                            </StyledTableCell>
                                                        </StyledTableRow>
                                                    );
                                                }
                                            })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </div>
                            <div className="flex flex-row justify-center mt-[20px] w-full">
                                <Button
                                    className="w-[114px] !h-[38px] text-white bg-[#215493] flex flex-row"
                                    style={{
                                        borderRadius: '8px',
                                        background: '#215493',
                                        gap: '8px',
                                        marginTop: '10px',
                                    }}
                                    onClick={onAddArr}
                                    disabled={isDisabledField}
                                >
                                    <div className="flex flex-row gap-3">
                                        <img
                                            src={PlusIcon}
                                            alt="plus"
                                            height={16}
                                            width={16}
                                        />{' '}
                                        <span>追加</span>
                                    </div>
                                </Button>
                            </div>
                            <div className="flex flex-col items-end text-[14px] p-[5px]">
                                <div
                                    className="flex flex-col bg-[#E8F2FF] w-[300px] p-[5px]"
                                    style={{ borderRadius: '8px' }}
                                >
                                    <div className="flex flex-row justify-between h-[32px]">
                                        <p style={{ color: '#0000008C' }}>
                                            小計
                                        </p>
                                        <p>
                                            ¥
                                            {totalBeforeTax.toFixed().toLocaleString()}
                                        </p>
                                    </div>
                                    <div className="flex flex-row justify-between h-[32px]">
                                        <p style={{ color: '#0000008C' }}>
                                            割引
                                        </p>
                                        <p>¥0</p>
                                    </div>
                                    <div className="flex flex-row justify-between h-[32px]">
                                        <p style={{ color: '#0000008C' }}>
                                            消費税（10％）
                                        </p>
                                        <p>
                                            ¥{totalTax.toFixed().toLocaleString()}
                                        </p>
                                    </div>
                                    <div className="flex flex-row justify-between h-[32px]">
                                        <p style={{ color: '#0000008C' }}>
                                            合計
                                        </p>
                                        <p>
                                            ¥
                                            {totalAfterTax.toFixed().toLocaleString()}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div
                            className="bg-[#fff] flex flex-row justify-end"
                            style={{ padding: '16px 24px 16px 24px' }}
                        >
                            <div
                                className="d-flex flex-row text-lg"
                                style={{
                                    marginTop: '14px',
                                }}
                            >
                                <button
                                    className={clsx(
                                        'modal-create-user-button1 d-flex flex-row w-[140px]',
                                        {
                                            'cursor-no-drop': isDisabledField,
                                        },
                                    )}
                                    style={{
                                        width: '140px',
                                        borderRadius: '0px',
                                        border: '1px solid #D9D9D9',
                                        color: '#000000D9',
                                    }}
                                    type="button"
                                    onClick={() => navigate(-1)}
                                    disabled={isDisabledField}
                                >
                                    キャンセル
                                </button>

                                <button
                                    className={clsx(
                                        'modal-create-user-button2 d-flex flex-row mx-[15px] w-[140px]',
                                        {
                                            'cursor-no-drop': isDisabledField,
                                        },
                                    )}
                                    // disabled={invoice?.status === 'paid'}
                                    style={{
                                        width: '140px',
                                        borderRadius: '0px',
                                        // background: `${invoice?.status === 'paid' && '#CCCCCC'
                                        //   }`,`
                                    }}
                                    type="submit"
                                    disabled={isDisabledField}
                                >
                                    保存
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
                <ModalDelete>
                    <div
                        className="modal-create-user d-flex flex-column"
                        style={{ height: '250px' }}
                    >
                        <div className="flex flex-col justify-center items-center pt-[15px] pb-[10px]">
                            <img
                                src={ExclamationIcon}
                                className="w-[80px] h-[80px]"
                                alt="exclamation icon"
                            />
                        </div>
                        <div className="modal-create-user-title d-flex flex-row">
                            本当に削除しますか？
                        </div>
                        <div
                            className="modal-create-user-buttons d-flex flex-row"
                            style={{ marginTop: '50px' }}
                        >
                            <button
                                className="modal-create-user-button1 d-flex flex-row"
                                onClick={closeDelete}
                            >
                                キャンセル
                            </button>
                            <button
                                className="modal-create-user-button2 d-flex flex-row"
                                onClick={handleDeleteEstimate}
                            >
                                はい
                            </button>
                        </div>
                    </div>
                </ModalDelete>
                <ModalComparison>
                    <form>
                        <div className="modal-create-user d-flex flex-column !p-[0px] !w-[387px] !h-[220px] !justify-start">
                            <div className="!text-[white] modal-create-user-title d-flex flex-row bg-[#215493] !p-[17px] !h-[47px] !justify-between">
                                <div>利益</div>
                                <Button
                                    className="!bg-[#215493] !text-[white] !border-0"
                                    onClick={closeModalCompare}
                                >
                                    X
                                </Button>
                            </div>
                            <div className="search-workers-row-dropdown d-flex flex-column !px-[17px] pt-[20px] pb-[10px] !w-full !h-auto">
                                <div className="input-add-user d-flex flex-column !max-w-none">
                                    <p style={{ marginTop: 0 }}>予実管理</p>
                                    <Controller
                                        name="budget"
                                        control={control}
                                        render={({ field }) => (
                                            <Dropdown
                                                className="dropdown-create-user1 height36"
                                                controlClassName="dropdown-control-user height36"
                                                options={[
                                                    ...budgetsListOptions,
                                                ]}
                                                {...field}
                                                placeholder=""
                                                onChange={(e) =>
                                                    setBudgetSelectItem(e)
                                                }
                                            />
                                        )}
                                    />
                                </div>
                            </div>
                            <div className="modal-create-user-buttons d-flex flex-row !px-[17px]">
                                <button
                                    className="modal-create-user-button1 d-flex flex-row"
                                    onClick={closeModalCompare}
                                >
                                    キャンセル
                                </button>
                                <button
                                    onClick={() =>
                                        Object.keys(budgetSelectItem).length > 0
                                            ? navigate('/compare', {
                                                state: {
                                                    estimateData:
                                                        estimatesCompare,
                                                    budgetData:
                                                        budgetSelectItem,
                                                    project: props.project,
                                                },
                                            })
                                            : closeModalCompare()
                                    }
                                    className="modal-create-user-button2 d-flex flex-row"
                                >
                                    比較
                                </button>
                            </div>
                        </div>
                    </form>
                </ModalComparison>
            </div>
        </Main>
    );
};

export default EstimationList;
