import { Box } from '@mui/material';
import { StyledModalInvoice } from './styled';
import CloseIcon from '@mui/icons-material/Close';
import { TEXT_ACTION } from 'utils/constants/textAction';
import { ACTION } from 'utils/enums/action';
import { Form, Input, Select, UploadFile } from 'antd';
import TableInvoice from './TableInvoice';
import { useEffect, useState } from 'react';
import UploadPdf from './UploadPdf';
import { validate } from 'utils/constants/validate';
import { formatMoney } from 'utils/helper/helpers';
import {
    formatNumber,
    removeSpecialCharacters,
} from 'utils/constants/function';
import clsx from 'clsx';
import { alertError, alertSuccessPopup } from 'utils/helper/appHelper';
import invoiceService from 'services/invoiceService';
import LoadingSpin from 'components/LoadingSpin';
interface ModalCUDInvoiceProps {
    isOpenInvoice?: boolean;
    setIsOpenInvoice: React.Dispatch<React.SetStateAction<boolean>>;
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
    setTypeAction: (data: string) => void;
    typeAction?: string;
    project_id?: number | any;
    getListInvoice: any;
    invoice_id?: number | any;
    isLoading?: boolean;
    isDisabledField?: boolean;
    setCurrentRecord?: any;
    getDetailProject?: any;
}

const dataDefault = [
    {
        key: 1,
        contents: '',
        quantity: '',
        unit: '式',
        unit_price: '',
        amount: '¥0',
        price_tax_included: '¥0',
    },
];

export const defaultListTotal = [
    { label: '小計', id: 1, value: 0 },
    { label: '割引', id: 2, value: 0 },
    { label: '消費税（10％）', id: 3, value: 0 },
    { label: '合計', id: 4, value: 0 },
];
const ModalCUDInvoice: React.FC<ModalCUDInvoiceProps> = ({
    isOpenInvoice,
    typeAction,
    setIsOpenInvoice,
    setTypeAction,
    project_id,
    getListInvoice,
    invoice_id,
    setIsLoading,
    isLoading,
    setCurrentRecord,
    getDetailProject,
    isDisabledField,
}) => {
    const handleClose = () => {
        setIsOpenInvoice(false);
        setTypeAction('');
        form?.resetFields();
        setDataInvoice([]);
        setFilePdfList([]);
        setListTotal(defaultListTotal);
        setIsLoading(false);
        setCurrentRecord({});
    };
    const [filePdfList, setFilePdfList] = useState<UploadFile[]>([]);
    const [form] = Form.useForm();

    const [dataInvoice, setDataInvoice]: any = useState([]);

    const [listTotal, setListTotal] = useState(defaultListTotal);

    const isCreate = typeAction?.includes(ACTION.CREATE);

    const isDetail = typeAction?.includes(ACTION.DETAIL);

    const titleHeader = typeAction?.includes(ACTION.CREATE)
        ? TEXT_ACTION.INVOICE + TEXT_ACTION.CREATE_SUB
        : typeAction?.includes(ACTION.EDIT)
            ? TEXT_ACTION.INVOICE + TEXT_ACTION.EDIT
            : TEXT_ACTION.INVOICE + TEXT_ACTION.DETAIL;

    const titleSubmit = typeAction?.includes(ACTION.CREATE)
        ? TEXT_ACTION.REGISTRATION
        : TEXT_ACTION.KEEP;

    const onSubmit = async (values: any) => {
        const dataFormat = {
            status: values?.status ?? '',
            name: values?.invoice_name ? values?.invoice_name?.trim() : '',
        };
        const dataInvoiceFormat = dataInvoice?.map((item: any) => {
            return {
                ...(item?.id && { id: item?.id }),
                name: item?.contents ? item?.contents?.trim() : '',
                quantity: item?.quantity
                    ? removeSpecialCharacters(item?.quantity)
                    : '',
                unit: item?.unit ?? '',
                unit_price: item?.unit_price
                    ? removeSpecialCharacters(item?.unit_price)
                    : '',
                amount: item?.amount
                    ? removeSpecialCharacters(item?.amount)
                    : '',
                total_price: item?.price_tax_included
                    ? removeSpecialCharacters(item?.price_tax_included)
                    : '',
            };
        });

        const formData = new FormData();
        formData.append('project_id', project_id);
        formData.append('name', dataFormat?.name);
        formData.append('status', dataFormat?.status);

        dataInvoiceFormat?.forEach((invoice: any, index: number) => {
            if (invoice?.id) {
                formData.append('bill_items_attributes[][id]', invoice?.id);
            }
            formData.append('bill_items_attributes[][name]', invoice?.name);
            formData.append(
                'bill_items_attributes[][quantity]',
                invoice?.quantity,
            );
            formData.append('bill_items_attributes[][unit]', invoice?.unit);
            formData.append(
                'bill_items_attributes[][unit_price]',
                invoice?.unit_price,
            );
            formData.append('bill_items_attributes[][amount]', invoice?.amount);
            formData.append(
                'bill_items_attributes[][total_price]',
                invoice?.total_price,
            );
        });
        const filePdfListFormat = filePdfList?.filter((item: any) => !item?.id);
        filePdfListFormat?.forEach((file: any, index: number) => {
            formData.append('files[]', file);
        });
        dataInvoiceFormat?.forEach((item: any) => {
            if (Number(item.unit_price) < 0 || Number(item.quantity) < 0) {
                alertError(
                    '合計注文金額は¥0から¥99,999,999までの間である必要があります。',
                );
                return;
            }
        });
        if (dataInvoice.length === 0) {
            alertError('登録をするためには、最低1つの請求書が必要です。');
        } else {
            setIsLoading(true)
            isCreate ? createInvoice(formData) : editInvoice(formData);
        }
    };

    const createInvoice = async (formData) => {
        try {
            const data = await invoiceService.createInvoice(formData);
            if (data) {
                alertSuccessPopup({
                    title: '請求書が作成されました。',
                    confirmButtonText: '閉じる',
                });
                getDetailProject();
                getListInvoice();
                handleClose();
            }
        } catch (err) {
            const response = err?.response;
            alertError(response.data?.error);
        }
    };

    const editInvoice = async (formData) => {
        try {
            const data = await invoiceService.updateInvoice(
                invoice_id,
                formData,
            );
            if (data) {
                alertSuccessPopup({
                    title: '請求書が編集されました。',
                    confirmButtonText: '閉じる',
                });
                getDetailProject();
                getListInvoice();
                handleClose();
            }
        } catch (err) {
            const response = err?.response;
            alertError(response.data?.error);
        }
    };

    const getDetailInvoice = async () => {
        try {
            const response = await invoiceService.getDetailInvoice(
                invoice_id,
                project_id,
            );
            if (response !== null && response !== undefined) {
                const data = response?.data?.bill;
                const dataInvoiceItems = data?.bill_items?.map(
                    (item: any, index: number) => {
                        const totalAmount = item?.quantity * item?.unit_price;

                        return {
                            ...item,
                            contents: item?.name ?? '',
                            key: index + 1,
                            quantity: item
                                ? String(item?.quantity.toLocaleString('en-US'))
                                : '',
                            unit_price: item?.unit_price
                                ? '¥' +
                                String(
                                    Number(item?.unit_price).toLocaleString(
                                        'en-US',
                                    ),
                                )
                                : '',
                            amount: '¥' + formatNumber(totalAmount),
                            price_tax_included:
                                '¥' +
                                formatNumber(
                                    Math.round(totalAmount + totalAmount * 0.1),
                                ),
                        };
                    },
                );

                setIsLoading(false);
                setDataInvoice(dataInvoiceItems);
                form.setFieldsValue({
                    invoice_name: data?.name,
                    status: data?.status,
                });
                setFilePdfList(data?.files ?? []);
            }
        } catch (err) {
            alertError(err);
        }
    };

    useEffect(() => {
        let subTotal = dataInvoice?.reduce((total, obj) => {
            const amountFormat = obj?.amount
                ? Number(removeSpecialCharacters(obj?.amount))
                : '';
            return total + amountFormat;
        }, 0);

        const consumptionTax = dataInvoice?.reduce((total, obj) => {
            const priceTaxIncludedFormat = obj?.price_tax_included
                ? Number(removeSpecialCharacters(obj?.price_tax_included))
                : '';
            return total + priceTaxIncludedFormat;
        }, 0);
        const total = Number(subTotal) + Number(consumptionTax);

        const arrTotal = [subTotal, 0, consumptionTax, total];

        const listTotalFormat = listTotal?.map((item, index) => {
            return {
                ...item,
                value: arrTotal[index],
            };
        });
        isOpenInvoice && setListTotal(listTotalFormat);
    }, [dataInvoice, isCreate, isOpenInvoice]);

    useEffect(() => {
        !isCreate && isOpenInvoice && getDetailInvoice();
    }, [isOpenInvoice, isCreate]);

    useEffect(() => {
        if (!invoice_id) {
            setDataInvoice(dataDefault);
        }
    }, [invoice_id, isOpenInvoice]);

    const isDisabled = isDetail || isDisabledField;

    return (
        <>
            <StyledModalInvoice
                className="!p-0"
                open={isOpenInvoice}
                footer={null}
                closable={false}
                closeIcon={false}
                onCancel={handleClose}
                centered
                width={1153}
            >
                <Box
                    sx={{
                        background: '#215493',
                        margin: 0,
                        color: 'white',
                        borderTopLeftRadius: '12px',
                        borderTopRightRadius: '12px',
                        p: 2,
                        fontSize: '16px',
                    }}
                    id="modal-modal-title"
                    component="h2"
                >
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                        }}
                    >
                        <span>{titleHeader}</span>
                        <CloseIcon
                            sx={{ cursor: 'pointer' }}
                            onClick={handleClose}
                        />
                    </div>
                </Box>

                <Form form={form} onFinish={onSubmit}>
                    <Box
                        id="modal-modal-description"
                        sx={{
                            my: 2,
                            display: 'flex',
                            flexDirection: 'column',
                            p: '15px',
                            width: '100%',
                            height: '100%',
                            maxHeight: '85vh',
                            minHeight: '85vh',
                            overflowY: 'scroll',
                        }}
                    >
                        {isLoading ? (
                            <LoadingSpin />
                        ) : (
                            <div>
                                <div className="flex gap-4 w-[35%]">
                                    <div className="w-full">
                                        <div>
                                            {!isDisabled && (
                                                <span className="text-red pr-1">
                                                    *
                                                </span>
                                            )}
                                            請求書名
                                        </div>
                                        <Form.Item
                                            name="invoice_name"
                                            rules={[
                                                () => ({
                                                    required: isDisabled,
                                                    validator(
                                                        _: any,
                                                        value: any,
                                                    ) {
                                                        if (
                                                            !value &&
                                                            !isDetail
                                                        ) {
                                                            return Promise.reject(
                                                                new Error(
                                                                    `請求書名${validate?.validate_input}`,
                                                                ),
                                                            );
                                                        } else {
                                                            return Promise.resolve();
                                                        }
                                                    },
                                                }),
                                            ]}
                                            className="w-full pt-2"
                                        >
                                            <Input
                                                className="h-[38px]"
                                                onBlur={(data) => {
                                                    const valueFormat = data?.target?.value;
                                                    form.setFieldValue('invoice_name', valueFormat.trim())
                                                }}
                                                disabled={isDisabled}
                                            />
                                        </Form.Item>
                                    </div>
                                </div>
                                <div className="pt-2 pb-5">
                                    <div className="font-medium text-xl pb-2">
                                        請求書
                                    </div>
                                    <TableInvoice
                                        form={form}
                                        setDataInvoice={setDataInvoice}
                                        dataInvoice={dataInvoice}
                                        isDisabled={isDisabled}
                                        invoice_id={invoice_id}
                                        getListInvoice={getListInvoice}
                                        project_id={project_id}
                                        isOpenInvoice={isOpenInvoice}
                                    />
                                    <div
                                        className={clsx(
                                            'flex justify-between mt-5',
                                        )}
                                    >
                                        <UploadPdf
                                            invoice_id={invoice_id}
                                            isDisabled={isDisabled}
                                            isDetail={isDetail}
                                            filePdfList={filePdfList}
                                            setFilePdfList={setFilePdfList}
                                            isDisabledField={isDisabledField}
                                        />
                                        <div className="bg-[#e8f2ff] px-2 py-1 rounded-lg w-[25%] h-fit">
                                            {listTotal?.map(
                                                (
                                                    element: any,
                                                    index: number,
                                                ) => (
                                                    <div
                                                        key={index}
                                                        className="flex justify-between"
                                                    >
                                                        <div className="text-[#0000008C]">
                                                            {element?.label}
                                                        </div>
                                                        <div className="font-normal text-sm max-w-[150px]">
                                                            {'¥' +
                                                                formatMoney(
                                                                    String(
                                                                        element?.value ||
                                                                        0,
                                                                    ),
                                                                )}
                                                        </div>
                                                    </div>
                                                ),
                                            )}
                                        </div>
                                    </div>
                                </div>
                                {!isDetail && (
                                    <div className="flex gap-3 justify-end pb-2">
                                        <button
                                            type="button"
                                            className="back-button d-flex flex-row !rounded-sm !w-[124px] !h-[48px]"
                                            onClick={handleClose}
                                        >
                                            キャンセル
                                        </button>
                                        <button
                                            type="submit"
                                            className="back-button d-flex flex-row bg-[#215493] !text-[#ffffff] !rounded-sm !h-[48px] !w-[124px]"
                                        >
                                            {titleSubmit}
                                        </button>
                                    </div>
                                )}
                            </div>
                        )}
                    </Box>
                </Form>
            </StyledModalInvoice>
        </>
    );
};
export default ModalCUDInvoice;
