import {
    Box,
    Button,
    InputAdornment,
    MenuItem,
    Select,
    TextField,
} from '@mui/material';
import { DatePicker } from 'antd';
import { Search } from '@mui/icons-material';
import uploadSimple from 'assets/icons/UploadSimple.svg';
import { useEffect, useState } from 'react';
import TableDocument from './table-document';
import ModalUploadDocument from './modal-upload';
import { Controller, useForm } from 'react-hook-form';
import documentsService from 'services/documentService';
import { alertError } from 'utils/helper/appHelper';
import dayjs from 'dayjs';
import { FORMAT } from 'utils/constants/format-date-time';

const { RangePicker } = DatePicker;
const data = Array.from({ length: 9 }, (_, index) => ({
    id: index,
    project_name: `案件A`,
    upload_date: '12/04/2024',
    type: '請求書',
    file_name: `7rGUf8WW5_33lki82J/edit#gid.... .pdf`,
}));

export const typeSelect = [
    {
        label: '請求書',
        value: 'invoice',
    },
    {
        label: '見積書 ',
        value: 'quotation',
    },
];

const defaultParams = {
    page: 1,
    per_page: 10,
    data_type: '',
    name: '',
    start_at: '',
    end_at: '',
    total_page: 0,
};

export interface IParams {
    page?: number;
    per_page?: number;
    data_type?: string;
    name?: string;
    start_at?: string;
    end_at?: string;
    total_page?: number;
}

const Document = () => {
    const [documentList, setDocumentList] = useState<any[]>([]);

    const [params, setParams] = useState<IParams>(defaultParams);
    console.log("🚀 ~ Document ~ params:", params)

    const [currentRecord, setCurrentRecord] = useState({ id: 0 });

    const [isOpenUpload, setIsOpenUpload] = useState(false);

    const [isOpenListFile, setIsOpenListFile] = useState(false);

    const [isOpenDeleteListFile, setIsOpenDeleteListFile] = useState(false);

    const {
        handleSubmit,
        control,
        formState: { errors },
    } = useForm({
        defaultValues: {
            season: undefined,
            status: '',
            project_name: undefined,
        },
    });

    const handleOpenUpload = () => {
        setIsOpenUpload(true);
    };

    const onSubmit = async (data: any) => {
        const [start, end] = data?.season ?? [];

        const dataFormat: IParams = {
            page: 1,
            data_type: data.status ?? '',
            name: data?.project_name ? data?.project_name?.trim() : '',
            start_at: start
                ? dayjs(start).format(FORMAT.FORMAT_DATE_UPPER)
                : '',
            end_at: end ? dayjs(end).format(FORMAT.FORMAT_DATE_UPPER) : '',
        };

        setParams((prev: IParams) => {
            return {
                ...prev,
                ...dataFormat,
            };
        });
    };

    const getListDocument = async (dataFormat?: IParams) => {
        try {
            const response = await documentsService.getListDocument(dataFormat);
            if (response !== null && response !== undefined) {
                const response_data = response?.data;
                const data_managements = response_data?.data_managements ?? [];
                const total_count = response?.data?.meta?.total_count;
                const total_integer = Math.floor(total_count / 10);
                const total_surplus = total_count % 10;
                const total_pageCustom =
                    total_surplus >= 1 ? total_integer + 1 : total_integer;

                setParams((prev: IParams) => {
                    return {
                        ...prev,
                        total_page: total_pageCustom,
                    };
                });
                setDocumentList(data_managements);
            }
        } catch (err) {
            alertError(err);
        }
    };

    useEffect(() => {
        getListDocument(params);
    }, [
        isOpenUpload,
        isOpenDeleteListFile,
        params?.page,
        params?.name,
        params?.data_type,
        params.start_at,
        params.end_at,
    ]);

    return (
        <>
            <div className="container-worker d-flex flex-row">
                <div className="worker-content d-flex flex-column">
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="flex gap-4 items-end">
                            <Box sx={{ marginLeft: '10px' }}>
                                <div className="mb-1 text-sm">時期</div>
                                <Controller
                                    control={control}
                                    render={({
                                        field: { onChange, onBlur, value },
                                    }) => (
                                        <RangePicker
                                            placeholder={['開始日', '終了日']}
                                            className="!h-10"
                                            style={{ width: '260px' }}
                                            onBlur={onBlur}
                                            onChange={onChange}
                                            value={value}
                                            format={FORMAT.FORMAT_DATE_UPPER}
                                        />
                                    )}
                                    name="season"
                                />
                            </Box>
                            <Box
                                sx={{
                                    width: '100%',
                                    ' .MuiSelect-select': {
                                        border: '1px solid #cdd1d5',
                                        borderRadius: '2px',
                                    },
                                    '.MuiInputBase-input': {
                                        padding: '8px !important',
                                    },
                                }}
                                className="d-flex flex-column"
                                style={{ width: '260px' }}
                            >
                                <div className="mb-1 text-sm">ステータス</div>

                                <Controller
                                    control={control}
                                    render={({
                                        field: { onChange, onBlur, value },
                                    }) => (
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            className="!h-10"
                                            onBlur={onBlur}
                                            onChange={onChange}
                                            value={value}
                                        >
                                            {typeSelect?.map(
                                                (element, index) => (
                                                    <MenuItem
                                                        key={index}
                                                        value={element.value}
                                                    >
                                                        {element.label}
                                                    </MenuItem>
                                                ),
                                            )}
                                        </Select>
                                    )}
                                    name="status"
                                />
                            </Box>
                            <Box
                                sx={{
                                    width: '100%',
                                    '.MuiTextField-root': {
                                        border: '1px solid #cdd1d5',
                                        width: '100%',
                                        borderRadius: '2px',
                                        marginRight: '12.5px',
                                    },
                                    '.MuiInputBase-input': {
                                        height: '35px',
                                        padding: '0px !important',
                                    },
                                }}
                                style={{ width: '260px' }}
                            >
                                <div className="mb-1 text-sm">案件名</div>

                                <Controller
                                    control={control}
                                    render={({
                                        field: { onChange, onBlur, value },
                                    }) => (
                                        <TextField
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <Search
                                                            sx={{
                                                                color: '#000',
                                                            }}
                                                        />
                                                    </InputAdornment>
                                                ),
                                            }}
                                            className="!h-10"
                                            autoComplete="off"
                                            id="input-search"
                                            type="text"
                                            name="free_word"
                                            onBlur={onBlur}
                                            onChange={onChange}
                                            value={value}
                                        />
                                    )}
                                    name="project_name"
                                />
                            </Box>
                            <button
                                className="search-user-button d-flex flex-column !p-0 !text-sm"
                                type="submit"
                                style={{
                                    width: '60px',
                                    height: '36px',
                                    borderRadius: '4px',
                                }}
                            >
                                検索
                            </button>
                            <Button
                                component="label"
                                className="uploadImageFileBtn"
                                style={{
                                    backgroundColor: '#215493',
                                    color: '#FFFFFF',
                                    fontWeight: '400',
                                    fontSize: '14px',
                                    height: '36px',
                                    borderRadius: '2px',
                                }}
                                onClick={handleOpenUpload}
                            >
                                <img
                                    src={uploadSimple}
                                    alt="upload icon"
                                    style={{ marginRight: '7px' }}
                                />
                                <span>アップロード</span>
                            </Button>
                        </div>
                    </form>
                    <TableDocument
                        params={params}
                        documentList={documentList}
                        currentRecord={currentRecord}
                        totalPage={params?.total_page}
                        page={params?.page}
                        setParams={setParams}
                        setCurrentRecord={setCurrentRecord}
                        setIsOpenListFile={setIsOpenListFile}
                        isOpenListFile={isOpenListFile}
                        setIsOpenDeleteListFile={setIsOpenDeleteListFile}
                        isOpenDeleteListFile={isOpenDeleteListFile}
                        getListDocument={getListDocument}
                    />
                    <ModalUploadDocument
                        isOpenUpload={isOpenUpload}
                        setIsOpenUpload={setIsOpenUpload}
                    />
                </div>
            </div>
        </>
    );
};
export default Document;
