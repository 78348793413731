import { Upload, UploadProps, message } from 'antd';
import { useState, useEffect, FocusEventHandler } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { RcFile } from 'antd/lib/upload';
import {
    alertSuccess,
    alertSuccessPopup,
} from 'utils/helper/appHelper';
import quoteApi from 'services/quoteApi';
import { validateFullWhitespace } from 'utils/validation/validatorHelper';
import dayjs from 'dayjs';
import { FORMAT } from 'utils/constants/format-date-time';
import moment from 'moment';

export type ReceivedProps = {
    screenType: 'create' | 'edit';
};
const useSPRepairForm = (props: ReceivedProps) => {
    const params = useParams();
    const { id }: any = params;
    const {
        control,
        handleSubmit,
        watch,
        register,
        getValues,
        setError,
        setValue,
        formState: { errors },
        resetField,
        clearErrors,
    } = useForm();
    const numberOfSelectedImages = watch('images');
    let storage = localStorage.getItem('line:accessToken');
    // const user = storage !== null ? JSON.parse(storage) : null;
    const [data, setData]: any = useState();
    const [isAllowUpload, setAllowUpload] = useState(true);

    const [listCompany, setListCompany] = useState([]);

    const [isLoading, setIsLoading] = useState(false);
    const [listFile, setListFile]: any = useState();
    const [totalImageSize, setTotalImageSize]: any = useState<number>(0);
    const [fileLength, setFileLength]: any = useState(0);
    const [isExcessiveUpload, setIsExcessiveUpload] = useState<boolean>(false);
    const [companies, setCompanies]: any = useState([]);
    const location = useLocation();
    const navigate = useNavigate();
    const getDetail = async () => {
        try {
            const res = await quoteApi.getSPQuoteDetail(storage, id);
            if (res) {
                const data = res.data.quote_repair;
                setData(data);
                setValue('company_name', data?.company_name);
                setValue('content', data?.content);
                setValue('construction_site', data?.construction_site);
                // setValue('address', data?.address);
                setValue('person_in_charge', data?.person_in_charge);
                setValue('property_name', data?.property_name);
                if (data?.desired_end_date) {
                    setValue('desired_date', [moment(data?.desired_from_date), moment(data?.desired_end_date)])
                }
                setAllowUpload(data.images.length < 100);
                const fileArr: any = [];
                let imageSize = 0;
                if (data?.images && data?.images.length > 0) {
                    for (let i = 0; i < data?.images.length; i++) {
                        await fetch(data?.images[i].image_path)
                            .then((res) => res.blob())
                            .then((myBlob) => {
                                const myFile: any = new File(
                                    [myBlob],
                                    'image.jpeg',
                                    { type: myBlob.type },
                                );
                                fileArr.push({
                                    uid: `-${i + 1}`,
                                    name: myFile.name,
                                    url: data?.images[i].image_path,
                                    status: 'done',
                                    originFileObj: myFile,
                                    lastModified: myFile.lastModified,
                                    lastModifiedDate: myFile.lastModifiedDate,
                                    type: myFile.type,
                                    size: myFile.size,
                                });
                                imageSize += myFile.size;
                            });
                    }
                }
                setTotalImageSize((prev) => prev + imageSize);
                setValue('images', fileArr);
                setListFile(fileArr);
                setFileLength(fileArr.length);
            }
        } catch (error) {
            console.log('error', error);
        }
    };

    useEffect(() => {
        getAllListCompany();
        getListCompany();
        if (id) {
            getDetail();
        }
    }, [id]);

    const submitData = async (value: any, evt: any) => {
        const isAllLE100MB = totalImageSize < 1024 * 1024 * 100;
        const [start, end] = value?.desired_date ?? [];

        evt.preventDefault();
        setIsLoading(true);
        let formData = new FormData();

        formData.append('person_in_charge', value?.person_in_charge);
        // formData.append('address', value?.address);
        formData.append('content', value?.content);
        for (let i = 0; i < value?.images.length; i++) {
            formData.append('images[]', value?.images[i].originFileObj);
        }

        formData.append('company_name', value?.company_name);
        formData.append('property_name', value?.property_name);
        formData.append('construction_site', value.construction_site);

        formData.append(
            'desired_from_date',
            start ? dayjs(start).format(FORMAT.FORMAT_DATE_UPPER) : '',
        );
        formData.append(
            'desired_end_date',
            end ? dayjs(end).format(FORMAT.FORMAT_DATE_UPPER) : '',
        );

        if (!isAllLE100MB) {
            message.error('画像の合計サイズは 100MB を超えてはなりません');
            setIsLoading(false);
        } else {
            try {
                if (location.pathname.includes('create')) {
                    const res = await quoteApi.createSPRepair(
                        storage,
                        formData,
                    );
                    if (res) {
                        alertSuccessPopup({
                            title: '修繕依頼を作成しました。',
                            confirmButtonText: '閉じる',
                        });
                        handleResetFields();
                        setIsLoading(false);
                        setTotalImageSize(0);
                    }
                } else {
                    const res = await quoteApi.updateSPQuote(
                        storage,
                        formData,
                        id,
                    );
                    if (res) {
                        alertSuccess('修繕依頼を作成しました。');
                        handleResetFields();
                        navigate(-1);
                        setIsLoading(false);
                        setTotalImageSize(0);
                    }
                }
            } catch (error) {
                console.log('error', error.message);
                setIsLoading(false);
                // alertError(error.message);
            }
        }
    };
    const dragProps: UploadProps = {
        name: 'file',
        beforeUpload(file: RcFile) {
            // const isPDF = file.type === 'application/pdf';

            const isLE10MB = file.size / 1024 / 1024 <= 10;
            if (!isLE10MB) {
                message.error('ファイルは10MBを超えてはいけません。');
            }
            return isLE10MB || Upload.LIST_IGNORE;
        },
        onChange(info) {
            // getBase64(info.file.originFileObj as RcFile, (url) => {
            //     setValue('file', url)
            // });
            setValue('file', info.file.originFileObj);
        },
        onDrop(e) {
            //
        },
    };
    const dummyRequest: UploadProps['customRequest'] = async ({
        file,
        onSuccess,
    }) => {
        setTimeout(() => {
            if (onSuccess) {
                onSuccess('ok');
            }
        }, 0);
    };

    const handleValidateWhitespace: FocusEventHandler<
        HTMLInputElement | HTMLTextAreaElement
    > = (e) => {
        const onlyContainWhitespaces = validateFullWhitespace(e.target.value);
        if (onlyContainWhitespaces) {
            setValue(e.target.name, '');
        }
    };

    const getListCompany = async () => {
        try {
            const res = await quoteApi.geAlltCompanies(storage);
            const arrTmp: any = [];
            for (let i = 0; i < res.data.cooperative_companies.length; i++) {
                arrTmp.push({
                    value: res.data.cooperative_companies[i].company.id,
                    name: res.data.cooperative_companies[i].company.name,
                });
            }
            setCompanies(arrTmp);
        } catch (error) {
            console.log('error', error);
        }
    };

    const getAllListCompany = async () => {
        try {
            const response = await quoteApi.geAllCompanies(storage);
            const companies =
                response?.data?.companies?.length > 0
                    ? response?.data?.companies?.map((item, index) => {
                        return {
                            label: item?.name ?? '',
                            value: item?.id ?? '',
                        };
                    })
                    : [];
            setListCompany(companies);
        } catch (error) {
            console.log('error', error);
        }
    };

    const handleResetFields = () => {
        setValue('content', '');
        setValue('construction_site', '');
        setValue('property_name', '');
        setValue('company_name', '');
        setValue('address', '');
        resetField('images');
        setValue('person_in_charge', '');
        setValue('desired_date', '');
        setListFile([]);
        setFileLength(undefined);
        setIsLoading(false);
        setAllowUpload(true);
    };

    const navigateBackToDetail = () => {
        navigate(-1);
    };

    return {
        ...props,
        numberOfSelectedImages,
        handleSubmit,
        submitData,
        control,
        dragProps,
        dummyRequest,
        register,
        setError,
        errors,
        setValue,
        id,
        isLoading,
        getValues,
        listFile,
        setListFile,
        isAllowUpload,
        setAllowUpload,
        fileLength,
        setFileLength,
        isExcessiveUpload,
        setIsExcessiveUpload,
        data,
        clearErrors,
        navigateBackToDetail,
        handleValidateWhitespace,
        totalImageSize,
        setTotalImageSize,
        companies,
        listCompany
    };
};

export type Props = ReturnType<typeof useSPRepairForm>;

export default useSPRepairForm;
