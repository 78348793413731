import { STATUS_INVOICE } from 'utils/constants';
import CalendarIcon from 'assets/icons/ic_calendar.svg';
import ImgPdfLine from 'assets/icons/img_pdf_line.svg';
import { useEffect, useState } from 'react';
import { formatNumber } from 'utils/constants/function';
import styles from './styles.module.scss';
import clsx from 'clsx';
import { defaultListTotal } from 'pages/project/projectPlan/Invoices/ModalCUDInvoice';
import { useNavigate, useNavigation, useParams } from 'react-router-dom';
import billLineService from 'services/billLineService';
import { alertError } from 'utils/helper/appHelper';
import dayjs from 'dayjs';
import { FORMAT } from 'utils/constants/format-date-time';
import LoadingSpin from 'components/LoadingSpin';

interface filePdfListType {
    id?: number;
    file_path?: string;
    size?: string;
    file_name?: string;
}

interface billType {
    id?: number;
    name?: string;
    quantity?: number;
    unit?: string;
    unit_price?: string | any;
    amount?: string | any;
    total_price?: string;
}

interface detailBillType {
    project?: {
        corporate_name?: string;
        from_date?: string;
        address?: string;
        quote?: {
            customer?: {
                address?: string;
                name?: string;
            };
        };
    };
    customer?: {
        address?: string;
        name?: string;
    };
    files?: [];
    status?: string;
}

const titleCssBillItem = 'text-[#0000008C] font-normal !w-[33%] text-left';
const valueCssBillItem = 'text-right break-all';

interface SPBillDetailProps { }
const SPBillDetail: React.FC<SPBillDetailProps> = ({ }) => {
    const { idBill } = useParams();

    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(false);

    let storage = localStorage.getItem('line:accessToken');

    const [filePdf, setFilePdf] = useState<filePdfListType[]>([]);

    const [listTotal, setListTotal] = useState(defaultListTotal);

    const [detaiLBill, setDetailBill] = useState<detailBillType>({});

    const [listBillItems, setListBillItems]: any = useState<billType[]>([]);

    const status = STATUS_INVOICE?.find(
        (item) => item.value === detaiLBill?.status,
    );

    const getDetailBillLine = async () => {
        setIsLoading(true);
        try {
            const response = await billLineService.getDetailBillLine(
                storage,
                Number(idBill),
            );
            if (response !== null && response !== undefined) {
                const response_data = response?.data?.bill;
                setDetailBill(response_data);
                setFilePdf(response_data?.files);
                setListBillItems(response_data?.bill_items);
                setIsLoading(false);
            }
        } catch (err) {
            alertError(err);
        }
    };

    const backListBill = () => {
        navigate(-1);
    };

    const updateStatusBill = async () => {
        setIsLoading(true);
        try {
            const response = await billLineService.updateStatus(
                storage,
                Number(idBill),
            );
            if (response !== null && response !== undefined) {
                const response_data = response?.data;
                if (response_data) {
                    backListBill();
                }
            }
        } catch (err) {
            alertError(err);
        }
    };

    useEffect(() => {
        getDetailBillLine();
    }, [idBill]);

    useEffect(() => {
        let subTotal = listBillItems?.reduce((total, obj) => {
            const amountFormat = obj?.amount ? Math.round(obj?.amount) : '';
            return total + amountFormat;
        }, 0);

        const consumptionTax = listBillItems?.reduce((total, obj) => {
            const totalPriceFormat = obj?.total_price
                ? Math.round(obj?.total_price)
                : '';
            return total + totalPriceFormat;
        }, 0);

        const total = Number(subTotal) + Number(consumptionTax);

        const arrTotal = [subTotal, '0', consumptionTax, total];

        const listTotalFormat = listTotal?.map((item, index) => {
            return {
                ...item,
                value: arrTotal[index],
            };
        });
        setListTotal(listTotalFormat);
    }, [listBillItems]);

    return (
        <>
            {isLoading ? (
                <LoadingSpin />
            ) : (
                <div className="relative flex">
                    <div
                        className="px-2 mb-[200px]"
                        style={{ flex: '1 0 auto' }}
                    >
                        <div className="flex items-center justify-between my-3">
                            <div className="text-sm font-bold">基本情報</div>
                            <div>
                                {status ? (
                                    <div className="w-full flex justify-center">
                                        <div
                                            className="w-[112px] flex items-center justify-center rounded-2xl h-7"
                                            style={{
                                                color: status?.color,
                                                backgroundColor: status?.bg,
                                            }}
                                        >
                                            {status?.label}
                                        </div>
                                    </div>
                                ) : (
                                    ''
                                )}
                            </div>
                        </div>
                        <div className="grid gap-y-1">
                            <div></div>
                            <div>
                                <div className="flex text-sm">
                                    <div className="text-[#666666]">
                                        顧客:
                                    </div>
                                    <div>
                                        {detaiLBill?.project?.corporate_name ??
                                            ''}
                                    </div>
                                </div>
                                <div>
                                    <div className="flex text-[#666666] items-center gap-1 text-sm">
                                        <div>
                                            <img src={CalendarIcon} />
                                        </div>
                                        <div>
                                            {detaiLBill?.project?.from_date
                                                ? dayjs(
                                                    detaiLBill?.project
                                                        ?.from_date,
                                                ).format(
                                                    FORMAT.FORMAT_DATE_UPPER_JAPAN,
                                                )
                                                : ''}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div></div>
                        </div>
                        <div className="text-sm mt-1">
                            {detaiLBill?.project?.address ?? ''}
                        </div>
                        <hr className="!my-1" />
                        <div>
                            <div className="flex items-center gap-1 text-sm">
                                <div className="text-[#666666]">お客様:</div>
                                {/* discuss */}
                                <div>{detaiLBill?.customer?.name}</div>
                            </div>
                            {/* discuss */}
                            <div className="gap-1 text-sm">
                                {detaiLBill?.customer?.address}{' '}
                            </div>
                        </div>
                        <div className="my-3 p-2 border-[1px] border-solid border-[#00000026] rounded-lg">
                            <div className="text-center text-sm font-semibold">
                                List PDF files
                            </div>
                            {filePdf?.length > 0 ? (
                                filePdf?.map(
                                    (item: filePdfListType, index: number) => (
                                        <div
                                            key={index}
                                            className="bg-[#f8f8f8] mt-2 rounded-lg p-2"
                                        >
                                            <a
                                                className="flex items-center gap-2 no-underline"
                                                href={item?.file_path}
                                                target="blank"
                                            >
                                                <img src={ImgPdfLine} />
                                                <div className="font-normal !text-sm text-[#000000]">
                                                    <div className="break-all">
                                                        {item?.file_name
                                                            ? item?.file_name?.replace(
                                                                '.pdf',
                                                                '.',
                                                            ) + 'PDF'
                                                            : ''}
                                                    </div>
                                                    <div className="break-all">
                                                        {item?.size}
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                    ),
                                )
                            ) : (
                                <div className="text-center">
                                    データがありません。
                                </div>
                            )}
                        </div>

                        {listBillItems?.length > 0 &&
                            listBillItems?.map(
                                (bill: billType, index: number) => (
                                    <div
                                        key={index}
                                        className="my-3 p-2 border-[1px] border-solid border-[#00000026] rounded-lg"
                                    >
                                        <div className="text-center text-sm font-semibold">
                                            {bill?.name ?? ''}
                                        </div>
                                        <div>
                                            <div className="flex justify-between text-sm w-full">
                                                <div
                                                    className={titleCssBillItem}
                                                >
                                                    数量
                                                </div>
                                                <div
                                                    className={valueCssBillItem}
                                                >
                                                    {bill?.quantity
                                                        ? formatNumber(
                                                            bill?.quantity,
                                                        )
                                                        : ''}
                                                </div>
                                            </div>
                                            <div className="flex justify-between text-sm mt-1 w-full">
                                                <div className="text-[#0000008C] font-normal !w-[33%] text-left">
                                                    単位
                                                </div>
                                                <div
                                                    className={valueCssBillItem}
                                                >
                                                    {bill?.unit ?? ''}
                                                </div>
                                            </div>
                                            <div className="flex justify-between text-sm mt-1 w-full">
                                                <div className="text-[#0000008C] font-normal !w-[33%] text-left">
                                                    単価
                                                </div>
                                                <div
                                                    className={clsx(
                                                        valueCssBillItem,
                                                        'font-medium',
                                                    )}
                                                >
                                                    {bill?.unit_price
                                                        ? '¥' +
                                                        formatNumber(
                                                            Math.round(
                                                                bill?.unit_price,
                                                            ),
                                                        )
                                                        : ''}
                                                </div>
                                            </div>
                                            <div className="flex justify-between text-sm mt-1 w-full">
                                                <div className="text-[#0000008C] font-normal  !w-[33%] text-left">
                                                    税率
                                                </div>
                                                <div
                                                    className={clsx(
                                                        valueCssBillItem,
                                                        'font-medium',
                                                    )}
                                                >
                                                    10%
                                                </div>
                                            </div>
                                            <div className="flex justify-between text-sm mt-1 w-full">
                                                <div className="text-[#0000008C] font-normal !w-[33%] text-left">
                                                    金額
                                                </div>
                                                <div
                                                    className={clsx(
                                                        valueCssBillItem,
                                                        'font-medium',
                                                    )}
                                                >
                                                    {bill?.amount
                                                        ? '¥' +
                                                        formatNumber(
                                                            Math.round(
                                                                bill?.amount,
                                                            ),
                                                        )
                                                        : ''}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ),
                            )}
                    </div>
                    <div
                        className={clsx(
                            'bg-[#ffffff] shadow-inner px-3 py-2 w-full',
                            styles.footerBillDetail,
                        )}
                    >
                        {listBillItems?.length > 0 && (
                            <div className="bg-[#f9f9f9] p-2">
                                {listTotal?.map((item: any, index: number) => (
                                    <div
                                        className={clsx(
                                            'flex justify-between text-sm w-full',
                                            {
                                                'mt-2': index !== 0,
                                            },
                                        )}
                                    >
                                        <div
                                            className={clsx(
                                                '!w-[33%] text-left',
                                                {
                                                    'text-[#0000008C] font-normal':
                                                        index !== 3,
                                                    '!text-xl font-medium':
                                                        index === 3,
                                                },
                                            )}
                                        >
                                            {item?.label}
                                        </div>
                                        <div
                                            className={clsx(
                                                'text-[#000000] font-medium !w-[67%] break-all text-right',
                                                {
                                                    'text-xl': index === 3,
                                                    'text-base': index !== 3,
                                                },
                                            )}
                                        >
                                            {item?.value
                                                ? formatNumber(item?.value) +
                                                '¥'
                                                : ''}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        )}
                        <div className="modal-create-user-buttons d-flex flex-row gap-2">
                            <button
                                className="modal-create-user-button1 d-flex flex-row !text-[#000000] !border-[#d9d9d9] !h-10 w-full"
                                onClick={backListBill}
                            >
                                キャンセル
                            </button>
                            {detaiLBill?.status?.includes('unpaid') && (
                                <button
                                    className="modal-create-user-button2 d-flex flex-row !h-10 w-full"
                                    onClick={updateStatusBill}
                                >
                                    支払い
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default SPBillDetail;
